import { useEffect, useRef } from 'react';

const useHorizontalWindowResize = (handler: () => void) => {
  const widthRef = useRef(window.innerWidth);

  useEffect(() => {
    const localHandler = () => {
      if (window.innerWidth !== widthRef.current) {
        handler();
      }
      widthRef.current = window.innerWidth;
    };

    window.addEventListener('resize', localHandler);
    return () => window.removeEventListener('resize', localHandler);
  }, [handler]);
};

export default useHorizontalWindowResize;
