import { useIntl } from 'react-intl';
import ProductFundingsSection from './ProductFundingsSection';
import useProductFundings from './useProductFundings';

interface IProductFundingsOverviewProps {
  productId: number;
}

export default function ProductFundingsOverview(
  props: IProductFundingsOverviewProps,
) {
  const { quantityOnList, donations, purchases } = useProductFundings(
    props.productId,
  );
  const intl = useIntl();

  return (
    <>
      {donations && donations.length > 0 && (
        <ProductFundingsSection
          title={intl.formatMessage(
            { id: 'product-details-page.fundings-overview.donations' },
            { numberOfDonations: donations.length },
          )}
          fundings={donations}
          quantityOnWishList={quantityOnList}
        />
      )}
      {purchases && purchases.length > 0 && (
        <ProductFundingsSection
          title={intl.formatMessage(
            { id: 'product-details-page.fundings-overview.purchases' },
            { numberOfPurchases: purchases.length },
          )}
          fundings={purchases}
          quantityOnWishList={quantityOnList}
        />
      )}
    </>
  );
}
