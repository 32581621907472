import { Link } from 'react-router-dom';
import { state } from '@springtree/eva-sdk-react-recoil';
import { useGetState } from 'eva/EvaHooks';
import Icon from '../ui/Icon';

export default function HeaderCartButton() {
  const shoppingCartProductsCount = useGetState(
    state.checkout.shoppingCartProductCountState,
  );

  return (
    <Link
      to="/cart"
      className="text-2xl bg-gray h-10 w-10 flex justify-center items-center relative overflow-visible"
    >
      <Icon name="cart" />
      {shoppingCartProductsCount && shoppingCartProductsCount.total > 0 && (
        <div className="absolute -bottom-3 -right-3 rounded-full text-xs bg-red-500 text-white font-bold w-6 h-6 flex justify-center items-center">
          <span>{shoppingCartProductsCount.total}</span>
        </div>
      )}
    </Link>
  );
}
