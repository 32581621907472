import { atom } from 'recoil';

export const CheckoutAmountsState = atom<EVA.Core.OpenOrderAmounts | undefined>(
  {
    key: 'Checkout:Amounts',
    default: undefined,
  },
);

export default CheckoutAmountsState;
