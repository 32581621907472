import { atom } from 'recoil';
import { ProductAvailabilityDetails } from './ProductDetailsTypes';

export const SelectedConfiguredProductIdState = atom<number | undefined>({
  key: 'ProductDetails:SelectedConfiguredProductId',
  default: undefined,
});

export const ProductAvailabilityDetailsState = atom<ProductAvailabilityDetails>(
  {
    key: 'ProductDetails:ProductAvailabilityDetails',
    default: {} as ProductAvailabilityDetails,
  },
);
