import { useSetRecoilState, useRecoilValue } from 'recoil';
import { state, hooks } from '@springtree/eva-sdk-react-recoil';
import { useCallback } from 'react';
import { Core } from '@springtree/eva-services-core';
import { useHistory } from 'react-router-dom';
import useWishlist from 'hooks/useWishlist';
import { ActiveWishListItemAvailabilityState } from './WishListState';
import useFilteredWishListLines from './useFilteredWishListLines';

const useBuyWishList = () => {
  const history = useHistory();
  const { activeWishlist } = useWishlist();
  const createOrder = hooks.useCallService({ service: Core.CreateOrder });
  const addWishListProductToOrder = hooks.useCallService({
    service: Core.AddWishListProductToOrder,
  });
  const setShoppingCart = useSetRecoilState(
    state.checkout.shoppingCartState.request,
  );
  const activeWishListItemAvailability = useRecoilValue(
    ActiveWishListItemAvailabilityState,
  );
  const filteredWishListLines = useFilteredWishListLines();

  const buyWishList = useCallback(async () => {
    if (activeWishlist) {
      const createOrderResponse = await createOrder({ Type: 0 });
      if (createOrderResponse?.OrderID) {
        const linesToAdd = filteredWishListLines
          // skip fully bought lines
          ?.filter((x) => x.OpenQuantity > 0)
          ?.filter((line) => {
            const lineProductAvailability = activeWishListItemAvailability?.Products?.find(
              (x) => x.ProductID === line.Product.ID,
            );

            if (lineProductAvailability) {
              // skip lines that are UseUp / BackorderNotAllowed and have no stock
              if (
                (lineProductAvailability.ProductStatus === 32 ||
                  lineProductAvailability.ProductStatus === 34) &&
                lineProductAvailability.StoreAvailabilityCount <= 0 &&
                (lineProductAvailability.Delivery.QuantityAvailable ?? 0) <= 0
              ) {
                return false;
              }
            }
            return true;
          });

        if (linesToAdd) {
          await addWishListProductToOrder({
            TargetOrderID: createOrderResponse.OrderID,
            WishListOrderID: activeWishlist.OrderID,
            LineActionType: 4,
            WishListLines: linesToAdd.map((x) => ({
              WishListLineID: x.ID,
              Quantity: x.OpenQuantity,
            })),
          });
          setShoppingCart({ OrderID: createOrderResponse.OrderID });
          history.push('/cart');
        }
      }
    }
  }, [
    activeWishlist,
    createOrder,
    addWishListProductToOrder,
    setShoppingCart,
    history,
  ]);

  return buyWishList;
};
export default useBuyWishList;
