import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { ResetPasswordValues } from './ResetPasswordTypes';

const useResetPasswordValidationSchema = () => {
  const intl = useIntl();

  const validationSchema: yup.SchemaOf<ResetPasswordValues> = yup
    .object()
    .shape({
      password: yup
        .string()
        .min(
          8,
          intl.formatMessage(
            { id: 'generic.error.min-length' },
            { minLength: 8 },
          ),
        )
        .required(intl.formatMessage({ id: 'generic.password.required' })),
      confirmPassword: yup
        .string()
        .required(intl.formatMessage({ id: 'generic.repeat-password' }))
        .test(
          'passwords-match',
          intl.formatMessage({ id: 'generic.error.confirm-password' }),
          // eslint-disable-next-line func-names
          function (value) {
            return this.parent.password === value;
          },
        ),
    })
    .defined();

  return validationSchema;
};

export default useResetPasswordValidationSchema;
