import { hooks, state } from '@springtree/eva-sdk-react-recoil';
import { Core } from '@springtree/eva-services-core';
import { useCallback } from 'react';
import { FTH } from 'eva/eva-services-fth';
import { useCallService } from 'eva/EvaHooks';
import useNotifications from 'hooks/useNotifications';
import { useIntl } from 'react-intl';
import { NotificationType } from 'components/shared/ui/types/NotificationType';
import { EditWishListValues } from './ProfileTypes';

const useUpdateActiveWishList = () => {
  const updateWishList = hooks.useCallService({
    service: Core.UpdateWishList,
  });
  const refreshWishList = hooks.useSetServiceStale({
    serviceState: state.wishList.wishListDetailsState,
  });
  const refreshUserWishLists = hooks.useSetServiceStale({
    serviceState: state.wishList.wishListsForUserState,
  });
  const setRequestedDeliveryDate = hooks.useCallService({
    service: Core.SetRequestedDate,
  });
  const setDueDateOnOrder = useCallService({
    service: FTH.FTHSetDueDateOnOrder,
  });
  const intl = useIntl();
  const { addNotification } = useNotifications();

  const updateWishListValues = useCallback(
    (wishList: EVA.Core.WishList | undefined, values: EditWishListValues) => {
      if (wishList) {
        updateWishList({
          OrderID: wishList.OrderID,
          Name: values.wishListName,
          Data: {
            ...wishList.Data,
            dueDate: values.dueDate,
            requestedDeliveryDate: values.requestedDeliveryDate,
          },
        }).then(() => {
          refreshWishList();
          refreshUserWishLists();

          addNotification({
            message: intl.formatMessage({
              id: 'profile-page.success-notification',
            }),
            type: NotificationType.Success,
          });
        });

        setRequestedDeliveryDate({
          OrderID: wishList.OrderID,
          RequestedDate: values.requestedDeliveryDate?.toISOString(),
        });

        setDueDateOnOrder({
          OrderID: wishList.OrderID,
          DueDate: values.dueDate?.toISOString(),
        });
      }
    },
    [
      addNotification,
      intl,
      refreshUserWishLists,
      refreshWishList,
      setDueDateOnOrder,
      setRequestedDeliveryDate,
      updateWishList,
    ],
  );

  const setWishListImage = (
    wishList: EVA.Core.WishList | undefined,
    imageId: number,
  ) => {
    if (wishList) {
      updateWishList({
        OrderID: wishList.OrderID,
        Data: {
          ...wishList.Data,
          imgId: imageId,
        },
      }).then(refreshWishList);
    }
  };

  return { updateWishListValues, setWishListImage };
};

export default useUpdateActiveWishList;
