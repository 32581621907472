/* eslint-disable import/prefer-default-export */
import { selector } from 'recoil';
import { state } from '@springtree/eva-sdk-react-recoil';
import AppConfiguration from 'AppConfiguration';
import { IPaymentMethod } from 'components/shared/payment/PaymentTypes';

export const BuckarooPaymentMethodsSelector = selector<
  Array<IPaymentMethod> | undefined
>({
  key: 'Payment:AvailablePaymentMethods',
  get: ({ get }) => {
    const shoppingCartAvailablePaymentMethods = get(
      state.checkout.shoppingCartAvailablePaymentMethodsState.response,
    );

    if (
      shoppingCartAvailablePaymentMethods?.PaymentMethods?.includes('BUCKAROO')
    ) {
      const buckarooMethods = shoppingCartAvailablePaymentMethods?.Results?.find(
        (x) => x.Name === 'BUCKAROO',
      );
      if (buckarooMethods) {
        return buckarooMethods.Types.filter(
          (x) =>
            !AppConfiguration.disabledBuckarooPaymentMethods.includes(x.Code),
        );
      }
    }

    return undefined;
  },
});

export const UserCardPaymentMethodSelector = selector<
  IPaymentMethod | undefined
>({
  key: 'Payment:UserCardPaymentMethod',
  get: ({ get }) => {
    const shoppingCartAvailablePaymentMethods = get(
      state.checkout.shoppingCartAvailablePaymentMethodsState.response,
    );

    if (
      shoppingCartAvailablePaymentMethods?.PaymentMethods?.includes('USERCARD')
    ) {
      const userCardMethods = shoppingCartAvailablePaymentMethods?.Results?.find(
        (x) => x.Name === 'USERCARD',
      );
      if (userCardMethods?.Types) {
        return userCardMethods.Types[0];
      }
    }

    return undefined;
  },
});
