/** @jsxImportSource @emotion/react */
import Logo from 'assets/images/logo.png';
import SuspenseFallback from 'eva/SuspenseFallback';
import { Link } from 'react-router-dom';
import tw, { css } from 'twin.macro';
import HeaderLogout from './HeaderLogout';
import HeaderMenu from './HeaderMenu';
import SearchBar from '../search-bar/SearchBar';
import HeaderCartButton from './HeaderCartButton';

export default function Header() {
  return (
    <header className="bg-primary">
      <div
        css={[
          tw`container p-2 mx-auto grid md:flex justify-between`,
          css`
            grid-template-columns: auto auto auto;
            grid-template-rows: repeat(2, auto);
          `,
        ]}
      >
        <div className="flex items-center mr-5 col-span-2">
          <Link to="/">
            <img src={Logo} alt="Baby-Deals" className="w-72" />
          </Link>
        </div>
        <div className="row-start-2 col-span-3 col-start-1 mt-4 md:mt-0 flex-1 self-end">
          <SuspenseFallback>
            <SearchBar />
          </SuspenseFallback>
        </div>
        <div className="self-end">
          <div className="flex justify-end pb-2 text-white text-sm font-bold">
            <SuspenseFallback>
              <HeaderLogout />
            </SuspenseFallback>
          </div>
          <div className="flex justify-end">
            <HeaderCartButton />
          </div>
        </div>
      </div>
      <SuspenseFallback>
        <HeaderMenu />
      </SuspenseFallback>
    </header>
  );
}
