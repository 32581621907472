import useIsMounted from 'hooks/useIsMounted';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  SearchPageFiltersState,
  SearchPageResultsState,
} from './SearchPageState';
import { ICategory, ICategoryFilterItem, IFilterItem } from './SearchPageTypes';

const useCategoriesFilter = () => {
  const isMounted = useIsMounted();

  const searchProductsResponse = useRecoilValue(SearchPageResultsState);
  const [searchPageFilters, setSearchPageFilters] = useRecoilState(
    SearchPageFiltersState,
  );
  const [categories, setCategories] = useState<ICategoryFilterItem[]>([]);

  const toggleCategory = useCallback(
    (categoryName: string) => {
      if (searchPageFilters.categories.some((x) => x.name === categoryName)) {
        setSearchPageFilters((current) => ({
          ...current,
          currentPage: 1,
          categories: current.categories.filter((x) => x.name !== categoryName),
          usePriceRange: false,
        }));
      } else {
        setSearchPageFilters((current) => ({
          ...current,
          currentPage: 1,
          categories: [
            ...current.categories,
            { name: categoryName, subCategories: [] },
          ],
          usePriceRange: false,
        }));
      }
    },
    [searchPageFilters.categories, setSearchPageFilters],
  );

  const toggleSubCategory = useCallback(
    (categoryName: string, subCategoryName: string) => {
      const category = searchPageFilters.categories.find(
        (x) => x.name === categoryName,
      );

      if (category) {
        let newCategory: ICategory;

        if (category.subCategories.includes(subCategoryName)) {
          newCategory = {
            name: categoryName,
            subCategories: category.subCategories.filter(
              (x) => x !== subCategoryName,
            ),
          };
        } else {
          newCategory = {
            name: categoryName,
            subCategories: [...category.subCategories, subCategoryName],
          };
        }

        setSearchPageFilters((current) => ({
          ...current,
          currentPage: 1,
          categories: [
            ...current.categories.filter((x) => x.name !== categoryName),
            newCategory,
          ],
          usePriceRange: false,
        }));
      } else {
        setSearchPageFilters((current) => ({
          ...current,
          currentPage: 1,
          categories: [
            ...current.categories,
            { name: categoryName, subCategories: [subCategoryName] },
          ],
          usePriceRange: false,
        }));
      }
    },
    [searchPageFilters.categories, setSearchPageFilters],
  );

  useEffect(() => {
    if (searchProductsResponse?.Aggregations?.omzetgroep && isMounted) {
      setCategories(
        searchProductsResponse.Aggregations.omzetgroep.values
          .map(
            (category: any): ICategoryFilterItem => ({
              name: category.value,
              numberOfProducts: category.count,
              selected: searchPageFilters.categories.some(
                (selectedCategory) => selectedCategory.name === category.value,
              ),
              subcategories: category.groepnaam?.values
                ?.map(
                  (subCategory: any): IFilterItem => ({
                    name: subCategory.value,
                    numberOfProducts: subCategory.count,
                    selected: searchPageFilters.categories.some(
                      (selectedCategory) =>
                        selectedCategory.name === category.value &&
                        selectedCategory.subCategories.includes(
                          subCategory.value,
                        ),
                    ),
                  }),
                )
                .sort((a: IFilterItem, b: IFilterItem) =>
                  a.name.localeCompare(b.name),
                ),
            }),
          )
          .sort((a: IFilterItem, b: IFilterItem) =>
            a.name.localeCompare(b.name),
          ),
      );
    }
  }, [
    isMounted,
    searchPageFilters.brands,
    searchPageFilters.categories,
    searchProductsResponse,
  ]);

  return { categories, toggleCategory, toggleSubCategory };
};

export default useCategoriesFilter;
