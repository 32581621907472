export interface AddressValues {
  zipcode: string;
  houseNumber: string;
  addressAddition: string;
  street: string;
  city: string;
  countryId: string;
  autocomplete?: string;
}

export const InitialAddressValues: AddressValues = {
  zipcode: '',
  houseNumber: '',
  addressAddition: '',
  street: '',
  city: '',
  countryId: '',
  autocomplete: '',
};
