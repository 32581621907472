/** @jsxImportSource @emotion/react */
import Text from 'components/shared/ui/Text';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import tw, { css } from 'twin.macro';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import PromoRibbon from 'components/shared/product/PromoRibbon';
import useWishlist from 'hooks/useWishlist';
import HelmetTitle from 'components/shared/title/HelmetTitle';
import useProduct from './useProduct';
import ProductAvailability from './ProductAvailability';
import { SelectedConfiguredProductIdState } from './ProductDetailsState';
import ProductDetailsParentActions from './ProductDetailsParentActions';
import ProductDetailsFamilyActions from './ProductDetailsFamilyActions';
import ProductFundingsOverview from './ProductFundingsOverview';
import useGetProductAvailabilityDetails from './useGetProductAvailabilityDetails';

export default function ProductDetails() {
  const { productId } = useParams<{ productId: string }>();
  const parsedProductId = useMemo(() => parseInt(productId, 10), [productId]);
  const {
    productDetails,
    productImage,
    isIncludedInActiveWishList,
    wishlistDiscount,
    wishlistLineUnitPrice,
    isConfigurable,
    isBundle,
    isLoading,
  } = useProduct(parsedProductId);
  const selectedConfiguredProductId = useRecoilValue(
    SelectedConfiguredProductIdState,
  );

  useGetProductAvailabilityDetails(
    isConfigurable ? selectedConfiguredProductId : parsedProductId,
  );

  const { isParentOrEmployee, isParent } = useWishlist();

  return (
    <>
      {!isLoading && productDetails === undefined && (
        <>
          <Text type="h2">
            <FormattedMessage id="product-details-page.not-available" />
          </Text>
        </>
      )}
      {productDetails && (
        <>
          <HelmetTitle title={productDetails.display_value} />
          <div className="flex items-center mb-8">
            <Text type="h1" className="mr-4">
              {productDetails.display_value}
            </Text>
            <span className="font-normal">
              <FormattedMessage
                id="product-details-page.article-number"
                values={{ articleNumber: productDetails.custom_id }}
              />
            </span>
          </div>
          <div
            css={[
              tw`grid lg:grid-cols-3 items-stretch gap-6`,
              css`
                grid-template-rows: repeat(3, auto);
              `,
            ]}
          >
            <div className="bg-white text-center p-4 relative">
              {productDetails.vanvoorbetype === 'promo' && <PromoRibbon />}
              <img src={productImage} alt="" className="mx-auto" />
            </div>
            <div className="row-start-3 lg:row-start-2">
              <ProductAvailability />
            </div>
            <div className="lg:col-span-2 lg:row-span-3">
              {isParentOrEmployee ? (
                <ProductDetailsParentActions
                  isConfigurable={isConfigurable}
                  isBundle={isBundle}
                  isIncludedInActiveWishList={isIncludedInActiveWishList}
                  productId={parsedProductId}
                  productPrice={wishlistLineUnitPrice + wishlistDiscount}
                />
              ) : (
                <ProductDetailsFamilyActions
                  isConfigurable={isConfigurable}
                  isIncludedInActiveWishList={isIncludedInActiveWishList}
                  productId={parsedProductId}
                  productPrice={wishlistLineUnitPrice + wishlistDiscount}
                  isBundle={isBundle}
                />
              )}
              <div className="bg-white mb-6 p-4">
                <Text type="h3" className="mb-4 text-xl">
                  <FormattedMessage id="product-details-page.product-information" />
                </Text>
                <div
                  css={[descriptionStyles]}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: productDetails.long_description,
                  }}
                />
              </div>
              {isParent && (
                <ProductFundingsOverview productId={parsedProductId} />
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

const descriptionStyles = css`
  & p {
    margin-bottom: 1rem;
  }
`;
