import Button from 'components/shared/ui/Button';
import Text from 'components/shared/ui/Text';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import Notification from 'components/shared/ui/Notification';
import useLogin from './useLogin';
import useGetCurrentUser from '../../hooks/useGetCurrentUser';

export default function LoginPageCreateAccount() {
  const history = useHistory();
  const { logout } = useLogin();
  const { currentUser } = useGetCurrentUser();

  const handleCreateAccountClick = useCallback(() => {
    if (currentUser) {
      logout();
    }

    history.push('/register');
  }, [currentUser, history, logout]);

  return (
    <div className="bg-white m-6 p-6 border-gray border col-span-12 flex flex-col items-center">
      <Text type="h1">
        <FormattedMessage id="login-page.create-account.title" />
      </Text>
      <Text className="mt-6">
        <FormattedMessage id="login-page.create-account.description" />
      </Text>
      <div className="mt-8 md:w-1/2 self-stretch md:self-center">
        {currentUser && (
          <Notification
            className="mb-4"
            textId="login-page.create-account.already-logged-in"
            type="warning"
          />
        )}
        <div>
          <Button
            className="w-full"
            underline
            onClick={handleCreateAccountClick}
          >
            <FormattedMessage
              id={
                currentUser
                  ? 'login-page.create-account.logout-and-register'
                  : 'login-page.create-account.action'
              }
            />
          </Button>
        </div>
      </div>
    </div>
  );
}
