import SuspenseFallback from 'eva/SuspenseFallback';
import { useHistory } from 'react-router-dom';
import useGetCurrentUser from 'hooks/useGetCurrentUser';
import { useEffect } from 'react';
import useWishlist from 'hooks/useWishlist';
import { useRecoilValue } from 'recoil';
import CheckoutProfile from './account/CheckoutProfile';
import { CheckoutOrderState } from './state/CheckoutOrder';

export default function CheckoutRegisterPage() {
  const { currentUser } = useGetCurrentUser();
  const { isParent } = useWishlist();
  const checkoutOrder = useRecoilValue(CheckoutOrderState);
  const history = useHistory();

  useEffect(() => {
    if (!checkoutOrder) {
      history.push('/cart');
    }
  }, [checkoutOrder, currentUser, history, isParent]);

  return (
    <SuspenseFallback>
      <div>{isParent}</div>
      {isParent ? (
        <div className="container mx-auto p-10">
          <CheckoutProfile order={checkoutOrder} type="parent" />
        </div>
      ) : undefined}
      {!isParent ? (
        <div className="container mx-auto p-10">
          <CheckoutProfile order={checkoutOrder} type="family" />
        </div>
      ) : undefined}
    </SuspenseFallback>
  );
}
