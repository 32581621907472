import Button from 'components/shared/ui/Button';
import Price from 'components/shared/ui/Price';
import Text from 'components/shared/ui/Text';
import useWishlist from 'hooks/useWishlist';
import { FormattedMessage } from 'react-intl';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import Link from 'components/shared/ui/Link';
import { useEffect, useMemo } from 'react';

export default function DonateSuccessPage() {
  const { activeWishlist } = useWishlist();
  const { donationValue } = useParams<{ donationValue?: string }>();

  const location = useLocation();
  const history = useHistory();

  const success = useMemo(
    () => new URLSearchParams(location.search).get('success') === 'true',
    [location.search],
  );

  useEffect(() => {
    if (!success && activeWishlist) {
      history.push(
        `/geboortelijst/${activeWishlist.OrderID}/${activeWishlist.AccessToken}`,
      );
    }
  }, [activeWishlist, history, location.search, success]);

  return (
    <div className="container mx-auto p-6 lg:p-10">
      {success && (
        <>
          <Text type="h1" className="font-bold mb-4">
            <FormattedMessage id="donate-success-page.title" />
          </Text>
          {activeWishlist && donationValue && (
            <div className="bg-white p-4">
              <FormattedMessage id="donate-success-page.your-donation-of" />
              <Price value={parseFloat(donationValue)} />
              <FormattedMessage id="donate-success-page.has-been-processed-successfully" />
              <FormattedMessage
                id="donate-success-page.family-will-be-notified"
                values={{ familyName: activeWishlist.Customer.FullName }}
              />

              <div className="flex flex-col sm:flex-row my-4 sm:items-center">
                <Button
                  className="sm:mr-4"
                  onClick={() =>
                    history.push(
                      `/geboortelijst/${activeWishlist.OrderID}/${activeWishlist.AccessToken}`,
                    )
                  }
                >
                  <FormattedMessage id="donate-success-page.go-to-wishlist" />
                </Button>
                <Link to="/register">
                  <span className="font-bold">
                    <FormattedMessage id="donate-success-page.create-wishlist" />
                  </span>
                </Link>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
