import Autocomplete from 'components/shared/ui/Autocomplete';
import Button from 'components/shared/ui/Button';
import FormikInput from 'components/shared/ui/FormikInput';
import FormikSelect from 'components/shared/ui/FormikSelect';
import { useFormikContext, getIn } from 'formik';
import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import AddressFieldError from './AddressFieldError';
import useAddressAutocomplete from './useAddressAutocomplete';
import useCountryOptions from './useCountryOptions';

interface IAddressFieldsProps {
  prefix: string;
}

export default function AddressFields(props: IAddressFieldsProps) {
  const {
    values,
    setFieldValue,
    getFieldMeta,
    setFieldError,
    setFieldTouched,
    errors,
    touched,
  } = useFormikContext();
  const [showAddressFields, setShowAddressFields] = useState(false);
  const [manualShowAddressFields, setManualShowAddressFields] = useState(false);
  const countryOptions = useCountryOptions();
  const intl = useIntl();

  const {
    addressSuggestions,
    getAddressSuggestions,
    getAddressByReference,
    address,
  } = useAddressAutocomplete();

  useEffect(() => {
    if (!getIn(values, `${props.prefix}.countryId`) && countryOptions.length) {
      setFieldValue(`${props.prefix}.countryId`, countryOptions[0].value);
    }
  }, [countryOptions, props.prefix, setFieldValue, values]);

  useEffect(() => {
    if (address) {
      setFieldValue(
        `${props.prefix}.houseNumber`,
        address.HouseNumber?.LongName || '',
      );
      setFieldValue(`${props.prefix}.street`, address.Street?.LongName || '');
      setFieldValue(
        `${props.prefix}.zipcode`,
        address.PostalCode?.LongName || '',
      );
      setFieldValue(`${props.prefix}.city`, address.City?.LongName || '');
      if (address.Country) {
        const validCountry =
          countryOptions.findIndex(
            (x) => x.value === address.Country?.ShortName,
          ) > -1;
        setFieldValue(
          `${props.prefix}.countryId`,
          validCountry ? address.Country?.ShortName : countryOptions[0].value,
        );
      }
    }
  }, [address, countryOptions, props.prefix, setFieldValue]);

  useEffect(() => {
    const hasAnyAddressField =
      !!getIn(values, `${props.prefix}.zipcode`) ||
      !!getIn(values, `${props.prefix}.houseNumber`) ||
      !!getIn(values, `${props.prefix}.additional`) ||
      !!getIn(values, `${props.prefix}.street`) ||
      !!getIn(values, `${props.prefix}.city`);

    if (hasAnyAddressField !== showAddressFields || manualShowAddressFields) {
      setShowAddressFields(hasAnyAddressField || manualShowAddressFields);
    }
  }, [manualShowAddressFields, props.prefix, showAddressFields, values]);

  useEffect(() => {
    const fields = ['zipcode', 'houseNumber', 'additional', 'street', 'city'];

    const hasErrors =
      !showAddressFields &&
      fields.some((x) => {
        const fieldMeta = getFieldMeta(`${props.prefix}.${x}`);

        return fieldMeta.error && fieldMeta.touched;
      });

    const autocomplete = getFieldMeta(`${props.prefix}.autocomplete`);

    if (hasErrors !== !!autocomplete.error) {
      setFieldError(
        `${props.prefix}.autocomplete`,
        hasErrors
          ? intl.formatMessage({ id: 'generic.error.required' })
          : undefined,
      );
      setFieldTouched(`${props.prefix}.autocomplete`, hasErrors);
    }
  }, [
    getFieldMeta,
    intl,
    manualShowAddressFields,
    props.prefix,
    setFieldError,
    setFieldTouched,
    showAddressFields,
    values,
  ]);

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2">
      <div>
        <Autocomplete
          name={`${props.prefix}.autocomplete`}
          labelId="register-page.your-address.search"
          options={addressSuggestions.map((x) => {
            return { value: x.Reference, label: x.Description };
          })}
          onChange={getAddressSuggestions}
          onSelect={getAddressByReference}
        />
        {!showAddressFields && (
          <Button
            variant="text"
            onClick={() => setManualShowAddressFields(true)}
          >
            <FormattedMessage id="register-page.enter-address-manually" />
          </Button>
        )}
        {showAddressFields && (
          <>
            <div className="grid grid-cols-4">
              <div className="col-span-4 lg:col-span-2 lg:mr-2">
                <FormikInput
                  name={`${props.prefix}.zipcode`}
                  labelId="generic.address.zipcode"
                  type="text"
                  hideErrorMessage
                />
              </div>
              <div className="col-span-2 lg:col-span-1 mr-2">
                <FormikInput
                  name={`${props.prefix}.houseNumber`}
                  labelId="generic.address.house-number"
                  type="text"
                  hideErrorMessage
                />
              </div>
              <div className="col-span-2 lg:col-span-1">
                <FormikInput
                  name={`${props.prefix}.addressAddition`}
                  labelId="generic.address.additional"
                  type="text"
                  hideErrorMessage
                />
              </div>
              <div className="col-span-full flex flex-col mb-2">
                <AddressFieldError fieldName={`${props.prefix}.zipcode`} />
                <AddressFieldError fieldName={`${props.prefix}.houseNumber`} />
              </div>
              <div className="col-span-4 flex flex-col mb-2">
                <FormikInput
                  name={`${props.prefix}.street`}
                  labelId="generic.address.street-name"
                  type="text"
                  hideErrorMessage
                />
                <AddressFieldError fieldName={`${props.prefix}.street`} />
              </div>
              <div className="col-span-4 flex flex-col mb-2">
                <FormikInput
                  name={`${props.prefix}.city`}
                  labelId="generic.address.city"
                  type="text"
                  hideErrorMessage
                />
                <AddressFieldError fieldName={`${props.prefix}.city`} />
              </div>
              <FormikSelect
                name={`${props.prefix}.countryId`}
                labelId="generic.address.country"
                className="col-span-4"
                options={countryOptions}
              />
              <AddressFieldError fieldName={`${props.prefix}.country`} />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
