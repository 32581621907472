import SuspenseFallback from 'eva/SuspenseFallback';
import LoginPageLoginForm from './LoginPageLoginForm';
import LoginPageSearchWishlist from './LoginPageSearchWishlist';
import LoginPageCreateAccount from './LoginPageCreateAccount';

export default function LoginPage() {
  return (
    <div className="container mx-auto lg:p-10 lg:grid lg:grid-cols-2">
      <SuspenseFallback>
        <LoginPageLoginForm />
      </SuspenseFallback>
      <SuspenseFallback>
        <LoginPageSearchWishlist />
      </SuspenseFallback>
      <SuspenseFallback>
        <LoginPageCreateAccount />
      </SuspenseFallback>
    </div>
  );
}
