import { useCallService, useSetServiceStale } from 'eva/EvaHooks';
import { Core } from '@springtree/eva-services-core';
import { useCallback } from 'react';
import { state } from '@springtree/eva-sdk-react-recoil';
import useWishlist from 'hooks/useWishlist';

const useRemoveWishListLine = () => {
  const { activeWishListIdentifier } = useWishlist();
  const removeWishListLine = useCallService({
    service: Core.RemoveProductFromWishList,
  });
  const refreshWishListDetails = useSetServiceStale({
    serviceState: state.wishList.wishListDetailsState,
  });

  return useCallback(
    async (wishListLine: EVA.Core.WishListLine) => {
      if (activeWishListIdentifier) {
        await removeWishListLine({
          OrderID: activeWishListIdentifier.orderId,
          WishListLineID: wishListLine.ID,
          Quantity: wishListLine.Quantity,
        });
        refreshWishListDetails();
      }
    },
    [activeWishListIdentifier, refreshWishListDetails, removeWishListLine],
  );
};

export default useRemoveWishListLine;
