import { IEvaCurrentUserType } from '@springtree/eva-sdk-react-recoil/lib/state/current-user/current-user-type-state';
import { atom } from 'recoil';

export const CurrentUserState = atom<EVA.Core.UserDto | undefined>({
  key: 'CurrentUser',
  default: undefined,
});

export const CurrentUserTypeState = atom<IEvaCurrentUserType | undefined>({
  key: 'CurrentUserType',
  default: undefined,
});
