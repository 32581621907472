import useProductImage from 'components/shared/product/useProductImage';
import Link from 'components/shared/ui/Link';
import { ISearchResult } from './SearchBarTypes';

interface ISearchBarItem {
  result: ISearchResult;
  onSelect: () => void;
}

export default function SearchBarItem(props: ISearchBarItem) {
  const productImage = useProductImage(props.result.productId, 64, 64);
  return (
    <div className="p-4 w-full hover:bg-gray-light">
      <Link
        to={`/product/${props.result.productId}/${props.result.productSlug}`}
        onClick={props.onSelect}
      >
        <div className="flex font-bold items-center">
          <img
            src={productImage}
            alt=""
            className="w-16 h-16 object-center mr-4"
          />
          <span className="max-w-full truncate max-h-5">
            {props.result.productName}
          </span>
        </div>
      </Link>
    </div>
  );
}
