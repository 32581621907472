import AppConfiguration from 'AppConfiguration';
import DatePicker from 'components/shared/ui/DatePicker';
import Separator from 'components/shared/ui/Separator';
import Text from 'components/shared/ui/Text';
import { format } from 'date-fns';
import { Form, Formik, FormikValues } from 'formik';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import useDeliveryDate from '../hooks/useDeliveryDate';

export interface DeliverDateValues extends FormikValues {
  requestedDeliveryDate?: Date;
}

export default function OrderDeliveryWeek() {
  const {
    minDeliveryDate,
    maxDeliveryDate,
    currentDeliveryDate,
    setDeliveryDate,
  } = useDeliveryDate();

  const [deliveryWeekMessage, setDeliveryWeekMessage] = useState<
    string | undefined
  >();
  const intl = useIntl();

  useEffect(() => {
    if (currentDeliveryDate) {
      const deliveryDayOfWeek = currentDeliveryDate.getDay() - 1;
      const weekStart = new Date(
        currentDeliveryDate.getTime() - deliveryDayOfWeek * 86400000,
      );
      const weekEnd = new Date(
        currentDeliveryDate.getTime() + (5 - deliveryDayOfWeek) * 86400000,
      );
      const start = format(weekStart, AppConfiguration.dateFormat);
      const end = format(weekEnd, AppConfiguration.dateFormat);

      setDeliveryWeekMessage(
        intl.formatMessage(
          { id: 'checkout-page.order-delivery-week.message' },
          { end, start },
        ),
      );
    }
  }, [currentDeliveryDate, intl]);

  return (
    <>
      {currentDeliveryDate && (
        <div>
          <Text type="h2">
            <FormattedMessage id="checkout-page.order-delivery-week.title" />
          </Text>
          <Separator className="my-4" />
          <Formik<DeliverDateValues>
            initialValues={{ requestedDeliveryDate: currentDeliveryDate }}
            enableReinitialize
            onSubmit={(event) => {
              if (event.requestedDeliveryDate) {
                setDeliveryDate(event.requestedDeliveryDate);
              }
            }}
          >
            <Form>
              <DatePicker
                labelId=""
                name="requestedDeliveryDate"
                minDate={minDeliveryDate}
                maxDate={maxDeliveryDate}
                onChange={(date: Date) => {
                  setDeliveryDate(date);
                }}
                disableSundays
              />
            </Form>
          </Formik>
          <div>{deliveryWeekMessage}</div>
        </div>
      )}
    </>
  );
}
