/** @jsxImportSource @emotion/react */
import { ReactNode } from 'react';
import tw from 'twin.macro';

interface IButtonProps {
  children: ReactNode;
  className?: string;
  underline?: boolean;
  type?: 'submit' | 'button';
  disabled?: boolean;
  onClick?: () => void;
  variant?: 'contained' | 'text' | 'stroked' | 'icon';
  color?: 'primary' | 'red';
}

export default function Button(props: IButtonProps) {
  return (
    <button
      type={props.type === 'submit' ? 'submit' : 'button'}
      style={{ minHeight: '40px' }}
      css={[
        props.variant !== 'icon' && tw`font-bold text-sm px-4`,
        (props.variant === 'contained' || props.variant === undefined) && [
          tw` text-white`,
          props.color === 'red' ? tw`bg-red-600` : tw`bg-primary-dark`,
        ],
        props.variant === 'stroked' && [
          tw`border border-gray text-xs `,
          props.color === 'red' ? tw`text-red-600` : tw`text-primary-dark`,
        ],
        props.variant === 'text' && [
          props.color === 'red' ? tw`text-red-600` : tw`text-primary-dark`,
        ],
        props.underline &&
          (props.variant === 'contained' || props.variant === undefined) && [
            tw`border-b-2`,
            props.color === 'red'
              ? tw`border-red-800`
              : tw`border-primary-darker`,
          ],
        props.underline &&
          props.variant === 'text' &&
          tw`border-b-2 border-gray`,
        props.disabled && tw`cursor-not-allowed bg-gray`,
      ]}
      className={props.className}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
}
