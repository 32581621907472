import { hooks } from '@springtree/eva-sdk-react-recoil';
import { useCallback, useState } from 'react';
import { Core } from '@springtree/eva-services-core';
import useIsMounted from './useIsMounted';

const useAvailabilityIndication = () => {
  const isMounted = useIsMounted();
  const [
    availabilityIndication,
    setAvailabilityIndication,
  ] = useState<EVA.Core.GetAvailabilityIndicationResponse>();
  const getProductDetailAvailability = hooks.useCallService({
    service: Core.GetAvailabilityIndication,
  });

  const getAvailabilityIndication = useCallback(
    (products: Array<{ ProductID: number; QuantityRequested: number }>) => {
      if (products.length) {
        getProductDetailAvailability({
          Products: products,
          DetailedShopInformation: true,
          IncludeAvailabilityTexts: false,
          FastestPickupShopOnly: false,
        }).then((response) => {
          if (isMounted) {
            setAvailabilityIndication(response);
          }
        });
      }
    },
    [getProductDetailAvailability, isMounted],
  );

  return {
    getAvailabilityIndication,
    availabilityIndication,
  };
};

export default useAvailabilityIndication;
