export interface ISearchPageFilters {
  selectedMinPrice: number;
  selectedMaxPrice?: number;
  pageSize: number;
  currentPage: number;
  sortType: SearchSortType;
  categories: ICategory[];
  brands: string[];
  usePriceRange: boolean;
}

export interface ICategory {
  name: string;
  subCategories: string[];
}

export enum SearchSortType {
  Newest = 'Newest',
  PriceAscending = 'PriceAscending',
  PriceDescending = 'PriceDescending',
  Popular = 'Popular',
}

export const SearchSortTypeTranslations: Record<SearchSortType, string> = {
  [SearchSortType.Newest]: 'search-page.sort-type.newest',
  [SearchSortType.PriceAscending]: 'search-page.sort-type.price-ascending',
  [SearchSortType.PriceDescending]: 'search-page.sort-type.price-descending',
  [SearchSortType.Popular]: 'search-page.sort-type.popular',
};

export const SearchSortTypeDescriptors: Record<
  SearchSortType,
  EVA.Core.SortFieldDescriptor
> = {
  [SearchSortType.Newest]: {
    FieldName: 'product_id',
    Direction: 1,
  },
  [SearchSortType.PriceAscending]: {
    FieldName: 'display_price',
    Direction: 0,
  },
  [SearchSortType.PriceDescending]: {
    FieldName: 'display_price',
    Direction: 1,
  },
  [SearchSortType.Popular]: {
    FieldName: '_score',
    Direction: 0,
  },
};

export interface IFilterItem {
  name: string;
  numberOfProducts: number;
  selected: boolean;
}

export interface ICategoryFilterItem extends IFilterItem {
  subcategories: Array<IFilterItem>;
}
