import { useShoppingCartAdd } from 'eva/EvaHooks';
import { useCallback } from 'react';
import { Core } from '@springtree/eva-services-core';

const useAddProductToShoppingCart = () => {
  const shoppingCartAdd = useShoppingCartAdd({
    service: Core.AddProductToOrder,
  });

  const addProductToShoppingCart = useCallback(
    ({
      productId,
      quantity,
      lineActionType,
    }: {
      productId: number;
      quantity: number;
      lineActionType: 1 | 4;
    }) => {
      return shoppingCartAdd({
        orderType: 0,
        payload: {
          ProductId: productId,
          QuantityOrdered: quantity,
          LineActionType: lineActionType,
          Children: [],
        },
      });
    },
    [shoppingCartAdd],
  );

  return addProductToShoppingCart;
};

export default useAddProductToShoppingCart;
