/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import ProductPrice from 'components/shared/product/ProductPrice';
import Separator from '../ui/Separator';
import useProductImage from './useProductImage';

export interface IProductCardProps {
  id: number;
  brandName: string;
  name: string;
  backendId?: string;
  price?: number;
  footer?: React.ReactNode;
  isBundle: boolean;
}

export default function ProductCard(props: IProductCardProps) {
  const productImage = useProductImage(props.id);

  return (
    <div className="bg-white flex flex-col items-center h-80">
      <div className="text-black font-medium text-sm p-2 h-8 overflow-hidden">
        {props.brandName}
      </div>
      <div
        css={[
          tw`text-black text-sm px-4 text-center`,
          props.brandName !== undefined ? tw`overflow-hidden h-6` : tw`h-12`,
        ]}
      >
        {props.name}
      </div>
      <img className="my-2 px-2 h-40" src={productImage} alt="" />
      <div className="text-gray self-end text-right p-2">{props.backendId}</div>
      <Separator className="self-stretch" />
      {props.price !== undefined && (
        <div className="self-end p-2 mr-3 my-auto">
          <ProductPrice
            displayPrice={props.price}
            productId={props.id}
            isBundle={props.isBundle}
          />
        </div>
      )}
      {props.footer !== undefined && props.footer}
    </div>
  );
}
