/** @jsxImportSource @emotion/react */
import { Field, FieldProps } from 'formik';
import { FormattedMessage } from 'react-intl';
import tw from 'twin.macro';

interface ITextAreaProps {
  labelId?: string;
  name: string;
  readonly?: boolean;
  hideErrorMessage?: boolean;
  rows?: number;
  capitalizeLabel?: boolean;
}

export default function TextArea(props: ITextAreaProps) {
  return (
    <Field name={props.name}>
      {({ field, meta }: FieldProps) => (
        <div className="w-full mb-4">
          <div className="flex">
            {props.labelId && (
              <div
                css={[
                  props.capitalizeLabel !== false && tw`uppercase`,
                  tw`text-sm`,
                ]}
              >
                <FormattedMessage id={props.labelId} />
              </div>
            )}
            {meta.touched && meta.error && !props.hideErrorMessage && (
              <div className="text-sm flex-grow text-right text-error">
                {meta.error}
              </div>
            )}
          </div>
          <textarea
            rows={props.rows ?? 5}
            css={[
              tw`border border-gray mt-2 p-2 text-sm font-light w-full focus:bg-gray focus:border-blue`,
              props.readonly && tw`bg-gray cursor-not-allowed`,
              meta.touched && meta.error && tw`border-error`,
            ]}
            {...field}
            readOnly={props.readonly}
          >
            {field.value}
          </textarea>
        </div>
      )}
    </Field>
  );
}
