import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Icon from 'components/shared/ui/Icon';

export default function AddWishListItem() {
  return (
    <Link
      to="/search"
      className="max-w-sm bg-transparent border-dashed border-2 border-gray focus:outline-none p-2 flex flex-col justify-center items-center h-80"
    >
      <span className="text-5xl text-primary my-2">
        <Icon name="plus" />
      </span>
      <span className="text-sm my-2 text-center">
        <FormattedMessage id="home-page.wish-list.add-product" />
      </span>
    </Link>
  );
}
