import { hooks } from '@springtree/eva-sdk-react-recoil';
import { Core } from '@springtree/eva-services-core';
import useAvailabilityIndication from 'hooks/useAvailabilityIndication';
import useProductAvailability from 'hooks/useProductAvailability';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import useIsMounted from '../../hooks/useIsMounted';
import { ProductAvailabilityDetailsState } from './ProductDetailsState';
import {
  IShopAvailability,
  IWarehouseAvailability,
} from './ProductDetailsTypes';

const useProductDetailsAvailability = () => {
  const { availabilityIndication, productAvailability } = useRecoilValue(
    ProductAvailabilityDetailsState,
  );

  const isMounted = useIsMounted();
  const [shops, setShops] = useState<any[]>();
  const getOrganizationUnits = hooks.useCallService({
    service: Core.ListOrganizationUnits,
  });

  useEffect(() => {
    getOrganizationUnits({
      PageConfig: {
        Filter: { CountryIDs: ['BE'], TypeID: 8 },
      },
    }).then((response) => {
      if (response?.Result && isMounted) {
        setShops(response.Result.Page);
      }
    });
  }, [getOrganizationUnits, isMounted]);

  const [shopAvailability, setShopAvailability] = useState<
    Array<IShopAvailability>
  >();

  const [
    warehouseAvailability,
    setWarehouseAvailability,
  ] = useState<IWarehouseAvailability>();

  useEffect(() => {
    if (shops && availabilityIndication && productAvailability && isMounted) {
      setShopAvailability(
        shops.map((shop) => {
          const availabilityForShop = availabilityIndication.Products[0].PickupIndications.find(
            (x) => x.PickupOrganizationUnit.ID === shop.ID,
          );
          return {
            shop: shop.Name,
            inStock: (availabilityForShop?.QuantityOnHand ?? 0) > 0 ?? false,
          };
        }),
      );

      setWarehouseAvailability({
        inStock:
          (availabilityIndication.Products[0].Delivery.QuantityAvailable ?? 0) >
          0,
        expectedDate: productAvailability.Products[0].Delivery.AvailabilityDate
          ? new Date(productAvailability.Products[0].Delivery.AvailabilityDate)
          : undefined,
      });
    }
  }, [availabilityIndication, isMounted, productAvailability, shops]);

  return { shopAvailability, warehouseAvailability };
};

export default useProductDetailsAvailability;
