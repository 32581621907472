import Select from 'components/shared/ui/Select';
import Separator from 'components/shared/ui/Separator';
import Text from 'components/shared/ui/Text';
import { IOption } from 'components/shared/ui/types/IOption';
import { Form, Formik } from 'formik';
import { Fragment, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import useDeliveryMethod from '../hooks/useDeliveryMethod';
import { DeliveryMethod } from '../state/SelectedDeliveryMethod';

export interface DeliverMethodValues {
  method: DeliveryMethod | undefined;
}

const defaultDeliveryMethodValues: DeliverMethodValues = {
  method: undefined,
};
export default function OrderDeliveryMethod() {
  const intl = useIntl();
  const {
    visibleStores,
    methods,
    currentPickupOu,
    selectedMethod,
    selectDeliveryMethod,
    setPickupStore,
  } = useDeliveryMethod();

  const updateDeliveryMethod = useCallback(
    (e) => {
      selectDeliveryMethod(e.target.value);
    },
    [selectDeliveryMethod],
  );

  const storeOptions: Array<IOption> = useMemo(() => {
    const options: IOption[] = [];

    if (!currentPickupOu) {
      options.push({
        value: 0,
        label: intl.formatMessage({
          id: 'checkout-page.order-delivery-method.select-a-store',
        }),
      });
    }
    if (visibleStores) {
      visibleStores.forEach((store: any) => {
        options.push({
          value: store.ID,
          label: store.Name,
        });
      });
    }

    return options;
  }, [currentPickupOu, intl, visibleStores]);

  return (
    <>
      <Formik<DeliverMethodValues>
        initialValues={defaultDeliveryMethodValues}
        enableReinitialize
        onSubmit={() => {}}
      >
        {() => (
          <Form>
            <Text type="h2">
              <FormattedMessage id="checkout-page.order-delivery-method.title" />
            </Text>
            <Separator className="my-4" />
            <div role="group">
              {methods
                ? methods
                    .filter((m) => !m.isHidden)
                    .map((m) => (
                      <Fragment key={m.value}>
                        <div className="flex items-center my-2">
                          <input
                            id={m.name}
                            name="method"
                            type="radio"
                            value={m.value}
                            checked={m.value === selectedMethod?.value}
                            disabled={m.isDisabled}
                            onChange={updateDeliveryMethod}
                            className="mr-2"
                          />
                          <label htmlFor={m.name}>{m.name}</label>
                        </div>
                        {m.value === 'pickup' &&
                          selectedMethod?.value === 'pickup' && (
                            <Select
                              options={storeOptions}
                              value={currentPickupOu}
                              onChange={(e) => {
                                setPickupStore(parseInt(e.target.value, 10));
                              }}
                            />
                          )}
                      </Fragment>
                    ))
                : undefined}
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
