import { NotificationType } from 'components/shared/ui/types/NotificationType';
import { useCallback, useMemo } from 'react';
import { toast, ToastOptions } from 'react-toastify';
import { theme } from 'twin.macro';
import AppConfiguration from '../AppConfiguration';

const useNotifications = () => {
  const backgroundColors: Record<NotificationType, string> = useMemo(
    () => ({
      [NotificationType.Error]: theme`colors.error`,
      [NotificationType.Info]: theme`colors.black`,
      [NotificationType.Success]: theme`colors[primary-darker]`,
    }),
    [],
  );

  const addNotification = useCallback(
    ({
      message,
      options,
      type,
    }: {
      message: string;
      options?: ToastOptions;
      type: NotificationType;
    }) => {
      toast.success(message, {
        ...AppConfiguration.toastOptions,
        ...options,
        style: {
          ...AppConfiguration.toastOptions.style,
          ...options?.style,
          backgroundColor: backgroundColors[type],
        },
      });
    },
    [backgroundColors],
  );

  return { addNotification };
};

export default useNotifications;
