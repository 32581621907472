import { useRouteMatch } from 'react-router-dom';
import { Core } from '@springtree/eva-services-core';
import { helpers, state } from '@springtree/eva-sdk-react-recoil';
import Button from 'components/shared/ui/Button';
import FormikInput from 'components/shared/ui/FormikInput';
import Notification from 'components/shared/ui/Notification';
import Text from 'components/shared/ui/Text';
import { Form, Formik } from 'formik';
import { useRecoilValue } from 'recoil';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import useIsMounted from 'hooks/useIsMounted';
import { ResetPasswordValues } from './ResetPasswordTypes';
import useResetPasswordValidationSchema from './useResetPasswordValidationSchema';

export default function ForgotPasswordPage() {
  const isMounted = useIsMounted();
  const evaEndpointUrl = useRecoilValue(state.core.evaEndpointUrlState);
  const resetPasswordValidationSchema = useResetPasswordValidationSchema();
  const [showInstructions, setShowInstructions] = useState(false);
  const params = useRouteMatch<any>('/resetpassword/:token');

  const resetPassword = async (values: ResetPasswordValues) => {
    setShowInstructions(false);

    if (values.confirmPassword && params) {
      const evaEndpoint = await helpers.getEvaEndpoint(evaEndpointUrl);
      const token = params?.params.token;
      await evaEndpoint.callService(Core.ResetUserPassword, {
        NewPassword: values.confirmPassword,
        Token: token,
      });

      if (isMounted) {
        setShowInstructions(true);
      }
    }
  };

  return (
    <div className="grid grid-cols-4">
      <div className="col-start-2 col-span-2 py-8">
        <Text type="h2" className="mb-4">
          <FormattedMessage id="reset-password-page.title" />
        </Text>
        <Text className="mb-4">
          <FormattedMessage id="reset-password-page.description" />
        </Text>
        <Formik<ResetPasswordValues>
          initialValues={{ password: '', confirmPassword: '' }}
          onSubmit={resetPassword}
          validationSchema={resetPasswordValidationSchema}
        >
          {({ isValid, dirty }) => (
            <Form>
              <div className="grid grid-cols-2">
                <div>
                  <FormikInput
                    name="password"
                    labelId="generic.password"
                    type="password"
                  />
                  <FormikInput
                    name="confirmPassword"
                    labelId="generic.repeat-password"
                    type="password"
                  />
                </div>
              </div>
              <Button type="submit" disabled={!isValid || !dirty}>
                <FormattedMessage id="reset-password-page.action" />
              </Button>
            </Form>
          )}
        </Formik>
        {showInstructions && (
          <Notification
            className="mt-4"
            type="success"
            textId="reset-password-page.success"
          />
        )}
      </div>
    </div>
  );
}
