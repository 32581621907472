import { ReactNode, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from './Button';
import Icon from './Icon';
import Separator from './Separator';

interface IDialogProps {
  titleId?: string;
  children: ReactNode;
  confirmButtonTextId?: string;
  confirmHandler?: () => void;
  closeHandler: () => void;
  show: boolean;
  showCancelButton?: boolean;
  showTopSeparator?: boolean;
}

export default function Dialog(props: IDialogProps) {
  useEffect(() => {
    document.body.style.overflow = props.show ? 'hidden' : 'auto';
  }, [props.show]);
  useEffect(
    () => () => {
      document.body.style.overflow = 'auto';
    },
    [],
  );

  return (
    <>
      {props.show && (
        <div className="fixed inset-0 z-50 bg-gray bg-opacity-50 overflow-y-auto flex">
          <div className="max-w-screen-lg m-auto p-4 lg:p-20">
            <div className="bg-white p-2">
              <div className="flex w-full justify-between ">
                {props.titleId && (
                  <div className="font-semibold p-2">
                    <FormattedMessage id={props.titleId} />
                  </div>
                )}
                <div className="ml-auto mr-2">
                  <Button
                    variant="icon"
                    onClick={props.closeHandler}
                    className="text-2xl"
                  >
                    <Icon name="times" />
                  </Button>
                </div>
              </div>
              {props.showTopSeparator && <Separator />}
              <div className="p-2">{props.children}</div>
              <div className="flex flex-col sm:flex-row sm:justify-end p-2">
                {props.showCancelButton && (
                  <Button variant="text" onClick={props.closeHandler}>
                    <FormattedMessage id="generic.cancel" />
                  </Button>
                )}
                {props.confirmButtonTextId && (
                  <div className="p-2 mr-auto ml-auto order-first sm:order-last sm:mr-0">
                    <Button underline onClick={props.confirmHandler}>
                      <FormattedMessage id={props.confirmButtonTextId} />
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
