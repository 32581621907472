import SuspenseFallback from 'eva/SuspenseFallback';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import useWishList from 'hooks/useWishlist';
import Button from 'components/shared/ui/Button';
import { FormattedMessage } from 'react-intl';
import { useSetServiceStale } from 'eva/EvaHooks';
import { state } from '@springtree/eva-sdk-react-recoil';
import useGetCurrentUser from 'hooks/useGetCurrentUser';
import Text from 'components/shared/ui/Text';
import { useRecoilValue } from 'recoil';
import { WishListNotFoundForUrlState } from 'state/WishlistState';
import Link from 'components/shared/ui/Link';
import WishListCover from './wish-list/WishListCover';
import WishListGrid from './wish-list/WishListGrid';
import WishListGridHeader from './wish-list/WishListGridHeader';
import WishListFamilyInformationDialog from './WishListFamilyInformationDialog';
import WishListParentInformationDialog from './WishListParentInformationDialog';
import WishListSwitcher from './wish-list/WishListSwitcher';
import useWishListUrlParamsValidationErrors from './wish-list/useWishListUrlParamsValidationErrors';

export default function WishListPage() {
  const {
    isParent,
    activeWishlist,
    selectedUserWishList,
    isParentOrEmployee,
  } = useWishList();
  const { isEmployee } = useGetCurrentUser();

  const refreshWishList = useSetServiceStale({
    serviceState: state.wishList.wishListDetailsState,
  });
  useEffect(() => {
    refreshWishList();
  }, [refreshWishList]);

  const history = useHistory();
  const { paramsError } = useWishListUrlParamsValidationErrors();
  const wishListNotFound = useRecoilValue(WishListNotFoundForUrlState);

  return (
    <div className="container mx-auto p-6 lg:p-10">
      {!paramsError && !wishListNotFound ? (
        <>
          <SuspenseFallback>
            <WishListCover />
          </SuspenseFallback>
          {activeWishlist?.IsCompleted ? (
            <div className="flex flex-col items-start">
              <div className="my-4">
                <FormattedMessage id="home-page.wish-list.list-is-completed" />
              </div>
              {isParent && (
                <Button
                  variant="contained"
                  onClick={() => history.push('/geboortelijst/nieuw')}
                >
                  <FormattedMessage id="home-page.wish-list.new-wishlist" />
                </Button>
              )}
            </div>
          ) : (
            <>
              {isParentOrEmployee && <WishListSwitcher />}
              <SuspenseFallback>
                <WishListGridHeader />
              </SuspenseFallback>
              <SuspenseFallback>
                <WishListGrid />
              </SuspenseFallback>
            </>
          )}
        </>
      ) : (
        <>
          <Text type="h2" className="my-2">
            <FormattedMessage id="home-page.wish-list.not-found" />
          </Text>

          {selectedUserWishList && (
            <Link
              to={`/geboortelijst/${selectedUserWishList.OrderID}/${selectedUserWishList.AccessToken}`}
            >
              <Text type="h3">
                <FormattedMessage id="home-page.wish-list.not-found.back-to-my-list" />
              </Text>
            </Link>
          )}
        </>
      )}
    </div>
  );
}
