import FormikCheckbox from 'components/shared/ui/FormikCheckbox';
import Text from 'components/shared/ui/Text';
import { useField, useFormikContext } from 'formik';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import AddressFields from './AddressFields';

export default function Address() {
  const [hasShippingAddress] = useField<boolean>('hasShippingAddress');
  const { setFieldTouched, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (!hasShippingAddress.value) {
      setFieldValue('deliveryAddress', undefined);
      setFieldTouched('deliveryAddress', false);
    }
  }, [hasShippingAddress.value, setFieldTouched, setFieldValue]);

  return (
    <div className="mb-4">
      <Text type="h3" className="mb-2 uppercase">
        <FormattedMessage id="register-page.your-address" />
      </Text>
      <AddressFields prefix="billingAddress" />
      <div className="my-4">
        <FormikCheckbox
          name="hasShippingAddress"
          labelId="register-page.delivery-address.different"
        />
      </div>
      {hasShippingAddress.value && (
        <>
          <Text type="h3" className="mb-2 uppercase">
            <FormattedMessage id="register-page.delivery-address" />
          </Text>
          <AddressFields prefix="shippingAddress" />
        </>
      )}
    </div>
  );
}
