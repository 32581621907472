import { max } from 'date-fns';
import useAvailabilityIndication from 'hooks/useAvailabilityIndication';
import useProductAvailability from 'hooks/useProductAvailability';
import { uniq } from 'lodash';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { ShoppingCartDetailsState } from 'state/CartState';
import {
  IProductAvailabilityInformation,
  IShoppingCartAvailability,
} from './CartTypes';

const useShoppingCartAvailability = () => {
  const shoppingCart = useRecoilValue(ShoppingCartDetailsState);
  const {
    availabilityIndication,
    getAvailabilityIndication,
  } = useAvailabilityIndication();
  const {
    productAvailability,
    getProductAvailability,
  } = useProductAvailability();

  const [
    shoppingCartAvailability,
    setShoppingCartAvailability,
  ] = useState<IShoppingCartAvailability>({ itemAvailability: [] });

  useEffect(() => {
    if (shoppingCart) {
      getAvailabilityIndication(
        shoppingCart.Lines.filter((x) => x.ProductID !== undefined).map(
          (x) => ({
            ProductID: x.ProductID as number,
            QuantityRequested: x.TotalQuantityToShip,
          }),
        ),
      );

      getProductAvailability(
        shoppingCart.Lines.filter((x) => x.ProductID !== undefined).map(
          (x) => ({
            ID: x.ProductID as number,
            QuantityRequested: x.TotalQuantityToShip,
          }),
        ),
      );
    }
  }, [getAvailabilityIndication, getProductAvailability, shoppingCart]);

  useEffect(() => {
    if (availabilityIndication && productAvailability) {
      const productIds = uniq([
        ...availabilityIndication.Products.map((x) => x.ProductID),
        ...productAvailability.Products.map((x) => x.ProductID),
      ]);

      const productAvailabilityInformation: Array<IProductAvailabilityInformation> = productIds.map(
        (productId) => {
          const indication = availabilityIndication.Products.find(
            (x) => x.ProductID === productId,
          );
          const availability = productAvailability.Products.find(
            (x) => x.ProductID === productId,
          );

          return {
            availableStores: indication?.PickupIndications?.map(
              (x) => x.PickupOrganizationUnit.Name,
            ),
            productId,
            hasStockForDelivery: availability?.Delivery?.HasStock,
            earliestAvailabilityDate: availability?.Delivery?.AvailabilityDate
              ? new Date(availability.Delivery.AvailabilityDate)
              : undefined,
          };
        },
      );

      setShoppingCartAvailability({
        itemAvailability: productAvailabilityInformation,
        earliestDeliveryDate: productAvailabilityInformation.filter(
          (x) => x.earliestAvailabilityDate !== undefined,
        ).length
          ? max(
              productAvailabilityInformation
                .filter((x) => x.earliestAvailabilityDate !== undefined)
                .map((x) => x.earliestAvailabilityDate as Date),
            )
          : undefined,
      });
    }
  }, [
    availabilityIndication,
    productAvailability,
    setShoppingCartAvailability,
  ]);

  return shoppingCartAvailability;
};

export default useShoppingCartAvailability;
