/** @jsxImportSource @emotion/react */
import Dialog from 'components/shared/ui/Dialog';
import tw from 'twin.macro';
import React, { useState } from 'react';
import Icon from 'components/shared/ui/Icon';
import AppConfiguration from '../../AppConfiguration';

interface IChangeWishListImageDialogProps {
  onSave: (imageId: number) => void;
  closeHandler: () => void;
  currentImageId: number;
}

export default function ChangeWishListImageDialog(
  props: IChangeWishListImageDialogProps,
) {
  const [selectedImageId, setSelectedImageId] = useState(props.currentImageId);

  return (
    <Dialog
      closeHandler={props.closeHandler}
      titleId="profile-page.edit-wish-list.choose-photo"
      confirmButtonTextId="generic.save"
      confirmHandler={() => {
        props.onSave(selectedImageId);
        props.closeHandler();
      }}
      show
      showCancelButton
    >
      <div className="grid grid-cols-4 gap-4 m-4">
        {AppConfiguration.wishListImages.map((x) => (
          <button
            type="button"
            css={[
              tw`relative h-36 w-48 focus:outline-none`,
              x.imageId === selectedImageId && tw`border-primary-dark border-4`,
            ]}
            key={x.imageSource}
            onClick={() => setSelectedImageId(x.imageId)}
          >
            <img
              src={x.imageSource}
              className="h-full w-56 object-center"
              alt=""
            />
            {x.imageId === selectedImageId && (
              <div className="absolute -right-1 -bottom-1 w-8 h-8 flex items-center justify-center text-xl bg-primary-dark text-white">
                <Icon name="check" />
              </div>
            )}
          </button>
        ))}
      </div>
    </Dialog>
  );
}
