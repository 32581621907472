import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import useGetCurrentUser from 'hooks/useGetCurrentUser';
import AppConfiguration from '../../../AppConfiguration';
import { CheckoutOrderState } from '../state/CheckoutOrder';
import { CheckoutToken } from '../state/CheckoutToken';
import { IPaymentMethod } from '../../shared/payment/PaymentTypes';
import useEvaCheckoutServices from './useEvaCheckoutServices';

const useAvailablePaymentMethods = () => {
  const checkoutOrder = useRecoilValue(CheckoutOrderState);
  const checkoutToken = useRecoilValue(CheckoutToken);

  const { evaGetAvailablePaymentMethods } = useEvaCheckoutServices();
  const { currentUser } = useGetCurrentUser();

  const [paymentMethods, setPaymentMethods] = useState<
    IPaymentMethod[] | undefined
  >();

  const getMethods = useCallback(async () => {
    const methods = await evaGetAvailablePaymentMethods({});
    let availableMethods: IPaymentMethod[] = [];

    if (methods?.PaymentMethods?.includes('BUCKAROO')) {
      const buckarooMethods = methods?.Results?.find(
        (x) => x.Name === 'BUCKAROO',
      );
      if (buckarooMethods) {
        availableMethods = buckarooMethods.Types.filter(
          (x) =>
            !AppConfiguration.disabledBuckarooPaymentMethods.includes(x.Code),
        );
      }
    }

    setPaymentMethods(availableMethods);
  }, [evaGetAvailablePaymentMethods]);

  useEffect(() => {
    if (!paymentMethods && checkoutOrder) {
      if (currentUser || checkoutToken) {
        getMethods();
      }
    }
  }, [
    checkoutOrder,
    checkoutToken,
    currentUser,
    currentUser?.Type,
    getMethods,
    paymentMethods,
  ]);

  return { paymentMethods };
};

export default useAvailablePaymentMethods;
