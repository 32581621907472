import Price from 'components/shared/ui/Price';
import Separator from 'components/shared/ui/Separator';
import Table from 'components/shared/ui/Table';
import Text from 'components/shared/ui/Text';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { CellProps, Column } from 'react-table';

interface IProductFundingsSectionProps {
  fundings: Array<EVA.Core.WishListFundingItem>;
  quantityOnWishList: number;
  title: string;
}

export default function ProductFundingsSection(
  props: IProductFundingsSectionProps,
) {
  const columns: Array<Column<EVA.Core.WishListFundingItem>> = useMemo(
    () => [
      {
        Header: () => (
          <FormattedMessage id="product-details-page.fundings-overview.name" />
        ),
        accessor: 'Name',
      },
      {
        Header: () => (
          <FormattedMessage id="product-details-page.fundings-overview.remark" />
        ),
        accessor: 'Remark',
      },
      {
        Header: () => (
          <FormattedMessage id="product-details-page.fundings-overview.amount" />
        ),
        accessor: 'Amount',
        Cell: (cellProps: CellProps<EVA.Core.WishListFundingItem>) => (
          <span className="font-bold text-primary-dark">
            <Price
              value={cellProps.cell.value}
              currency={cellProps.row.original.CurrencyID}
            />
          </span>
        ),
      },
    ],
    [],
  );

  return (
    <div className="bg-white mb-6 p-4">
      <Text type="h3" className="mb-2 text-xl">
        {props.title}
      </Text>
      <Separator className="mb-4" />
      <div className="mb-4">
        <FormattedMessage
          id="product-details-page.fundings-overview.quantity-on-wishlist"
          values={{ quantityOnWishList: props.quantityOnWishList }}
        />
      </div>

      <Table data={props.fundings} columns={columns} />
    </div>
  );
}
