import usePromotionInfo from 'hooks/usePromotionInfo';
import { useRecoilValue } from 'recoil';
import { ShoppingCartDetailsState } from 'state/CartState';
import { useEffect } from 'react';

const useShoppingCartPromotionInfo = () => {
  const { promotionInfo, getPromotionInfo } = usePromotionInfo();
  const shoppingCart = useRecoilValue(ShoppingCartDetailsState);

  useEffect(() => {
    if (shoppingCart) {
      getPromotionInfo(
        shoppingCart.Lines.filter((x) => x.ProductID !== undefined).map(
          (x) => x.ProductID as number,
        ),
      );
    }
  }, [getPromotionInfo, shoppingCart]);

  return promotionInfo;
};

export default useShoppingCartPromotionInfo;
