import { useMemo } from 'react';
import AppConfiguration from '../AppConfiguration';

const useWishListImage = (imageId: number) => {
  const wishListImage = useMemo(
    () =>
      AppConfiguration.wishListImages.find((x) => x.imageId === imageId)
        ?.imageSource,
    [imageId],
  );

  return wishListImage;
};

export default useWishListImage;
