export interface IPaymentMethod {
  ID: number;
  Code: string;
  Name: string;
}

export enum PaymentTypes {
  WishListDonation,
  Checkout,
}
