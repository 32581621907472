import Text from 'components/shared/ui/Text';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { FormattedMessage } from 'react-intl';
import useWishList from 'hooks/useWishlist';
import Button from 'components/shared/ui/Button';
import { useHistory } from 'react-router-dom';
import { CheckoutOrderState } from './state/CheckoutOrder';
import useCheckout from './hooks/useCheckout';

export default function CheckoutComplete() {
  const checkoutOrder = useRecoilValue(CheckoutOrderState);
  const { finishCheckout } = useCheckout();
  const [isFamily, setIsFamily] = useState<boolean | undefined>();
  const [orderDetails, setOrderDetails] = useState<
    EVA.Core.OrderDto | undefined
  >();
  const { activeWishlist } = useWishList();
  const history = useHistory();

  useEffect(() => {
    if (checkoutOrder) {
      if (checkoutOrder.Customer.Type === 2) {
        setIsFamily(true);
      } else {
        setIsFamily(false);
      }
      // retain order details for success page, and clear the checkout state
      setOrderDetails(checkoutOrder);
      finishCheckout();
    }
  }, [checkoutOrder, finishCheckout]);

  return (
    <>
      {orderDetails !== undefined && activeWishlist && (
        <div className="container mx-auto p-6 lg:p-10">
          <Text type="h1" className="font-bold mb-4">
            <FormattedMessage id="checkout-complete.title" />
          </Text>
          <div className="bg-white p-4">
            <div className="mb-4">
              <FormattedMessage id="checkout-complete.order-processed" />
              {isFamily && (
                <FormattedMessage
                  id="checkout-complete.family-will-be-notified"
                  values={{
                    familyName: activeWishlist.Customer.FullName ?? '',
                  }}
                />
              )}
            </div>
            <Button
              className="sm:mr-4"
              onClick={() =>
                history.push(
                  `/geboortelijst/${activeWishlist.OrderID}/${activeWishlist.AccessToken}`,
                )
              }
            >
              <FormattedMessage id="checkout-complete.go-to-wishlist" />
            </Button>
          </div>
        </div>
      )}
    </>
  );
}
