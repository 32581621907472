import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useRecoilValue } from 'recoil';
import useWishList from 'hooks/useWishlist';
import CreatePayment from '../../shared/payment/CreatePayment';
import { PaymentTypes } from '../../shared/payment/PaymentTypes';
import useAvailablePaymentMethods from '../hooks/useAvailablePaymentMethods';
import { CheckoutOrderState } from '../state/CheckoutOrder';
import { CheckoutRequireStoreSelection } from '../state/CheckoutRequireStoreSelection';
import { CheckoutRequireFamilyMessage } from '../state/CheckoutRequireFamilyMessage';

export default function OrderPayment() {
  const { paymentMethods } = useAvailablePaymentMethods();
  const checkoutOrder = useRecoilValue(CheckoutOrderState);

  const [payInStoreEnabled, setPayInStoreEnabled] = useState<boolean>(false);
  const needStoreSelection = useRecoilValue(CheckoutRequireStoreSelection);
  const needFamilyMessage = useRecoilValue(CheckoutRequireFamilyMessage);
  const { isParent } = useWishList();

  useEffect(() => {
    if (
      checkoutOrder &&
      checkoutOrder.OriginatingOrganizationUnitID !==
        checkoutOrder.PickupOrganizationUnitID
    ) {
      setPayInStoreEnabled(true);
    } else {
      setPayInStoreEnabled(false);
    }
  }, [checkoutOrder]);
  return (
    <>
      {paymentMethods && (
        <div className="bg-white flex-col flex justify-between">
          <CreatePayment
            isButtonDisabled={
              needStoreSelection || (needFamilyMessage && !isParent)
            }
            paymentType={PaymentTypes.Checkout}
            availableMethods={paymentMethods}
            payInStoreEnabled={payInStoreEnabled}
          />
          {needStoreSelection && (
            <div className="text-error p-4 font-semibold">
              <FormattedMessage id="checkout-page.order-payment.select-a-store" />
            </div>
          )}
          {!isParent && needFamilyMessage && (
            <div className="text-error p-4 font-semibold">
              <FormattedMessage id="checkout-page.order-payment.missing-family-message" />
            </div>
          )}
        </div>
      )}
    </>
  );
}
