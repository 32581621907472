import Text from 'components/shared/ui/Text';
import { FormattedMessage, useIntl } from 'react-intl';
import { format } from 'date-fns';
import AppConfiguration from 'AppConfiguration';
import AvailabilityItem from './AvailabilityItem';
import useProductDetailsAvailability from './useProductDetailsAvailability';

export default function ProductAvailability() {
  const intl = useIntl();

  const {
    warehouseAvailability,
    shopAvailability,
  } = useProductDetailsAvailability();

  return (
    <div className="bg-white lg:mb-6 p-4">
      <Text type="h3" className="mb-4 text-xl">
        <FormattedMessage id="product-details-page.product-availability.stock" />
      </Text>

      <div className="grid grid-cols-3 gap-2">
        {shopAvailability !== undefined &&
          shopAvailability.map((x) => (
            <AvailabilityItem
              key={x.shop}
              shopName={x.shop}
              inStock={x.inStock}
            />
          ))}
        {warehouseAvailability !== undefined && (
          <>
            <AvailabilityItem
              inStock={warehouseAvailability.inStock}
              shopName={intl.formatMessage({
                id: 'product-details-page.product-availability.warehouse',
              })}
            />
            {!warehouseAvailability.inStock &&
              warehouseAvailability.expectedDate && (
                <>
                  <div className="col-span-2">
                    <FormattedMessage id="product-details-page.product-availability.expected-delivery" />
                  </div>
                  <div className="text-right">
                    {format(
                      warehouseAvailability.expectedDate,
                      AppConfiguration.dateFormat,
                    )}
                  </div>
                </>
              )}
          </>
        )}
      </div>
    </div>
  );
}
