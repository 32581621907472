import Table from 'components/shared/ui/Table';
import Text from 'components/shared/ui/Text';
import { useMemo, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Column } from 'react-table';
import { useSetRecoilState } from 'recoil';
import { SelectedCustomerWishListsState } from 'state/EmployeeState';
import { ICustomer } from './EmployeeTypes';
import useCustomerWishLists from './useCustomerWishLists';

interface ICustomerWishListsTableProps {
  customer: ICustomer;
}

export default function CustomerWishListsTable(
  props: ICustomerWishListsTableProps,
) {
  const setSelectedCustomerWishLists = useSetRecoilState(
    SelectedCustomerWishListsState,
  );
  const customerWishLists = useCustomerWishLists(props.customer.ID);
  const history = useHistory();
  useEffect(() => {
    setSelectedCustomerWishLists(customerWishLists);
  }, [customerWishLists, setSelectedCustomerWishLists]);

  const columns: Array<Column<EVA.Core.WishList>> = useMemo(
    () => [
      {
        accessor: 'Name',
        Header: <FormattedMessage id="employee-page.wishlists-table.name" />,
      },
      {
        accessor: 'OrderID',
        Header: (
          <FormattedMessage id="employee-page.wishlists-table.order-id" />
        ),
      },
      {
        accessor: 'AccessToken',
        Header: (
          <FormattedMessage id="employee-page.wishlists-table.access-token" />
        ),
      },
    ],
    [],
  );

  return (
    <>
      {customerWishLists !== undefined && (
        <div className="bg-white p-6 my-10">
          {customerWishLists.length > 0 ? (
            <>
              <Text type="h2" className="mb-4 font-bold">
                <FormattedMessage
                  id="employee-page.wishlists-table.title"
                  values={{ customerName: props.customer.FullName }}
                />
              </Text>
              <Table
                maxHeight={500}
                columns={columns}
                data={customerWishLists}
                handleSelectRow={(wishList) =>
                  history.push(
                    `/geboortelijst/${wishList.OrderID}/${wishList.AccessToken}`,
                  )
                }
              />
            </>
          ) : (
            <Text type="h2" className="font-bold">
              <FormattedMessage
                id="employee-page.no-wishlists-for-customer"
                values={{ customerName: props.customer.FullName }}
              />
            </Text>
          )}
        </div>
      )}
    </>
  );
}
