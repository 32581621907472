import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { state } from '@springtree/eva-sdk-react-recoil';
import NotFoundImage from 'assets/images/product-not-found.png';
import useIsMounted from 'hooks/useIsMounted';

const useProductImage = (id?: number, width = 150, height = 150) => {
  const isMounted = useIsMounted();
  const endpointUrl = useRecoilValue(state.core.evaEndpointUrlState);
  const [productImage, setProductImage] = useState<string>(NotFoundImage);

  useEffect(() => {
    if (id) {
      setProductImage(NotFoundImage);
      fetch(`${endpointUrl}/productimage/${width}/${height}/${id}.jpg`).then(
        (response) => {
          if (response.ok) {
            response.blob().then((blob) => {
              if (isMounted) {
                setProductImage(URL.createObjectURL(blob));
              }
            });
          }
        },
      );
    }
  }, [endpointUrl, height, id, isMounted, width]);

  return productImage;
};

export default useProductImage;
