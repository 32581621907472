import Button from 'components/shared/ui/Button';
import Text from 'components/shared/ui/Text';
import { FormattedMessage } from 'react-intl';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Fragment, useCallback, useEffect, useState } from 'react';
import RangeSlider from 'components/shared/ui/RangeSlider';
import {
  SearchPageFiltersState,
  SearchPageResultsState,
} from './SearchPageState';
import Separator from '../shared/ui/Separator';
import FilterItem from './FilterItem';
import useBrandsFilter from './useBrandsFilter';
import useCategoriesFilter from './useCategoriesFilter';

export default function SearchPageFilters() {
  const [sliderEndValue, setSliderEndValue] = useState<number>();
  const { brands, toggleBrand } = useBrandsFilter();
  const {
    categories,
    toggleCategory,
    toggleSubCategory,
  } = useCategoriesFilter();

  const searchProductsResponse = useRecoilValue(SearchPageResultsState);
  const [searchPageFilters, setSearchPageFilters] = useRecoilState(
    SearchPageFiltersState,
  );

  useEffect(() => {
    const maxPriceFromResponse =
      searchProductsResponse?.Aggregations?.display_price?.stats?.max;

    const roundedPrice =
      maxPriceFromResponse !== undefined
        ? Math.ceil(maxPriceFromResponse)
        : undefined;

    setSearchPageFilters((current) => ({
      ...current,
      selectedMaxPrice: roundedPrice,
    }));
    setSliderEndValue(roundedPrice);
  }, [
    searchProductsResponse?.Aggregations?.display_price?.stats?.max,
    setSearchPageFilters,
  ]);

  const resetFilters = useCallback(() => {
    setSearchPageFilters((current) => ({
      ...current,
      brands: [],
      categories: [],
      selectedMinPrice: 0,
      selectedMaxPrice: sliderEndValue,
      usePriceRange: true,
    }));
  }, [setSearchPageFilters, sliderEndValue]);

  return (
    <div className="bg-white p-4 flex flex-col mt-2 lg:w-80">
      <Button
        onClick={resetFilters}
        variant="text"
        className="focus:outline-none text-left"
      >
        <FormattedMessage id="search-page.filters.clear" />
      </Button>
      <Text type="h2" className="px-4">
        <FormattedMessage id="search-page.filters.price" />
      </Text>
      <Separator color="primary" className="my-4" />
      <RangeSlider
        startValue={0}
        endValue={sliderEndValue ?? 0}
        selectedStartValue={searchPageFilters.selectedMinPrice}
        selectedEndValue={searchPageFilters.selectedMaxPrice ?? 1}
        onAfterChange={(startValue, endValue) =>
          setSearchPageFilters((current) => ({
            ...current,
            currentPage: 1,
            selectedMinPrice: startValue,
            selectedMaxPrice: endValue,
            usePriceRange: true,
          }))
        }
      />
      <Text type="h2" className="px-4">
        <FormattedMessage id="search-page.filters.category" />
      </Text>
      <Separator color="primary" className="my-4" />
      <div className="bg-white px-4 max-h-72 overflow-y-auto overflow-x-hidden">
        {categories.map((category) => (
          <Fragment key={category.name}>
            <FilterItem
              label={category.name}
              selected={category.selected}
              numberOfProducts={category.numberOfProducts}
              toggleSelected={() => toggleCategory(category.name)}
            />
            <div className="ml-6">
              {category.selected &&
                category.subcategories?.length &&
                category.subcategories.map((subCategory) => (
                  <FilterItem
                    key={subCategory.name}
                    label={subCategory.name}
                    selected={subCategory.selected}
                    numberOfProducts={subCategory.numberOfProducts}
                    toggleSelected={() =>
                      toggleSubCategory(category.name, subCategory.name)
                    }
                  />
                ))}
            </div>
          </Fragment>
        ))}
      </div>
      <Separator color="primary" className="my-4" />
      <Text type="h2" className="px-4">
        <FormattedMessage id="search-page.filters.brand" />
      </Text>
      <Separator color="primary" className="my-4" />
      <div className="bg-white px-4 max-h-72 overflow-y-auto overflow-x-hidden">
        {brands.map((item) => (
          <FilterItem
            key={item.name}
            label={item.name}
            selected={item.selected}
            numberOfProducts={item.numberOfProducts}
            toggleSelected={() => toggleBrand(item.name)}
          />
        ))}
      </div>
    </div>
  );
}
