import OrderLineProductImage from 'components/shared/order/OrderLineProductImage';
import useProductImage from 'components/shared/product/useProductImage';
import Price from 'components/shared/ui/Price';
import { FormattedMessage } from 'react-intl';

interface IOrderDetailsCardProps {
  order: EVA.Core.OrderDto;
}

export default function OrderDetailsCard(props: IOrderDetailsCardProps) {
  return (
    <div className="p-6 m-2 rounded shadow">
      {props.order.Lines.map((orderLine) => (
        <div key={orderLine.ID} className="grid grid-cols-3 my-2 items-center">
          <div>
            {orderLine.Product && (
              <OrderLineProductImage
                productId={orderLine.Product.ID}
                className="mx-auto"
              />
            )}
          </div>
          <span className="text-left">
            <FormattedMessage
              id="orders-overview-page.order-details-card.product-quantity"
              values={{
                product: orderLine.Description,
                quantity: orderLine.QuantityOrdered,
              }}
            />
          </span>
          <span className="text-center lg:text-left">
            <Price value={orderLine.TotalAmountInTax} />
          </span>
        </div>
      ))}
    </div>
  );
}
