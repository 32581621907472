import { useIntl } from 'react-intl';
import * as yup from 'yup';

const useSearchCustomersValidationSchema = () => {
  const intl = useIntl();

  const validationSchema: yup.SchemaOf<{ query: string }> = yup
    .object()
    .shape({
      query: yup
        .string()
        .required(intl.formatMessage({ id: 'generic.error.required' })),
    })
    .defined();

  return validationSchema;
};

export default useSearchCustomersValidationSchema;
