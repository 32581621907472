import useWishlist from 'hooks/useWishlist';
import { sum } from 'lodash';
import { useMemo } from 'react';

const useProductFundings = (productId: number) => {
  const { activeWishlist } = useWishlist();

  const linesWithCurrentProduct = useMemo(
    () => activeWishlist?.Lines?.filter((x) => x.Product.ID === productId),
    [activeWishlist?.Lines, productId],
  );

  const donations = useMemo(
    () =>
      linesWithCurrentProduct
        ?.flatMap((x) => x.Fundings)
        .filter((x) => x.IsConfirmed && x.Type === 0),
    [linesWithCurrentProduct],
  );
  const purchases = useMemo(
    () =>
      linesWithCurrentProduct
        ?.flatMap((x) => x.Fundings)
        .filter((x) => x.IsConfirmed && x.Type === 1),
    [linesWithCurrentProduct],
  );

  const quantityOnList = useMemo(
    () => sum(linesWithCurrentProduct?.map((x) => x.Quantity)),
    [linesWithCurrentProduct],
  );

  return {
    donations,
    purchases,
    quantityOnList,
  };
};

export default useProductFundings;
