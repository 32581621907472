import { useShoppingCartAdd } from 'eva/EvaHooks';
import { useCallback } from 'react';
import { Core } from '@springtree/eva-services-core';
import useWishList from 'hooks/useWishlist';

const useAddWishListProductToShoppingCart = () => {
  const { activeWishlist } = useWishList();
  const shoppingCartAdd = useShoppingCartAdd({
    service: Core.AddWishListProductToOrder,
  });

  const addWishListProductToShoppingCart = useCallback(
    ({
      productId,
      quantity,
      lineActionType,
    }: {
      productId: number;
      quantity: number;
      lineActionType: 1 | 4;
    }) => {
      if (activeWishlist) {
        const wishListLinesToBuy: Array<EVA.Core.AddWishListProductToOrderLine> = [];

        const openLines = activeWishlist.Lines.filter(
          (x) => x.Product.ID === productId && x.OpenQuantity > 0,
        );
        while (quantity > 0 && openLines.length > 0) {
          wishListLinesToBuy.push({
            WishListLineID: openLines[0].ID,
            Quantity: Math.min(quantity, openLines[0].OpenQuantity),
          });
          quantity -= Math.min(quantity, openLines[0].OpenQuantity);
          openLines.shift();
        }

        if (wishListLinesToBuy.length) {
          shoppingCartAdd({
            orderType: 0,
            payload: {
              WishListLines: wishListLinesToBuy,
              WishListOrderID: activeWishlist.OrderID,
              AccessToken: activeWishlist.AccessToken,
              LineActionType: lineActionType,
            },
          });
        }
      }
    },
    [activeWishlist, shoppingCartAdd],
  );

  return addWishListProductToShoppingCart;
};

export default useAddWishListProductToShoppingCart;
