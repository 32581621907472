import { FormattedMessage } from 'react-intl';
import Icon from 'components/shared/ui/Icon';
import { useState } from 'react';
import AddWishListDonationDialog from './AddWishListDonationDialog';

export default function AddWishListDonation() {
  const [showDialog, setShowDialog] = useState(false);

  return (
    <>
      <button
        onClick={() => setShowDialog(true)}
        type="button"
        className="max-w-sm bg-transparent border-dashed border-2 border-gray focus:outline-none p-2 flex flex-col justify-center items-center h-80"
      >
        <span className="text-5xl text-primary my-2">
          <Icon name="plus" />
        </span>
        <span className="text-sm my-2 text-center">
          <FormattedMessage id="home-page.wish-list.make-donation" />
        </span>
      </button>
      {showDialog && (
        <AddWishListDonationDialog closeHandler={() => setShowDialog(false)} />
      )}
    </>
  );
}
