import { atom } from 'recoil';
import { SearchSortType, ISearchPageFilters } from './SearchPageTypes';

export const SearchPageFiltersState = atom<ISearchPageFilters>({
  key: 'SearchPage:Filters',
  default: {
    selectedMinPrice: 0,
    pageSize: 15,
    currentPage: 1,
    sortType: SearchSortType.Newest,
    categories: [],
    brands: [],
    usePriceRange: true,
  },
});

export const SearchPageResultsState = atom<
  EVA.Core.SearchProductsResponse | undefined
>({
  key: 'SearchPage:Results',
  default: undefined,
});
