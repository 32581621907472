/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

interface ISeparatorProps {
  className?: string;
  color?: 'primary' | 'gray';
}

export default function Separator(props: ISeparatorProps) {
  return (
    <div
      css={[
        tw`border-b-2`,
        props.color === 'primary'
          ? tw`border-primary border-opacity-50`
          : tw`border-gray`,
      ]}
      className={props.className}
    />
  );
}
