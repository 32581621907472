/** @jsxImportSource @emotion/react */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import tw from 'twin.macro';
import { IOption } from './types/IOption';

interface ISelectProps {
  name?: string;
  value?: string | number;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onBlur?: (e: React.FocusEvent) => void;
  labelId?: string;
  options: Array<IOption>;
  fullWidth?: boolean;
  className?: string;
  error?: string;
  disabled?: boolean;
  hideErrorMessage?: boolean;
}

export default function Select(props: ISelectProps) {
  return (
    <div css={[props.fullWidth && tw`w-full`]} className={props.className}>
      <div className="flex">
        {props.labelId && (
          <div className="uppercase text-sm mb-2">
            <FormattedMessage id={props.labelId} />
          </div>
        )}
      </div>
      {props.error && !props.hideErrorMessage && (
        <div className="text-sm flex-grow text-right text-error mb-2">
          {props.error}
        </div>
      )}
      <select
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        css={[
          tw`border border-gray p-2 text-sm font-light w-full bg-white focus:border-blue`,
          props.disabled && tw`bg-gray cursor-not-allowed`,
        ]}
        disabled={props.disabled}
      >
        {props.options.map((x) => (
          <option
            key={`${x.value} - ${x.label}`}
            value={x.value}
            css={[tw`font-light p-2 bg-white`, x.hidden && tw`hidden`]}
          >
            {x.label}
          </option>
        ))}
      </select>
    </div>
  );
}
