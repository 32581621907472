import useEvaCheckoutService from 'components/checkout/hooks/useEvaCheckoutServices';
import { CheckoutOrderState } from 'components/checkout/state/CheckoutOrder';
import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';

const usePayInStore = () => {
  const checkoutOrder = useRecoilValue(CheckoutOrderState);
  const { evaPlaceOrder } = useEvaCheckoutService();

  return useCallback(async () => {
    if (checkoutOrder) {
      await evaPlaceOrder({
        OrderID: checkoutOrder.ID,
      });

      return true;
    }

    return false;
  }, [checkoutOrder, evaPlaceOrder]);
};

export default usePayInStore;
