import { Field, FieldProps } from 'formik';
import Input from 'components/shared/ui/Input';

interface IInputProps {
  labelId?: string;
  name: string;
  type: 'text' | 'password' | 'number';
  readonly?: boolean;
  hideErrorMessage?: boolean;
  capitalizeLabel?: boolean;
}

export default function FormikInput(props: IInputProps) {
  return (
    <Field name={props.name}>
      {({ field, meta }: FieldProps) => (
        <Input
          type={props.type}
          {...field}
          error={meta.touched ? meta.error : undefined}
          hideErrorMessage={props.hideErrorMessage}
          labelId={props.labelId}
          readonly={props.readonly}
          capitalizeLabel={props.capitalizeLabel}
        />
      )}
    </Field>
  );
}
