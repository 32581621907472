import { useFormikContext } from 'formik';
import { useMemo } from 'react';

interface IAddressFieldErrorProps {
  fieldName: string;
}

export default function AddressFieldError(props: IAddressFieldErrorProps) {
  const { getFieldMeta } = useFormikContext();

  const meta = useMemo(() => getFieldMeta(props.fieldName), [
    getFieldMeta,
    props.fieldName,
  ]);

  return (
    <>
      {meta.touched && !!meta.error && (
        <span className="text-error text-sm">{meta.error}</span>
      )}
    </>
  );
}
