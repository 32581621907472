import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { SearchPageResultsState } from './SearchPageState';
import useGetProducts from './useGetProducts';

export default function SearchProducts() {
  const searchProductsResponse = useGetProducts();
  const setSearchPageResults = useSetRecoilState(SearchPageResultsState);

  useEffect(() => setSearchPageResults(searchProductsResponse), [
    searchProductsResponse,
    setSearchPageResults,
  ]);

  return null;
}
