import { useShoppingCartModify } from 'eva/EvaHooks';
import { useCallback } from 'react';
import { Core } from '@springtree/eva-services-core';
import { useRecoilValue } from 'recoil';
import { ShoppingCartDetailsState } from 'state/CartState';

const useRemoveShoppingCartItem = () => {
  const shoppingCart = useRecoilValue(ShoppingCartDetailsState);
  const modifyShoppingCart = useShoppingCartModify({
    service: Core.CancelOrder,
  });

  const removeShoppingCartItem = useCallback(
    (orderLineId: number) => {
      if (shoppingCart?.ID) {
        modifyShoppingCart({
          payload: {
            OrderID: shoppingCart.ID,
            OrderLines: [{ OrderLineID: orderLineId }],
          },
        });
      }
    },
    [modifyShoppingCart, shoppingCart?.ID],
  );

  return removeShoppingCartItem;
};

export default useRemoveShoppingCartItem;
