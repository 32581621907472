import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { WishListPageUrlParamsState } from 'state/WishlistState';
import { useIntl } from 'react-intl';

const useWishListUrlParamsValidationErrors = () => {
  const params = useParams<{ orderId?: string; accessToken?: string }>();
  const setWishListPageUrlParams = useSetRecoilState(
    WishListPageUrlParamsState,
  );
  const intl = useIntl();
  const [paramsError, setParamsError] = useState<boolean>();

  useEffect(() => {
    if (
      !params.orderId ||
      !params.accessToken ||
      !/^\d+$/.test(params.orderId)
    ) {
      setParamsError(true);
      return;
    }
    setParamsError(false);
    setWishListPageUrlParams(params);
  }, [intl, params, setWishListPageUrlParams]);

  return {
    paramsError,
  };
};

export default useWishListUrlParamsValidationErrors;
