import { hooks } from '@springtree/eva-sdk-react-recoil';

export const {
  useCallService,
  useIsServiceLoading,
  useSetServiceStale,
  useShoppingCartAdd,
  useShoppingCartModify,
  useGetState,
} = hooks;
