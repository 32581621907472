/** @jsxImportSource @emotion/react */
import Button from 'components/shared/ui/Button';
import DatePicker from 'components/shared/ui/DatePicker';
import FormikInput from 'components/shared/ui/FormikInput';
import Separator from 'components/shared/ui/Separator';
import Text from 'components/shared/ui/Text';
import { Formik, Form } from 'formik';
import { FormattedMessage } from 'react-intl';
import tw, { css } from 'twin.macro';
import { addMonths } from 'date-fns';
import { useMemo, useState } from 'react';
import { EditWishListValues } from './ProfileTypes';
import useEditWishListValidationSchema from './useEditWishListValidationSchema';
import AppConfiguration from '../../AppConfiguration';
import useUpdateWishList from './useUpdateWishList';
import ChangeWishListImageDialog from './ChangeWishListImageDialog';
import useWishListImage from '../../hooks/useWishListImage';
import useParseDate from '../../hooks/useParseDate';

interface IEditWishListProps {
  wishList?: EVA.Core.WishList;
}

export default function EditWishList(props: IEditWishListProps) {
  const editWishListValidationSchema = useEditWishListValidationSchema();
  const { updateWishListValues, setWishListImage } = useUpdateWishList();
  const [showChangeImageDialog, setShowChangeImageDialog] = useState(false);
  const wishListImage = useWishListImage(
    props.wishList?.Data?.imgId ?? AppConfiguration.wishListImages[0].imageId,
  );
  const wishListImageStyle = useMemo(
    () => css`
      background-image: url(${wishListImage});
    `,
    [wishListImage],
  );
  const parseDate = useParseDate();

  return (
    <>
      {props.wishList && (
        <>
          <Formik<EditWishListValues>
            initialValues={{
              wishListName: props.wishList.Name,
              dueDate: props.wishList.Data?.dueDate
                ? parseDate(props.wishList.Data.dueDate)
                : undefined,
              requestedDeliveryDate: props.wishList.Data?.requestedDeliveryDate
                ? parseDate(props.wishList.Data.requestedDeliveryDate)
                : undefined,
            }}
            onSubmit={(values) => updateWishListValues(props.wishList, values)}
            validationSchema={editWishListValidationSchema}
          >
            <Form>
              <div className="p-6 bg-white mb-10">
                <Text type="h3" className="text-xl font-bold">
                  <FormattedMessage
                    id="profile-page.edit-wish-list.wish-list-name"
                    values={{ wishListName: props.wishList.Name }}
                  />
                </Text>
                <Separator className="my-6" />
                <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-12 p-4">
                  <div className="flex flex-col font-bold ">
                    <FormattedMessage id="profile-page.edit-wish-list.name" />
                    <FormikInput type="text" name="wishListName" />
                    <FormattedMessage id="profile-page.edit-wish-list.calculated-date" />
                    <DatePicker
                      name="dueDate"
                      minDate={new Date()}
                      maxDate={addMonths(new Date(), 9)}
                    />
                    <FormattedMessage id="profile-page.edit-wish-list.desired-delivery-date" />
                    <DatePicker
                      name="requestedDeliveryDate"
                      minDate={new Date()}
                      disableSundays
                    />
                  </div>
                  <div className="col-span-2">
                    <div className="font-bold">
                      <FormattedMessage id="profile-page.edit-wish-list.change-photo" />
                    </div>
                    <div
                      css={[
                        tw`bg-center bg-cover bg-no-repeat h-56 w-full mt-2 flex`,
                        wishListImageStyle,
                      ]}
                    >
                      <button
                        type="button"
                        className="px-4 py-2 border border-white text-white text-sm font-bold m-auto bg-gray-dark bg-opacity-40"
                        onClick={() => setShowChangeImageDialog(true)}
                      >
                        <FormattedMessage id="profile-page.edit-wish-list.choose-photo" />
                      </button>
                    </div>
                  </div>
                </div>
                <Button underline type="submit">
                  <FormattedMessage id="generic.save" />
                </Button>
              </div>
            </Form>
          </Formik>
          {showChangeImageDialog && (
            <ChangeWishListImageDialog
              closeHandler={() => setShowChangeImageDialog(false)}
              onSave={(imageId) => setWishListImage(props.wishList, imageId)}
              currentImageId={
                props.wishList.Data.imgId ??
                AppConfiguration.wishListImages[0].imageId
              }
            />
          )}
        </>
      )}
    </>
  );
}
