import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { state } from '@springtree/eva-sdk-react-recoil';

const useClearShoppingCart = () => {
  const setShoppingCartRequest = useSetRecoilState(
    state.checkout.shoppingCartState.request,
  );

  const clearShoppingCart = useCallback(() => {
    setShoppingCartRequest(undefined);
  }, [setShoppingCartRequest]);

  return clearShoppingCart;
};

export default useClearShoppingCart;
