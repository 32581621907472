import useAvailabilityIndication from 'hooks/useAvailabilityIndication';
import { uniqBy } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import useWishlist from 'hooks/useWishlist';
import {
  WishListFiltersState,
  ActiveWishListItemAvailabilityState,
} from './WishListState';
import { WishListSortType } from './WishListTypes';

const useFilteredWishListLines = () => {
  const { activeWishlist, isParentOrEmployee } = useWishlist();
  const wishListFilters = useRecoilValue(WishListFiltersState);

  const availabilityIndication = useRecoilValue(
    ActiveWishListItemAvailabilityState,
  );

  const filteredWishListLines:
    | Array<EVA.Core.WishListLine>
    | undefined = useMemo(() => {
    if (!activeWishlist) {
      return undefined;
    }

    let lines = [...activeWishlist.Lines];

    lines.sort((a, b) => {
      if (wishListFilters.sortType === WishListSortType.Position) {
        return lines.indexOf(a) - lines.indexOf(b);
      }
      return (
        (a.OpenAmount - b.OpenAmount) *
        (wishListFilters.sortType === WishListSortType.PriceAscending ? 1 : -1)
      );
    });

    if (!isParentOrEmployee || wishListFilters.showNotOrdered) {
      lines = lines.filter(
        (x) => x.OpenQuantity > 0 || x.Fundings.length === 0,
      );
    }

    if (!isParentOrEmployee) {
      lines = lines.filter((line) => {
        const availabilityForLineProduct = availabilityIndication?.Products?.find(
          (x) => x.ProductID === line.Product.ID,
        );

        if (
          availabilityForLineProduct?.ProductStatus === 32 ||
          availabilityForLineProduct?.ProductStatus === 34
        ) {
          const hasStock =
            availabilityForLineProduct.StoreAvailabilityCount > 0 ||
            (availabilityForLineProduct.Delivery.QuantityAvailable ?? 0) > 0;

          return hasStock && !line.IsHidden;
        }
        return !line.IsHidden;
      });
    }

    if (wishListFilters.showUniqueProducts) {
      lines = uniqBy(lines, (x) => x.Product.ID);
    }

    return lines;
  }, [
    activeWishlist,
    availabilityIndication?.Products,
    isParentOrEmployee,
    wishListFilters.showNotOrdered,
    wishListFilters.showUniqueProducts,
    wishListFilters.sortType,
  ]);

  return filteredWishListLines;
};

export default useFilteredWishListLines;
