import Address from 'components/shared/address/Address';
import Separator from 'components/shared/ui/Separator';
import Text from 'components/shared/ui/Text';
import { Form, Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import FormikInput from 'components/shared/ui/FormikInput';
import Button from 'components/shared/ui/Button';
import { useHistory } from 'react-router-dom';
import { InitialAddressValues } from 'components/shared/address/AddressValues';
import useGetCurrentUser from 'hooks/useGetCurrentUser';
import useWishList from 'hooks/useWishlist';
import { EditPersonalInformationValues } from './ProfileTypes';
import useEditPersonalInformationValidationSchema from './useEditPersonalInformationValidationSchema';
import useUpdateUser from './useUpdateUser';
import useAddressBook from './useAddressBook';

export default function EditPersonalInformation() {
  const history = useHistory();
  const editPersonalInformationValidationSchema = useEditPersonalInformationValidationSchema();
  const { currentUser } = useGetCurrentUser();
  const { updateUser } = useUpdateUser();
  const {
    billingAddress,
    shippingAddress,
    hasShippingAddress,
    updateBillingAddress,
    updateShippingAddress,
  } = useAddressBook();
  const { activeWishlist } = useWishList();

  const handleSubmit = (values: EditPersonalInformationValues) => {
    updateUser(values);
    if (values.billingAddress) {
      updateBillingAddress(values.billingAddress);
    }
    if (values.hasShippingAddress && values.shippingAddress) {
      updateShippingAddress(values.shippingAddress);
    } else if (!values.hasShippingAddress && values.billingAddress) {
      updateShippingAddress(values.billingAddress);
    }
  };

  return (
    <>
      {currentUser && (
        <Formik<EditPersonalInformationValues>
          enableReinitialize
          initialValues={{
            firstName: currentUser.FirstName,
            lastName: currentUser.LastName,
            email: currentUser.EmailAddress,
            phoneNumber: currentUser.PhoneNumber ?? '',
            hasShippingAddress,
            billingAddress: billingAddress ?? InitialAddressValues,
            shippingAddress: hasShippingAddress ? shippingAddress : undefined,
          }}
          onSubmit={handleSubmit}
          validationSchema={editPersonalInformationValidationSchema}
        >
          {() => (
            <Form>
              <div className="p-6 bg-white mb-10">
                <Text type="h3" className="text-xl font-bold">
                  <FormattedMessage
                    id="profile-page.personal-information"
                    values={{ wishListName: 'test' }}
                  />
                </Text>
                <Separator className="my-6" />
                <div className="p-4">
                  <Text type="h3" className="mb-2 uppercase">
                    <FormattedMessage id="profile-page.personal-information" />
                  </Text>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <FormikInput
                      type="text"
                      name="firstName"
                      labelId="generic.first-name"
                    />
                    <FormikInput
                      type="text"
                      name="phoneNumber"
                      labelId="generic.telephone"
                    />
                    <FormikInput
                      type="text"
                      name="lastName"
                      labelId="generic.last-name"
                    />
                    <FormikInput
                      type="text"
                      name="email"
                      labelId="generic.email-address"
                      readonly
                    />
                  </div>
                  <div className="lg:mr-2">
                    <Address />
                  </div>
                  <div className="flex justify-between">
                    {activeWishlist && (
                      <Button
                        variant="text"
                        onClick={() =>
                          history.push(
                            `/geboortelijst/${activeWishlist.OrderID}/${activeWishlist?.AccessToken}`,
                          )
                        }
                      >
                        <FormattedMessage id="generic.back" />
                      </Button>
                    )}
                    <Button underline type="submit">
                      <FormattedMessage id="generic.save" />
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
}
