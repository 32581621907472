import Button from 'components/shared/ui/Button';
import Text from 'components/shared/ui/Text';
import { FormattedMessage, useIntl } from 'react-intl';
import SuspenseFallback from 'eva/SuspenseFallback';
import useLogin from 'components/login/useLogin';
import { useHistory } from 'react-router-dom';
import HelmetTitle from 'components/shared/title/HelmetTitle';
import EditPersonalInformation from './EditPersonalInformation';
import EditWishList from './EditWishList';
import useGetCurrentUser from '../../hooks/useGetCurrentUser';
import useWishList from '../../hooks/useWishlist';

export default function ProfilePage() {
  const { logout } = useLogin();
  const history = useHistory();
  const { isEmployee } = useGetCurrentUser();
  const { isParent, selectedUserWishList, activeWishlist } = useWishList();
  const intl = useIntl();

  return (
    <>
      <HelmetTitle
        title={intl.formatMessage({ id: 'profile-page.helmet-title' })}
      />
      <div className="container mx-auto p-6 lg:p-10">
        <div className="flex flex-col sm:flex-row sm:items-center mb-10">
          <Text type="h1" className="font-bold mb-2 sm:mb-4">
            <FormattedMessage id="profile-page.title" />
          </Text>
          <Button
            underline
            className="sm:ml-auto sm:mb-0 sm:mr-4 mb-4"
            onClick={() => history.push('/orders-overview')}
          >
            <FormattedMessage id="profile-page.my-orders" />
          </Button>
          <Button
            variant="text"
            underline
            className="bg-white"
            onClick={logout}
          >
            <span className="text-black">
              <FormattedMessage id="generic.logout" />
            </span>
          </Button>
        </div>
        <SuspenseFallback>
          <EditWishList
            wishList={isEmployee ? activeWishlist : selectedUserWishList}
          />
        </SuspenseFallback>
        {isParent && (
          <SuspenseFallback>
            <EditPersonalInformation />
          </SuspenseFallback>
        )}
      </div>
    </>
  );
}
