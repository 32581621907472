import { Field, FieldProps } from 'formik';
import Select from 'react-select';
import { useIntl, FormattedMessage } from 'react-intl';
import { theme } from 'twin.macro';
import { IOption } from './types/IOption';

interface IAutocompleteProps {
  name: string;
  labelId: string;
  options: Array<IOption>;
  placeholderId?: string;
  onChange?: (value?: string) => void;
  onSelect?: (value?: string) => void;
  hideErrorMessage?: boolean;
}

export default function Autocomplete(props: IAutocompleteProps) {
  const intl = useIntl();

  const getBorderColor = (isFocused: boolean, hasError: boolean) => {
    if (isFocused) {
      return theme`colors.blue`;
    }
    if (hasError) {
      return theme`colors.error`;
    }
    return theme`colors.gray`;
  };

  return (
    <Field name={props.name}>
      {({ field, meta, form }: FieldProps) => (
        <div className="w-full mb-4">
          <div className="flex">
            <div className="uppercase text-sm">
              <FormattedMessage id={props.labelId} />
            </div>
            {meta.touched && meta.error && !props.hideErrorMessage && (
              <div className="text-sm flex-grow text-right text-error">
                {meta.error}
              </div>
            )}
          </div>
          <Select
            {...field}
            className="mt-2"
            isSearchable
            placeholder={
              props.placeholderId
                ? intl.formatMessage({ id: props.placeholderId })
                : ''
            }
            noOptionsMessage={() =>
              intl.formatMessage({ id: 'generic.no-results' })
            }
            options={props.options}
            onInputChange={(value) => {
              form.setFieldValue(props.name, value);
              if (props.onChange) {
                props.onChange(value);
              }
            }}
            onChange={(value) => {
              if (props.onSelect) {
                props.onSelect(value?.value);
              }
            }}
            styles={{
              control: (base, state) => ({
                ...base,
                borderRadius: 0,
                borderColor: getBorderColor(
                  state.isFocused,
                  !!meta.error && meta.touched,
                ),
                backgroundColor: state.isFocused
                  ? theme`colors.gray`
                  : base.backgroundColor,
                boxShadow: 'none',
              }),
            }}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
          />
        </div>
      )}
    </Field>
  );
}
