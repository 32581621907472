import { useMemo } from 'react';
import * as Yup from 'yup';
import useAddressValidationSchema from 'components/shared/address/useAddressValidationSchema';
import { useIntl } from 'react-intl';
import { CheckoutProfileValues } from './CheckoutProfileTypes';

const useCheckoutProfileValidationSchema = () => {
  const addressValidationSchema = useAddressValidationSchema();
  const intl = useIntl();

  return useMemo(
    (): Yup.SchemaOf<CheckoutProfileValues> =>
      Yup.object()
        .shape({
          firstName: Yup.string().required(
            intl.formatMessage({ id: 'generic.error.required' }),
          ),
          lastName: Yup.string().required(
            intl.formatMessage({ id: 'generic.error.required' }),
          ),
          phoneNumber: Yup.string().optional(),
          email: Yup.string()
            .email(
              intl.formatMessage({ id: 'generic.error.valid.email-address' }),
            )
            .required(intl.formatMessage({ id: 'generic.error.required' })),
          billingAddress: addressValidationSchema,
          shippingAddress: Yup.mixed().when('hasShippingAddress', {
            is: true,
            then: addressValidationSchema,
            otherwise: Yup.mixed().optional(),
          }),
        })
        .defined(),
    [addressValidationSchema, intl],
  );
};

export default useCheckoutProfileValidationSchema;
