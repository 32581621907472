import ProductCardPlaceholder from 'components/shared/product/ProductCardPlaceholder';
import { range } from 'lodash';
import { useRecoilValue } from 'recoil';
import { SearchPageFiltersState } from './SearchPageState';

export default function SearchPageResultsPlaceholder() {
  const { pageSize } = useRecoilValue(SearchPageFiltersState);
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6 mb-4">
      {range(0, pageSize).map((x) => (
        <ProductCardPlaceholder key={x} />
      ))}
    </div>
  );
}
