import Button from 'components/shared/ui/Button';
import { useState, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import useHorizontalWindowResize from 'hooks/useHorizontalWindowResize';
import SearchPageFilters from './SearchPageFilters';

export default function SearchPageFiltersWrapper() {
  const [showFilters, setShowFilters] = useState(
    window.matchMedia('(min-width: 768px)').matches,
  );

  const handleHorizontalResize = useCallback(() => {
    setShowFilters(window.matchMedia('(min-width: 768px)').matches);
  }, []);

  useHorizontalWindowResize(handleHorizontalResize);

  return (
    <div className="flex flex-col items-stretch">
      <Button
        className="w-full md:hidden"
        onClick={() => setShowFilters((prev) => !prev)}
      >
        <FormattedMessage
          id={
            showFilters
              ? 'search-page.filters.hide'
              : 'search-page.filters.show'
          }
        />
      </Button>
      {showFilters && (
        <div className="md:flex md:items-stretch md:flex-1">
          <SearchPageFilters />
        </div>
      )}
    </div>
  );
}
