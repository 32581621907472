/** @jsxImportSource @emotion/react */
import { FormattedMessage } from 'react-intl';
import tw, { css } from 'twin.macro';
import { useMemo } from 'react';
import useWishlist from 'hooks/useWishlist';
import useWishListImage from 'hooks/useWishListImage';
import AppConfiguration from 'AppConfiguration';
import WishListCoverFundings from './WishListCoverFundings';

export default function WishListCover() {
  const { activeWishlist, isParent } = useWishlist();
  const wishListImageUrl = useWishListImage(
    activeWishlist?.Data?.imgId ?? AppConfiguration.wishListImages[0].imageId,
  );
  const coverImageStyle = useMemo(
    () => css`
      background-image: url(${wishListImageUrl});
    `,
    [wishListImageUrl],
  );

  return (
    <>
      {activeWishlist && (
        <div
          css={[
            tw`relative h-80 bg-center bg-no-repeat bg-cover`,
            coverImageStyle,
          ]}
        >
          <div className=" w-full h-full bg-gradient-to-r from-primary-transparent bg-opacity-70 p-4 lg:p-10 flex flex-col justify-between">
            <div>
              <div className="mt-4 text-white text-xl">
                <FormattedMessage id="home-page.wish-list.cover.title" />
              </div>
              <div className="mt-2 text-white text-4xl font-bold">
                {activeWishlist.Name}
              </div>
              <div className="mt-2 text-white text-sm">
                <FormattedMessage
                  id="home-page.wish-list.cover.wishlist-id"
                  values={{
                    wishlistId: activeWishlist.OrderID,
                    accessToken: activeWishlist.AccessToken,
                  }}
                />
              </div>
            </div>
            {isParent && <WishListCoverFundings />}
          </div>
        </div>
      )}
    </>
  );
}
