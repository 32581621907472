/** @jsxImportSource @emotion/react */
import { Field, FieldProps } from 'formik';
import ReactDatePicker from 'react-datepicker';
import { FormattedMessage } from 'react-intl';
import tw, { css, theme } from 'twin.macro';
import { useCallback } from 'react';
import { getDay } from 'date-fns';
import CustomDatePickerInput from './CustomDatePickerInput';
import AppConfiguration from '../../../AppConfiguration';

interface IDatePickerProps {
  name: string;
  labelId?: string;
  minDate?: Date;
  maxDate?: Date;
  onChange?: any;
  disableSundays?: boolean;
}

export default function DatePicker(props: IDatePickerProps) {
  const filterDate = useCallback(
    (date: Date) => props.disableSundays !== true || getDay(date) !== 0,
    [props.disableSundays],
  );

  return (
    <Field name={props.name}>
      {({ field, form, meta }: FieldProps) => (
        <div
          css={[
            tw`w-full mb-4`,
            css`
              & input {
                border-color: ${meta.touched && meta.error !== undefined
                  ? theme`colors.error`
                  : theme`colors.gray`};
              }
            `,
          ]}
        >
          <div className="flex">
            {props.labelId && (
              <div className="uppercase text-sm">
                <FormattedMessage id={props.labelId} />
              </div>
            )}
            {meta.touched && meta.error && (
              <div className="text-sm flex-grow text-right text-error">
                {meta.error}
              </div>
            )}
          </div>
          <ReactDatePicker
            dateFormat={AppConfiguration.dateFormat}
            wrapperClassName="w-full"
            {...field}
            onCalendarClose={() => form.setFieldTouched(field.name, true)}
            selected={field.value}
            onChange={(date) => {
              form.setFieldValue(props.name, date);
              if (props.onChange) {
                props.onChange(date);
              }
            }}
            customInput={<CustomDatePickerInput />}
            popperPlacement="bottom-end"
            showPopperArrow={false}
            minDate={props.minDate}
            maxDate={props.maxDate}
            filterDate={filterDate}
          />
        </div>
      )}
    </Field>
  );
}
