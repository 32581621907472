/* eslint-disable import/prefer-default-export */
import { atom } from 'recoil';
import { WishListDonationValues } from '../components/shared/donate/DonateTypes';

export const WishListDonationValuesState = atom<
  WishListDonationValues | undefined
>({
  key: 'WishListDonationValues',
  default: undefined,
});
