import useAddressValidationSchema from 'components/shared/address/useAddressValidationSchema';
import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { RegisterValues } from './RegisterTypes';

const useRegisterValidationSchema = () => {
  const intl = useIntl();
  const addressValidationSchema = useAddressValidationSchema();

  const validationSchema: yup.SchemaOf<RegisterValues> = yup
    .object()
    .shape({
      firstName: yup
        .string()
        .required(intl.formatMessage({ id: 'generic.error.required' })),
      lastName: yup
        .string()
        .required(intl.formatMessage({ id: 'generic.error.required' })),
      phoneNumber: yup.string().optional(),
      email: yup
        .string()
        .email(intl.formatMessage({ id: 'generic.error.valid.email-address' }))
        .required(intl.formatMessage({ id: 'generic.error.required' })),
      requestedDeliveryDate: yup
        .date()
        .required(intl.formatMessage({ id: 'generic.error.required' })),
      password: yup
        .string()
        .min(
          8,
          intl.formatMessage(
            { id: 'generic.error.min-length' },
            { minLength: 8 },
          ),
        )
        .required(intl.formatMessage({ id: 'generic.password.required' })),
      confirmPassword: yup
        .string()
        .required(intl.formatMessage({ id: 'generic.repeat-password' }))
        .test(
          'passwords-match',
          intl.formatMessage({ id: 'generic.error.confirm-password' }),
          // eslint-disable-next-line func-names
          function (value) {
            return this.parent.password === value;
          },
        ),
      billingAddress: addressValidationSchema,
      shippingAddress: yup.mixed().when('hasShippingAddress', {
        is: true,
        then: addressValidationSchema,
        otherwise: yup.mixed().optional(),
      }),
    })
    .defined();

  return validationSchema;
};

export default useRegisterValidationSchema;
