import { AddressValues } from '../address/AddressValues';

export default function useMapAddress() {
  const mapAddress = (
    values?: AddressValues,
  ): EVA.Core.AddressDataDto | undefined => {
    if (values) {
      return {
        City: values.city,
        CountryID: values.countryId,
        HouseNumber: values.houseNumber,
        Street: values.street,
        ZipCode: values.zipcode,
      };
    }
    return undefined;
  };

  return { mapAddress };
}
