import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { EditWishListValues } from './ProfileTypes';

const useEditWishListValidationSchema = () => {
  const intl = useIntl();

  const validationSchema: yup.SchemaOf<EditWishListValues> = yup
    .object()
    .shape({
      wishListName: yup
        .string()
        .required(intl.formatMessage({ id: 'generic.error.required' })),
    })
    .defined();

  return validationSchema;
};

export default useEditWishListValidationSchema;
