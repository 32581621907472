/** @jsxImportSource @emotion/react */
import Footer from 'components/shared/layout/Footer';
import { BrowserRouter } from 'react-router-dom';
import nl from 'assets/i18n/nl.json';
import { IntlProvider } from 'react-intl';
import Routes from 'Routes';
import { EvaRecoilRoot } from 'eva/EvaComponents';
import Header from 'components/shared/layout/Header';
import tw from 'twin.macro';
import { useSetRecoilState } from 'recoil';
import { state } from '@springtree/eva-sdk-react-recoil';
import { useEffect } from 'react';
import SuspenseFallback from 'eva/SuspenseFallback';
import { HelmetProvider } from 'react-helmet-async';
import BaseAppTitle from 'components/shared/title/BaseAppTitle';
import { ToastContainer } from 'react-toastify';
import useUserExpired from './hooks/useUserExpired';
import evaSettings from './assets/config/eva-config.json';
import useFetchActiveWishList from './hooks/useFetchActiveWishList';
import useFetchShoppingCart from './hooks/useFetchShoppingCart';
import useFetchCurrentUser from './hooks/useFetchCurrentUser';

export default function App() {
  const { userExpired } = useUserExpired();

  return (
    <EvaRecoilRoot
      endpointUrl={evaSettings.endpoint}
      userExpiredCallback={userExpired}
    >
      <SuspenseFallback>
        <FetchCurrentUser />
      </SuspenseFallback>
      <SuspenseFallback>
        <FetchActiveWishList />
      </SuspenseFallback>
      <SuspenseFallback>
        <FetchShoppingCart />
      </SuspenseFallback>

      <HelmetProvider>
        <IntlProvider locale="nl" messages={nl}>
          <>
            <BaseAppTitle />
            <ToastContainer />
            <BrowserRouter>
              <div css={[tw`flex flex-col`, { minHeight: '100vh' }]}>
                <Header />
                <div className="flex-1">
                  <Routes />
                </div>
                <Footer />
              </div>
            </BrowserRouter>
          </>
        </IntlProvider>
      </HelmetProvider>
    </EvaRecoilRoot>
  );
}

const FetchCurrentUser = () => {
  useFetchCurrentUser();

  return null;
};

const FetchActiveWishList = () => {
  useFetchActiveWishList();

  return null;
};

const FetchShoppingCart = () => {
  const setQueryState = useSetRecoilState(
    state.core.requestedOrganizationUnitQueryState,
  );

  useEffect(() => {
    setQueryState({
      Name: 'Geboortelijst',
      BackendID: 'geboortelijst',
    });
  }, [setQueryState]);

  useFetchShoppingCart();
  return null;
};
