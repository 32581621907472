import { atom, selector, selectorFamily } from 'recoil';
import { state } from '@springtree/eva-sdk-react-recoil';

export const CurrentCurrencySelector = selector<string>({
  key: 'Cart:CurrentCurrency',
  get: ({ get }) => {
    const shoppingCart = get(ShoppingCartDetailsState);
    const currentUser = get(state.currentUser.currentUserState.response);

    return (
      shoppingCart?.CurrencyID ?? currentUser?.User?.CurrentCurrencyID ?? 'EUR'
    );
  },
});

export const ShoppingCartDetailsState = atom<EVA.Core.OrderDto | undefined>({
  key: 'Cart:Details',
  default: undefined,
});

export const ShoppingCartAmountsState = atom<
  EVA.Core.OpenOrderAmounts | undefined
>({
  key: 'Cart:Amounts',
  default: undefined,
});

export const ShoppingCartProductQuantityState = selectorFamily<
  number,
  number | undefined
>({
  key: 'Cart:ProductQuantity',
  get: (productId: number | undefined) => ({ get }) => {
    if (productId === undefined) {
      return 0;
    }
    const shoppingCart = get(ShoppingCartDetailsState);

    return (
      shoppingCart?.Lines?.filter((x) => x.ProductID === productId)?.reduce(
        (acc, curr) => acc + curr.TotalQuantityToShip,
        0,
      ) ?? 0
    );
  },
});
