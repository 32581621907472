import { state } from '@springtree/eva-sdk-react-recoil';
import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { CurrentUserState, CurrentUserTypeState } from 'state/CurrentUserState';

const useFetchCurrentUser = () => {
  const currentUserResponse = useRecoilValue(
    state.currentUser.currentUserState.response,
  );
  const currentUserType = useRecoilValue(
    state.currentUser.currentUserTypeState,
  );
  const setCurrentUserType = useSetRecoilState(CurrentUserTypeState);

  useEffect(() => setCurrentUserType(currentUserType), [
    currentUserType,
    setCurrentUserType,
  ]);

  const setCurrentUser = useSetRecoilState(CurrentUserState);

  useEffect(() => {
    setCurrentUser(
      currentUserResponse?.User?.Type === 4
        ? undefined
        : currentUserResponse?.User,
    );
  }, [currentUserResponse?.User, setCurrentUser]);
};

export default useFetchCurrentUser;
