import { useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { EvaStateContext } from 'eva/EvaComponents';
import useWishlist from 'hooks/useWishlist';
import { useCallService } from 'eva/EvaHooks';
import { FTH } from 'eva/eva-services-fth';
import { useIntl } from 'react-intl';
import { RegisterValues } from '../shared/profile/RegisterTypes';
import useIsMounted from '../../hooks/useIsMounted';
import useCreateCustomer from '../shared/profile/useCreateCustomer';

export default function useRegistration() {
  const isMounted = useIsMounted();
  const history = useHistory();
  const [emailAlreadyInUseError, setEmailAlreadyInUse] = useState(false);
  const { createNewWishlist } = useWishlist();
  const intl = useIntl();

  const { createCustomer } = useCreateCustomer(true);
  const evaStateContext = useContext(EvaStateContext);
  const setDueDateOnOrder = useCallService({
    service: FTH.FTHSetDueDateOnOrder,
  });

  const register = useCallback(
    async (values: RegisterValues) => {
      const localCreateCustomerResponse = await createCustomer(values);

      if (
        localCreateCustomerResponse &&
        localCreateCustomerResponse?.Result === 0
      ) {
        const wishlist = await createNewWishlist(
          {
            Name:
              values.wishlistName?.trim() ||
              intl.formatMessage(
                { id: 'generic.default-wishlist-name' },
                { lastName: values.lastName },
              ),
            Data: {
              lastName: values.lastName,
              dueDate: values.dueDate,
              requestedDeliveryDate: values.requestedDeliveryDate,
            },
          },
          localCreateCustomerResponse.User.AuthenticationToken,
        );

        if (wishlist) {
          localStorage.setItem(
            'activeWishListAccessToken',
            wishlist.AccessToken,
          );
          localStorage.setItem(
            'activeWishListOrderId',
            wishlist.OrderID.toString(),
          );
          setDueDateOnOrder(
            {
              OrderID: wishlist.OrderID,
              DueDate: values.dueDate?.toISOString(),
            },
            {
              authenticationToken:
                localCreateCustomerResponse.User.AuthenticationToken,
            },
          );

          localStorage.removeItem('parentInformationModalShown');

          evaStateContext.setUserTokenState(
            localCreateCustomerResponse.User.AuthenticationToken,
          );

          history.push(
            `/geboortelijst/${wishlist.OrderID}/${wishlist.AccessToken}`,
          );
        }
      }

      if (localCreateCustomerResponse?.Result === 3 && isMounted) {
        setEmailAlreadyInUse(true);
      }
    },
    [
      createCustomer,
      createNewWishlist,
      evaStateContext,
      history,
      isMounted,
      setDueDateOnOrder,
    ],
  );

  return { register, emailAlreadyInUseError };
}
