import { hooks, state } from '@springtree/eva-sdk-react-recoil';
import { Core } from '@springtree/eva-services-core';
import AppConfiguration from 'AppConfiguration';
import { useIsServiceLoading, useSetServiceStale } from 'eva/EvaHooks';
import { first, isEqual } from 'lodash';
import { useCallback, useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  ActiveWishListIdentifierState,
  UserWishListsState,
  WishListPageUrlParamsState,
  ActiveWishListState,
  SelectedUserWishListState,
  WishListNotFoundForUrlState,
} from 'state/WishlistState';
import useGetCurrentUser from './useGetCurrentUser';
import useIsMounted from './useIsMounted';

const useFetchActiveWishList = () => {
  // current active user
  const { currentUser } = useGetCurrentUser();

  const params = useRecoilValue(WishListPageUrlParamsState);
  const isMounted = useIsMounted();

  const isFetchingWishList = useIsServiceLoading({
    serviceState: state.wishList.wishListDetailsState,
  });

  // atom wich defines the current active list
  const [
    activeWishListIdentifier,
    setActiveWishListIdentifier,
  ] = useRecoilState(ActiveWishListIdentifierState);

  const [activeWishlist, setActiveWishlist] = useRecoilState(
    ActiveWishListState,
  );

  const [userWishlists, setUserWishlists] = useRecoilState(UserWishListsState);

  // wishlist detail request atom
  const [wishListDetailsRequest, setWishListDetailsRequest] = useRecoilState(
    state.wishList.wishListDetailsState.request,
  );

  // wishlist for user request atom
  const [wishListsForUserRequest, setWishListsForUserRequest] = useRecoilState(
    state.wishList.wishListsForUserState.request,
  );

  // wishlist detail response atom
  const activeWishListResponse = useRecoilValue(
    state.wishList.wishListDetailsState.response,
  );

  const activeWishListResponseError = useRecoilValue(
    state.wishList.wishListDetailsState.error,
  );

  // userWishlists response atom
  const userWishlistsResponse = useRecoilValue(
    state.wishList.wishListsForUserState.response,
  );

  // wishlist detail service trigger
  const refreshWishListDetails = useSetServiceStale({
    serviceState: state.wishList.wishListDetailsState,
  });

  const [selectedUserWishList, setSelectedUserWishList] = useRecoilState(
    SelectedUserWishListState,
  );

  const setWishListNotFoundForUrl = useSetRecoilState(
    WishListNotFoundForUrlState,
  );

  useEffect(() => {
    setWishListNotFoundForUrl(
      activeWishListResponseError !== undefined &&
        !isFetchingWishList &&
        wishListDetailsRequest !== undefined,
    );
  }, [
    activeWishListResponseError,
    isFetchingWishList,
    setWishListNotFoundForUrl,
    wishListDetailsRequest,
  ]);

  useEffect(() => {
    if (
      activeWishListResponse?.Result &&
      !isEqual(activeWishlist, activeWishListResponse?.Result)
    ) {
      setActiveWishlist(activeWishListResponse?.Result);
    }
  }, [activeWishlist, setActiveWishlist, activeWishListResponse]);

  // only return lists if we have a current user.
  useEffect(() => {
    if (
      userWishlistsResponse?.Result &&
      !isEqual(userWishlists, userWishlistsResponse?.Result)
    ) {
      setUserWishlists(userWishlistsResponse?.Result);
    }
  }, [userWishlists, setUserWishlists, userWishlistsResponse]);

  useEffect(() => {
    setSelectedUserWishList((current) => {
      if (!current && userWishlists) {
        return userWishlists[0];
      }
      if (current) {
        const updatedList = userWishlists?.find(
          (x) => x.OrderID === current.OrderID,
        );
        if (updatedList) {
          return updatedList;
        }
      }
      return undefined;
    });
  }, [setSelectedUserWishList, userWishlists]);

  useEffect(() => {
    if (
      currentUser &&
      activeWishListResponse?.Result?.Customer?.ID === currentUser.ID
    ) {
      setSelectedUserWishList(activeWishListResponse.Result);
    }
  }, [activeWishListResponse?.Result, currentUser, setSelectedUserWishList]);

  // return the active list
  // const activeWishlist = useMemo(() => {
  //   // console.log('MEMO activeWishListRepsonse', activeWishListResponse?.Result);
  //   return activeWishListResponse?.Result;
  // }, [activeWishListResponse?.Result]);

  // const isParent = useMemo(
  //   () => currentUser && activeWishlist?.Customer?.ID === currentUser.ID,
  //   [activeWishlist?.Customer?.ID, currentUser],
  // );

  // callback to change the active list.
  const updateActiveList = useCallback(
    (orderId: number, accessToken: string) => {
      if (
        !activeWishListIdentifier ||
        activeWishListIdentifier?.orderId !== orderId
      ) {
        localStorage.setItem('activeWishListOrderId', orderId.toString());
        localStorage.setItem('activeWishListAccessToken', accessToken);

        setActiveWishListIdentifier({
          orderId,
          accessToken,
        });
      }
    },
    [activeWishListIdentifier, setActiveWishListIdentifier],
  );

  // whenver active list changes, update detail request
  useEffect(() => {
    if (
      activeWishListIdentifier?.accessToken &&
      activeWishListIdentifier?.orderId &&
      activeWishListIdentifier.orderId !== wishListDetailsRequest?.OrderID
    ) {
      setWishListDetailsRequest({
        OrderID: activeWishListIdentifier.orderId,
        AccessToken: activeWishListIdentifier.accessToken,
        IncludedProperties: AppConfiguration.productProperties,
      });
      refreshWishListDetails();
    }
  }, [
    activeWishListIdentifier,
    wishListDetailsRequest,
    setWishListDetailsRequest,
    refreshWishListDetails,
  ]);

  useEffect(() => {
    if (currentUser && wishListsForUserRequest?.ID !== currentUser.ID) {
      setWishListsForUserRequest({
        ID: currentUser?.ID,
        ShowClosedOrders: false,
        ShowUnconfirmedFundings: false,
        IncludedProperties: [
          'brand_name',
          'display_value',
          'product_id',
          'slug',
          'original_price',
          'display_price',
          'custom_id',
        ],
      });
    }
  }, [setWishListsForUserRequest, currentUser, wishListsForUserRequest?.ID]);

  useEffect(() => {
    if (isMounted && params.accessToken && params.orderId) {
      if (
        !activeWishListIdentifier ||
        (activeWishListIdentifier &&
          activeWishListIdentifier.orderId !== parseInt(params.orderId, 10))
      ) {
        updateActiveList(parseInt(params.orderId, 10), params.accessToken);
      }
    }
  }, [
    activeWishListIdentifier,
    activeWishlist,
    isMounted,
    params,
    updateActiveList,
  ]);

  // if we have no list set, but own a list, we select the first we own
  useEffect(() => {
    // if we have currentUser, no params, and own lists

    // if (isMounted && params.accessToken && params.orderId) {
    //   if (
    //     !activeWishlist ||
    //     activeWishlist.OrderID !== parseInt(params.orderId, 10)
    //   ) {
    //     console.log('SETTING FROM PARAMS', activeWishlist, params);
    //     updateActiveList(parseInt(params.orderId, 10), params.accessToken);
    //     return;
    //   }
    // }

    if (
      !activeWishlist &&
      isMounted // &&
      // !params.accessToken &&
      // !params.orderId
    ) {
      const idFromLocalStorage = localStorage.getItem('activeWishListOrderId');
      const tokenFromLocalStorage = localStorage.getItem(
        'activeWishListAccessToken',
      );
      if (idFromLocalStorage && tokenFromLocalStorage) {
        updateActiveList(
          parseInt(idFromLocalStorage, 10),
          tokenFromLocalStorage,
        );

        return;
      }
    }

    if (
      isMounted &&
      // !params.accessToken &&
      // !params.orderId &&
      !activeWishListIdentifier &&
      currentUser &&
      !activeWishlist &&
      userWishlists &&
      userWishlists?.length
    ) {
      const firstList = first(userWishlists);

      if (firstList) {
        updateActiveList(firstList.OrderID, firstList.AccessToken);
      }
    }
  }, [
    isMounted,
    // params,
    currentUser,
    activeWishlist,
    userWishlists,
    activeWishListIdentifier,
    updateActiveList,
  ]);
};

export default useFetchActiveWishList;
