import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { ShoppingCartDetailsState } from 'state/CartState';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import useGetCurrentUser from 'hooks/useGetCurrentUser';
import useWishlist from 'hooks/useWishlist';
import useClearShoppingCart from 'hooks/useClearShoppingCart';
import { CheckoutOrderState } from '../state/CheckoutOrder';
import { CheckoutAmountsState } from '../state/CheckoutAmount';
import useEvaCheckoutServices from './useEvaCheckoutServices';

const useCheckout = () => {
  const history = useHistory();
  const shoppingCart = useRecoilValue(ShoppingCartDetailsState);
  const checkoutOrder = useRecoilValue(CheckoutOrderState);
  const setCheckoutOrder = useSetRecoilState(CheckoutOrderState);
  const setCheckoutAmounts = useSetRecoilState(CheckoutAmountsState);
  const { currentUser } = useGetCurrentUser();
  const { isParent } = useWishlist();
  const clearCart = useClearShoppingCart();

  const {
    evaAttachCustomer,
    evaGetOrder,
    evaPrepareCheckout,
    evaPlaceOrder,
  } = useEvaCheckoutServices();

  const refreshCheckoutOrder = useCallback(
    async (token?: string) => {
      if (checkoutOrder?.ID) {
        const order = await evaGetOrder(
          {
            OrderID: checkoutOrder.ID,
            IncludePendingPayments: true,
          },
          token,
        );

        setCheckoutOrder(order?.Result);
        setCheckoutAmounts(order?.Amounts);
        return true;
      }
      return false;
    },
    [checkoutOrder, evaGetOrder, setCheckoutOrder, setCheckoutAmounts],
  );

  const startCheckout = useCallback(async () => {
    if (shoppingCart?.ID) {
      const prepareCheckoutResponse = await evaPrepareCheckout({
        OrderID: shoppingCart.ID,
      });

      if (prepareCheckoutResponse && !prepareCheckoutResponse?.Error) {
        // if we are parent, attach customer based on logged in user
        if (isParent && currentUser && shoppingCart.ID) {
          await evaAttachCustomer({
            OrderID: shoppingCart.ID,
            UserID: currentUser.ID,
          });
        }

        const order = await evaGetOrder({
          OrderID: shoppingCart.ID,
          IncludePendingPayments: true,
        });
        setCheckoutOrder(order?.Result);
        setCheckoutAmounts(order?.Amounts);

        if (
          order?.Result.CustomerID &&
          order?.Result?.BillingAddress &&
          order?.Result?.ShippingAddress
        ) {
          history.push('/checkout/overview');
        } else {
          history.push('/checkout/register');
        }
      } else {
        console.log('ERROR', prepareCheckoutResponse);
        clearCart();
        history.push('/cart');
      }
    }
  }, [
    shoppingCart?.ID,
    evaPrepareCheckout,
    isParent,
    currentUser,
    evaGetOrder,
    setCheckoutOrder,
    setCheckoutAmounts,
    history,
    evaAttachCustomer,
    clearCart,
  ]);

  const finishCheckout = useCallback(async () => {
    if (checkoutOrder) {
      await evaPlaceOrder({
        OrderID: checkoutOrder.ID,
      });

      await clearCart();

      setCheckoutOrder(undefined);
      setCheckoutAmounts(undefined);
    }
  }, [
    checkoutOrder,
    clearCart,
    evaPlaceOrder,
    setCheckoutAmounts,
    setCheckoutOrder,
  ]);

  return { startCheckout, refreshCheckoutOrder, finishCheckout };
};

export default useCheckout;
