import { hooks } from '@springtree/eva-sdk-react-recoil';
import { Core } from '@springtree/eva-services-core';
import { useCallback } from 'react';
import useCheckoutRequestOptions from './useCheckoutRequestOptions';

const useEvaCheckoutService = () => {
  const { getOptions } = useCheckoutRequestOptions();

  const attachCustomerService = hooks.useCallService({
    service: Core.AttachCustomerToOrder,
  });

  const getOrder = hooks.useCallService({
    service: Core.GetOrder,
  });

  const prepareCheckout = hooks.useCallService({
    service: Core.PrepareOrderForCheckout,
  });

  const changeOrderLinesToDelivery = hooks.useCallService({
    service: Core.ChangeOrderLinesToDelivery,
  });

  const changeOrderLinesToPickup = hooks.useCallService({
    service: Core.ChangeOrderLinesToPickup,
  });

  const updateOrderShippingAddress = hooks.useCallService({
    service: Core.UpdateOrderShippingAddress,
  });

  const updateOrderBillingAddress = hooks.useCallService({
    service: Core.UpdateOrderBillingAddress,
  });

  const getAddressBook = hooks.useCallService({
    service: Core.ListAddressBook,
  });

  const setPickupOrganizationUnit = hooks.useCallService({
    service: Core.SetPickupOrganizationUnit,
  });

  const createCustomer = hooks.useCallService({
    service: Core.CreateCustomer,
  });

  const updateCustomer = hooks.useCallService({
    service: Core.UpdateUser,
  });

  const getProductAvailability = hooks.useCallService({
    service: Core.GetProductAvailability,
  });

  const getProductAvailabilityIndication = hooks.useCallService({
    service: Core.GetAvailabilityIndication,
  });

  const setRemarkOnOrderService = hooks.useCallService({
    service: Core.SetRemarkOnOrder,
  });

  const updateWishlistFundingService = hooks.useCallService({
    service: Core.UpdateWishListFunding,
  });

  const createAddressBookItem = hooks.useCallService({
    service: Core.CreateAddressBookItem,
  });

  const updateAddressBookItem = hooks.useCallService({
    service: Core.UpdateAddressBookItem,
  });

  const setRequestedDate = hooks.useCallService({
    service: Core.SetRequestedDate,
  });

  const getAvailablePaymentMethods = hooks.useCallService({
    service: Core.GetAvailablePaymentMethods,
  });

  const createPayment = hooks.useCallService({
    service: Core.CreatePayment,
  });

  const placeOrder = hooks.useCallService({
    service: Core.PlaceOrder,
  });

  const evaAttachCustomer = useCallback(
    (request: EVA.Core.AttachCustomerToOrder, token?: string) => {
      const options = getOptions;
      if (token) {
        options.authenticationToken = token;
      }
      return attachCustomerService(request, options);
    },
    [attachCustomerService, getOptions],
  );

  const evaGetOrder = useCallback(
    (request: EVA.Core.GetOrder, token?: string) => {
      const options = getOptions;
      if (token) {
        options.authenticationToken = token;
      }
      return getOrder(request, options);
    },
    [getOptions, getOrder],
  );

  const evaPrepareCheckout = useCallback(
    (request: EVA.Core.PrepareOrderForCheckout) => {
      return prepareCheckout(request, getOptions);
    },
    [getOptions, prepareCheckout],
  );

  const evaChangeOrderLinesToDelivery = useCallback(
    (request: EVA.Core.ChangeOrderLinesToDelivery) => {
      return changeOrderLinesToDelivery(request, getOptions);
    },
    [getOptions, changeOrderLinesToDelivery],
  );

  const evaChangeOrderLinesToPickup = useCallback(
    (request: EVA.Core.ChangeOrderLinesToPickup) => {
      return changeOrderLinesToPickup(request, getOptions);
    },
    [getOptions, changeOrderLinesToPickup],
  );

  const evaUpdateOrderShippingAddress = useCallback(
    (request: EVA.Core.UpdateOrderShippingAddress) => {
      return updateOrderShippingAddress(request, getOptions);
    },
    [getOptions, updateOrderShippingAddress],
  );

  const evaUpdateOrderBillingAddress = useCallback(
    (request: EVA.Core.UpdateOrderBillingAddress) => {
      return updateOrderBillingAddress(request, getOptions);
    },
    [getOptions, updateOrderBillingAddress],
  );

  const evaGetAddressBook = useCallback(
    (request: EVA.Core.ListAddressBook) => {
      return getAddressBook(request, getOptions);
    },
    [getOptions, getAddressBook],
  );

  const evaSetPickupOrganizationUnit = useCallback(
    (request: EVA.Core.SetPickupOrganizationUnit) => {
      return setPickupOrganizationUnit(request, getOptions);
    },
    [getOptions, setPickupOrganizationUnit],
  );

  const evaCreateCustomer = useCallback(
    (request: EVA.Core.CreateCustomer) => {
      return createCustomer(request, getOptions);
    },
    [getOptions, createCustomer],
  );

  const evaUpdateCustomer = useCallback(
    (request: EVA.Core.UpdateUser) => {
      return updateCustomer(request, getOptions);
    },
    [getOptions, updateCustomer],
  );

  const evaGetProductAvailability = useCallback(
    (request: EVA.Core.GetProductAvailability) => {
      return getProductAvailability(request, getOptions);
    },
    [getOptions, getProductAvailability],
  );

  const evaGetProductAvailabilityIndication = useCallback(
    (request: EVA.Core.GetAvailabilityIndication) => {
      return getProductAvailabilityIndication(request, getOptions);
    },
    [getOptions, getProductAvailabilityIndication],
  );

  const evaSetRemarkOnOrderService = useCallback(
    (request: EVA.Core.SetRemarkOnOrder) => {
      return setRemarkOnOrderService(request, getOptions);
    },
    [getOptions, setRemarkOnOrderService],
  );

  const evaUpdateWishlistFundingService = useCallback(
    (request: EVA.Core.UpdateWishListFunding) => {
      return updateWishlistFundingService(request, getOptions);
    },
    [getOptions, updateWishlistFundingService],
  );

  const evaCreateAddressBookItem = useCallback(
    (request: EVA.Core.CreateAddressBookItem) => {
      return createAddressBookItem(request, getOptions);
    },
    [getOptions, createAddressBookItem],
  );

  const evaUpdateAddressBookItem = useCallback(
    (request: EVA.Core.UpdateAddressBookItem) => {
      return updateAddressBookItem(request, getOptions);
    },
    [getOptions, updateAddressBookItem],
  );

  const evaSetRequestedDate = useCallback(
    (request: EVA.Core.SetRequestedDate) => {
      return setRequestedDate(request, getOptions);
    },
    [getOptions, setRequestedDate],
  );

  const evaGetAvailablePaymentMethods = useCallback(
    (request: EVA.Core.GetAvailablePaymentMethods) => {
      return getAvailablePaymentMethods(request, getOptions);
    },
    [getOptions, getAvailablePaymentMethods],
  );

  const evaCreatePayment = useCallback(
    (request: EVA.Core.CreatePayment) => {
      return createPayment(request, getOptions);
    },
    [getOptions, createPayment],
  );

  const evaPlaceOrder = useCallback(
    (request: EVA.Core.PlaceOrder) => {
      return placeOrder(request, getOptions);
    },
    [getOptions, placeOrder],
  );

  return {
    evaAttachCustomer,
    evaGetOrder,
    evaPrepareCheckout,
    evaChangeOrderLinesToDelivery,
    evaChangeOrderLinesToPickup,
    evaUpdateOrderShippingAddress,
    evaUpdateOrderBillingAddress,
    evaGetAddressBook,
    evaSetPickupOrganizationUnit,
    evaCreateCustomer,
    evaUpdateCustomer,
    evaGetProductAvailability,
    evaGetProductAvailabilityIndication,
    evaSetRemarkOnOrderService,
    evaUpdateWishlistFundingService,
    evaCreateAddressBookItem,
    evaUpdateAddressBookItem,
    evaSetRequestedDate,
    evaGetAvailablePaymentMethods,
    evaCreatePayment,
    evaPlaceOrder,
  };
};

export default useEvaCheckoutService;
