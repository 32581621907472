import { state } from '@springtree/eva-sdk-react-recoil';
import Dialog from 'components/shared/ui/Dialog';
import { useCallback, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import Button from '../../shared/ui/Button';

interface IShareWishListDialogProps {
  closeHandler: () => void;
}

export default function ShareWishListDialog(props: IShareWishListDialogProps) {
  const wishListResponse = useRecoilValue(
    state.wishList.wishListDetailsState.response,
  );
  const intl = useIntl();
  const sharedContent = useMemo(
    () =>
      encodeURI(
        intl.formatMessage(
          { id: 'home-page.wish-list.share-dialog.shared-content' },
          { url: document.location.href },
        ),
      ),
    [intl],
  );
  const linkElement = useRef<HTMLInputElement | null>(null);
  const [showLinkCopiedMessage, setShowLinkCopiedMessage] = useState(false);
  const copyLink = useCallback(() => {
    if (linkElement.current) {
      linkElement.current.select();
      linkElement.current.setSelectionRange(0, 9999);
      document.execCommand('copy');
      linkElement.current.setSelectionRange(0, 0);
      setShowLinkCopiedMessage(true);
    }
  }, []);

  return (
    <Dialog
      closeHandler={props.closeHandler}
      show
      titleId="home-page.wish-list.share-dialog.title"
    >
      <div className="px-4 pb-4">
        <div className="flex flex-col sm:flex-row mt-4">
          <input
            readOnly
            className="border border-gray-dark p-2 text-sm focus:outline-none flex-1"
            ref={linkElement}
            value={document.location.href}
          />
          <Button
            variant="text"
            onClick={copyLink}
            className="focus:outline-none mt-4 sm:mt-0"
          >
            <FormattedMessage id="home-page.wish-list.share-dialog.copy-link" />
          </Button>
        </div>
        {showLinkCopiedMessage && (
          <div className="mt-4 font-light text-sm">
            <FormattedMessage id="home-page.wish-list.share-dialog.link-copied" />
          </div>
        )}
        <div className="font-semibold mt-6">
          <FormattedMessage id="home-page.wish-list.share-dialog.share-via" />
        </div>
        <div className="flex flex-col md:grid-cols-3 md:grid gap-6 mt-4">
          <a
            href={`https://wa.me?text=${sharedContent}`}
            className="text-white py-2 px-8 md:mr-4 bg-whatsapp-green text-center font-semibold"
            target="_blank"
            rel="noreferrer"
          >
            <FormattedMessage id="home-page.wish-list.share-dialog.whatsapp" />
          </a>
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(
              window.location.href,
            )}&quote=${intl.formatMessage({
              id: 'home-page.wish-list.share-dialog.facebook-content',
            })}`}
            className="text-white py-2 px-8 md:mx-4 bg-facebook-blue text-center font-semibold"
            target="_blank"
            rel="noreferrer"
          >
            <FormattedMessage id="home-page.wish-list.share-dialog.facebook" />
          </a>
          <a
            href={`https://twitter.com/intent/tweet?text=${sharedContent}`}
            className="text-white py-2 px-8 md:ml-4 bg-twitter-blue text-center font-semibold"
            target="_blank"
            rel="noreferrer"
          >
            <FormattedMessage id="home-page.wish-list.share-dialog.twitter" />
          </a>
          <a
            href={`mailto:?subject=${intl.formatMessage(
              { id: 'home-page.wish-list.share-dialog.email-subject' },
              { wishListName: wishListResponse?.Result.Name },
            )}&body=${window.location.href}`}
            className="text-white py-2 px-8 md:mx-4 bg-primary text-center col-start-2 font-semibold"
            target="_blank"
            rel="noreferrer"
          >
            <FormattedMessage id="home-page.wish-list.share-dialog.email" />
          </a>
        </div>
      </div>
    </Dialog>
  );
}
