import { hooks, state } from '@springtree/eva-sdk-react-recoil';
import { useCallback } from 'react';
import { Core } from '@springtree/eva-services-core';
import useGetCurrentUser from 'hooks/useGetCurrentUser';
import useNotifications from 'hooks/useNotifications';
import { useIntl } from 'react-intl';
import { NotificationType } from 'components/shared/ui/types/NotificationType';
import { EditPersonalInformationValues } from './ProfileTypes';

const useUpdateUser = () => {
  const { currentUser } = useGetCurrentUser();
  const updateUserValues = hooks.useCallService({ service: Core.UpdateUser });
  const refreshUser = hooks.useSetServiceStale({
    serviceState: state.currentUser.currentUserState,
  });
  const { addNotification } = useNotifications();
  const intl = useIntl();

  const updateUser = useCallback(
    async (values: EditPersonalInformationValues) => {
      if (currentUser) {
        await updateUserValues({
          FirstName: values.firstName,
          LastName: values.lastName,
          PhoneNumber: values.phoneNumber,
          ID: currentUser.ID,
        }).then(refreshUser);
        addNotification({
          message: intl.formatMessage({
            id: 'profile-page.success-notification',
          }),
          type: NotificationType.Success,
        });
      }
    },
    [addNotification, currentUser, intl, refreshUser, updateUserValues],
  );

  return { updateUser };
};

export default useUpdateUser;
