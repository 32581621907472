import { clone, cloneDeep, find, get, map } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import useWishlist from 'hooks/useWishlist';
import useAvailabilityForStores from './useAvailabilityForStores';
import { pickupStores } from '../state/PickupStores';
import { CheckoutOrderState } from '../state/CheckoutOrder';
import {
  CheckoutOrderMinDeliveryDate,
  CheckoutOrderMaxDeliveryDate,
} from '../state/CheckoutOrderDeliveryDates';
import { CheckoutRequireStoreSelection } from '../state/CheckoutRequireStoreSelection';
import useEvaCheckoutServices from './useEvaCheckoutServices';
import useCheckout from './useCheckout';
import {
  DeliveryMethod,
  SelectedDeliveryMethodState,
  DeliveryMethodType,
} from '../state/SelectedDeliveryMethod';

export type LineActionType = 'delivery' | 'pickup';

const defaultDeliveryMethods: DeliveryMethod[] = [
  {
    name: 'Thuisbezorgen',
    value: 'delivery',
    isDisabled: false,
    isHidden: false,
  },
  {
    name: 'Afhalen in winkel',
    value: 'pickup',
    isDisabled: false,
    isHidden: false,
  },
  {
    name: 'Lever mijn bestelling bij de ouders',
    value: 'parents',
    isDisabled: false,
    isHidden: false,
  },
];

const useDeliveryMethod = () => {
  const checkoutOrder = useRecoilValue(CheckoutOrderState);
  const stores = useRecoilValue(pickupStores.response);

  const minDeliveryDate = useRecoilValue(CheckoutOrderMinDeliveryDate);
  const setMinDeliveryDate = useSetRecoilState(CheckoutOrderMinDeliveryDate);

  const maxDeliveryDate = useRecoilValue(CheckoutOrderMaxDeliveryDate);
  const setMaxDeliveryDate = useSetRecoilState(CheckoutOrderMaxDeliveryDate);

  const setNeedsStoreSelection = useSetRecoilState(
    CheckoutRequireStoreSelection,
  );

  const { isParent, activeWishlist } = useWishlist();

  const [visibleStores, setVisibleStores] = useState<any>([]);
  const [currentPickupOu, setCurrentPickupOu] = useState<number | undefined>();
  const [methods, setMethods] = useState<DeliveryMethod[] | undefined>();
  const [selectedMethod, setSelectedMethod] = useRecoilState(
    SelectedDeliveryMethodState,
  );

  const {
    getAvailabilityForStores,
    getAvailabilityForProductSet,
  } = useAvailabilityForStores();

  const {
    evaChangeOrderLinesToDelivery,
    evaChangeOrderLinesToPickup,
    evaUpdateOrderShippingAddress,
    evaGetAddressBook,
    evaSetPickupOrganizationUnit,
  } = useEvaCheckoutServices();

  const { refreshCheckoutOrder } = useCheckout();
  const [init, setInit] = useState<boolean>(false);

  const setAllLinesToDelivery = useCallback(async () => {
    if (checkoutOrder) {
      await evaChangeOrderLinesToDelivery({
        OrderID: checkoutOrder.ID,
        OrderLineIDs: map(checkoutOrder.Lines, (line) => line.ID),
      });

      await evaSetPickupOrganizationUnit({
        OrganizationUnitID: checkoutOrder.OriginatingOrganizationUnitID,
        OrderID: checkoutOrder.ID,
      });

      setCurrentPickupOu(undefined);
    }
  }, [
    checkoutOrder,
    evaSetPickupOrganizationUnit,
    evaChangeOrderLinesToDelivery,
  ]);

  const setAllLinesToPickup = useCallback(
    async (ou: number) => {
      if (checkoutOrder) {
        await evaChangeOrderLinesToPickup({
          OrderID: checkoutOrder.ID,
          OrderLineIDs: map(checkoutOrder.Lines, (line) => line.ID),
          PickupOrganizationUnitID: ou,
        });

        await evaSetPickupOrganizationUnit({
          OrganizationUnitID: ou,
          OrderID: checkoutOrder.ID,
        });

        setCurrentPickupOu(ou);

        return true;
      }
      return false;
    },
    [checkoutOrder, evaChangeOrderLinesToPickup, evaSetPickupOrganizationUnit],
  );

  const setPickupStore = useCallback(
    async (ou: number) => {
      if (ou > 0) {
        await setAllLinesToPickup(ou);
        await refreshCheckoutOrder();
      }
    },
    [refreshCheckoutOrder, setAllLinesToPickup],
  );

  const setShippingAddressToParent = useCallback(async () => {
    const parentAddress = get(
      activeWishlist,
      'Customer.Address',
    ) as EVA.Core.AddressDto;
    if (parentAddress && checkoutOrder) {
      await evaUpdateOrderShippingAddress({
        OrderID: checkoutOrder.ID,
        Address: parentAddress,
      });
      // await refreshCheckoutOrder();
    }
  }, [activeWishlist, checkoutOrder, evaUpdateOrderShippingAddress]);

  const setShippingAddressToCustomer = useCallback(async () => {
    const addressBook = await evaGetAddressBook({});
    let defaultShipping = null;

    if (addressBook?.Result) {
      defaultShipping = find(addressBook.Result.Page, {
        DefaultShippingAddress: true,
      });
    }
    if (
      defaultShipping &&
      checkoutOrder &&
      defaultShipping.Address.ID !== checkoutOrder.ShippingAddressID
    ) {
      await evaUpdateOrderShippingAddress({
        OrderID: checkoutOrder.ID,
        AddressBookID: defaultShipping.ID,
      });
    }
  }, [checkoutOrder, evaGetAddressBook, evaUpdateOrderShippingAddress]);

  const setAvailableMethods = useCallback(async () => {
    if (checkoutOrder && stores) {
      let quickestDeliveryDate = new Date();
      let hasWarehouseStock = true;
      let disablePickup = false;
      let disableDelivery = false;

      const useUpOnlyInStore: {
        productId: number;
        availableInstores: number[];
        storeNames: string[];
      }[] = [];

      let visible = clone(stores.Result.Page);

      const useUpProductsWithNoWarehouseStock: any[] = [];
      const availabilityForStores = await getAvailabilityForStores(
        checkoutOrder?.Lines,
        map(stores.Result.Page, (store) => store.ID),
      );

      availabilityForStores?.Products.forEach((avLine) => {
        if (!avLine.Delivery.HasStock && avLine.Delivery.AvailabilityDate) {
          // console.log('Delivery no stock, but has date');
          const avDate = new Date(avLine.Delivery.AvailabilityDate);

          if (avDate.getTime() >= quickestDeliveryDate.getTime()) {
            quickestDeliveryDate = avDate;
          } else {
            // console.log(
            //   'Delivery no stock, av date in the past. Disable delivery',
            //   avLine,
            //   avDate,
            //   quickestDeliveryDate,
            // );
            hasWarehouseStock = false;
          }
          // no stock, no date
        } else if (!avLine.Delivery.HasStock) {
          // console.log('Delivery no stock, no date. Disable delivery');
          hasWarehouseStock = false;
          useUpProductsWithNoWarehouseStock.push(avLine);
        }
      });

      // pickup. For all useup items with no warehouse stock we need to check what stores have it. Only these will be avaialable for selection
      if (useUpProductsWithNoWarehouseStock.length > 0) {
        const indication = await getAvailabilityForProductSet(
          useUpProductsWithNoWarehouseStock,
        );

        if (indication) {
          indication.forEach((storeAV) => {
            const storesWithStock = storeAV.PickupIndications.map((store) => {
              return store.PickupOrganizationUnit.ID;
            });

            const storeNames = storeAV.PickupIndications.map((store) => {
              return store.PickupOrganizationUnit.Name;
            });

            useUpOnlyInStore.push({
              productId: storeAV.ProductID,
              availableInstores: storesWithStock,
              storeNames,
            });

            visible = stores.Result.Page.filter((store: any) => {
              if (storesWithStock.includes(store.ID)) {
                return true;
              }

              return false;
            });

            if (visible.length === 0) {
              disablePickup = true;
            }
          });
        }
      }

      if (!hasWarehouseStock) {
        disableDelivery = true;
      }

      setVisibleStores(visible);

      const deliveryMethods = cloneDeep(defaultDeliveryMethods);

      const parentAddress = get(
        activeWishlist,
        'Customer.Address',
      ) as EVA.Core.AddressDto;

      deliveryMethods.forEach((method) => {
        if (method.value === 'delivery') {
          method.isDisabled = disableDelivery;
        }

        if (method.value === 'pickup') {
          method.isDisabled = disablePickup;
        }

        if (method.value === 'parents') {
          method.isDisabled = disableDelivery;
          method.isHidden = isParent ?? !parentAddress ?? false;
        }
      });

      setMethods(deliveryMethods);
      setMinDeliveryDate(quickestDeliveryDate);

      const maxDate = new Date();
      maxDate.setFullYear(maxDate.getFullYear() + 1);
      setMaxDeliveryDate(maxDate);

      // console.log('Disable Delivery', disableDelivery);
      // console.log('Disable Pickup', disablePickup);
      // console.log('Has warehouse stock', hasWarehouseStock);
      // console.log('Quickest delivery date', quickestDeliveryDate);
      // console.log('Visible stores', visible);
    }
  }, [
    checkoutOrder,
    stores,
    getAvailabilityForStores,
    setMinDeliveryDate,
    setMaxDeliveryDate,
    getAvailabilityForProductSet,
    isParent,
    activeWishlist,
  ]);

  const selectDeliveryMethod = useCallback(
    async (type: DeliveryMethodType) => {
      const newMethod = find(methods, { value: type });

      if (newMethod) {
        // for delivery, set all lines to action type 4
        if (checkoutOrder && newMethod && newMethod.value === 'delivery') {
          await setAllLinesToDelivery();
          await setShippingAddressToCustomer();
          await refreshCheckoutOrder();
          setSelectedMethod(newMethod);
        }

        // for parents, set parents shipping address
        if (checkoutOrder && newMethod && newMethod.value === 'parents') {
          await setAllLinesToDelivery();
          await setShippingAddressToParent();
          await refreshCheckoutOrder();
          setSelectedMethod(newMethod);
        }

        if (checkoutOrder && newMethod && newMethod.value === 'pickup') {
          await setShippingAddressToCustomer();
          await refreshCheckoutOrder();
          setSelectedMethod(newMethod);
        }
      }
    },
    [
      methods,
      checkoutOrder,
      setAllLinesToDelivery,
      setShippingAddressToCustomer,
      refreshCheckoutOrder,
      setSelectedMethod,
      setShippingAddressToParent,
    ],
  );

  // set correct pickup OU whenever order updates
  useEffect(() => {
    if (
      checkoutOrder &&
      checkoutOrder.PickupOrganizationUnitID !==
        checkoutOrder.OriginatingOrganizationUnitID
    ) {
      setCurrentPickupOu(checkoutOrder.PickupOrganizationUnitID);
    } else {
      setCurrentPickupOu(undefined);
    }
  }, [checkoutOrder]);

  // if there is no method selected, base it on order.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (
      !init &&
      checkoutOrder &&
      !selectedMethod &&
      methods &&
      methods.length > 0
    ) {
      setInit(true);
      console.log('init', methods);
      if (
        checkoutOrder.PickupOrganizationUnitID !==
        checkoutOrder.OriginatingOrganizationUnitID
      ) {
        selectDeliveryMethod('pickup');
      } else {
        const deliveryMethod = find(methods, { value: 'delivery' });
        const pickupMethod = find(methods, { value: 'pickup' });

        if (deliveryMethod && !deliveryMethod.isDisabled) {
          selectDeliveryMethod('delivery');
        }

        if (pickupMethod && !pickupMethod.isDisabled) {
          selectDeliveryMethod('pickup');
        }
      }
    }
  });

  // set the avaible delivery methods
  useEffect(() => {
    if (checkoutOrder && !methods) {
      setAvailableMethods();
    }
  }, [checkoutOrder, setAvailableMethods, methods]);

  useEffect(() => {
    if (selectedMethod?.value === 'pickup') {
      if (!currentPickupOu) {
        setNeedsStoreSelection(true);
      } else {
        setNeedsStoreSelection(false);
      }
    } else {
      setNeedsStoreSelection(false);
    }
  }, [currentPickupOu, selectedMethod, setNeedsStoreSelection]);

  return {
    visibleStores,
    minDeliveryDate,
    maxDeliveryDate,
    methods,
    currentPickupOu,
    selectedMethod,
    selectDeliveryMethod,
    setPickupStore,
  };
};

export default useDeliveryMethod;
