import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { EditPersonalInformationValues } from './ProfileTypes';
import useAddressValidationSchema from '../shared/address/useAddressValidationSchema';

const useEditPersonalInformationValidationSchema = () => {
  const intl = useIntl();
  const addressValidationSchema = useAddressValidationSchema();

  const validationSchema: yup.SchemaOf<EditPersonalInformationValues> = yup
    .object()
    .shape({
      firstName: yup
        .string()
        .required(intl.formatMessage({ id: 'generic.error.required' })),
      lastName: yup
        .string()
        .required(intl.formatMessage({ id: 'generic.error.required' })),
      billingAddress: addressValidationSchema,
      shippingAddress: yup.mixed().when('hasShippingAddress', {
        is: true,
        then: addressValidationSchema,
        otherwise: yup.mixed().optional(),
      }),
    })
    .defined();

  return validationSchema;
};

export default useEditPersonalInformationValidationSchema;
