import { state } from '@springtree/eva-sdk-react-recoil';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { SearchPageFiltersState } from './SearchPageState';
import { SearchSortTypeDescriptors } from './SearchPageTypes';

const useGetProducts = () => {
  const setSearchProductsRequest = useSetRecoilState(
    state.product.productListState.request,
  );

  const searchPageFilters = useRecoilValue(SearchPageFiltersState);
  const { query } = useParams<{ query?: string }>();

  useEffect(() => {
    setSearchProductsRequest(() => {
      const fromPrice = searchPageFilters.usePriceRange
        ? searchPageFilters.selectedMinPrice.toString()
        : undefined;

      const toPrice = searchPageFilters.usePriceRange
        ? searchPageFilters?.selectedMaxPrice?.toString()
        : undefined;

      return {
        Query: query,
        Page: searchPageFilters.currentPage,
        PageSize: searchPageFilters.pageSize,
        Sort: [SearchSortTypeDescriptors[searchPageFilters.sortType]],
        Filters: {
          logical_level: { Values: ['root'], IncludeMissing: true },
          product_types: {
            Values: [2048],
            Negation: true,
            ExactMatch: true,
          },
          publication: {
            Values: ['no-images'],
            Negation: true,
          },
        },
        IncludedFields: [
          'display_price',
          'display_value',
          'groepnaam',
          'omzetgroep',
          'brand_name',
          'slug',
          'product_types',
        ],
        AggregationOptions: {
          display_price: {
            ExactMatch: true,
            MaxAggregationTerms: 100,
            StatsAggregation: true,
            TermsAggregation: false,
            Values: [],
            AggregationOptions: {},
            From: fromPrice,
            To: toPrice,
          },
          brand_name: {
            MaxAggregationTerms: 100,
            ExactMatch: true,
            StatsAggregation: false,
            TermsAggregation: true,
            Values: searchPageFilters.brands,
            AggregationOptions: {},
          },
          omzetgroep: {
            MaxAggregationTerms: 100,
            ExactMatch: true,
            StatsAggregation: false,
            TermsAggregation: true,
            Values: searchPageFilters.categories.map((x) => x.name).flat(),
            AggregationOptions: {
              groepnaam: {
                MaxAggregationTerms: 100,
                ExactMatch: true,
                StatsAggregation: false,
                TermsAggregation: true,
                AggregationOptions: {},
                Values: searchPageFilters.categories
                  .map((x) => x.subCategories)
                  .flat(),
              },
            },
          },
        },
      };
    });
  }, [
    query,
    searchPageFilters.brands,
    searchPageFilters.categories,
    searchPageFilters.currentPage,
    searchPageFilters.pageSize,
    searchPageFilters?.selectedMaxPrice,
    searchPageFilters.selectedMinPrice,
    searchPageFilters.sortType,
    searchPageFilters.usePriceRange,
    setSearchProductsRequest,
  ]);

  const searchProductsResponse = useRecoilValue(
    state.product.productListState.response,
  );

  return searchProductsResponse;
};

export default useGetProducts;
