import Dialog from 'components/shared/ui/Dialog';
import Text from 'components/shared/ui/Text';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import useWishList from '../../hooks/useWishlist';
import { ShowParentInformationDialogState } from './wish-list/WishListState';

export default function WishListParentInformationDialog() {
  const [showDialog, setShowDialog] = useRecoilState(
    ShowParentInformationDialogState,
  );

  const closeModal = useCallback(() => {
    setShowDialog(false);
    localStorage.setItem('parentInformationModalShown', 'true');
  }, [setShowDialog]);

  const { activeWishlist } = useWishList();

  return (
    <Dialog
      show={showDialog}
      titleId="home-page.parent-dialog.title"
      confirmButtonTextId="home-page.parent-dialog.button"
      confirmHandler={closeModal}
      closeHandler={closeModal}
      showTopSeparator
    >
      <div className="p-4">
        <Text type="h3">Beste ouder(s),</Text>
        <div className="my-4">
          Hieronder een woordje uitleg over het gebruik van jullie
          geboortelijst:
        </div>
        <Text type="h3" className="mt-2">
          Algemene informate
        </Text>
        <ul className="list-disc p-4 ml-4">
          <li>
            De positie veranderen van een artikel, zodat de spullen die u graag
            eerst wenst bovenaan komen te staan.
          </li>
          <li>
            Een artikel verborgen zetten voor familie. (bv. Het geslacht van het
            kindje is geheim)
          </li>
          <li>
            Een artikel kan u zelf makkelijk toevoegen/verwijderen door de
            bijhorende knop. Kan een artikel niet worden toegevoegd, is dit een
            uitlopend artikel.
          </li>
          <li>
            Bij artikel bekijken zie je de voorraad per winkel en het centraal
            magazijn, de leverdatum, en algemene informatie over het product
            <br />
            <span className="underline">Tip:</span>
            <br />
            Voorraad winkel = keuze afhalen, voorraad magazijn = keuze
            thuisbezorgen.
          </li>
        </ul>
        <Text type="h3" className="mt-2">
          Algemene informate
        </Text>
        <ul className="list-disc p-4">
          <li>
            Deel deze lijst:
            <br />
            hier zijn de verschillende mogelijkheden om je lijst te delen
            <br />-{'>'} whatsapp, twitter, email en Facebook.
          </li>
          <li>
            Familie en vrienden loggen in via de website{' '}
            <a
              href="https://geboortelijst.baby-dump.be/nl/"
              className="text-primary text-sm hover:underline hover:text-blue-dark"
            >
              https://geboortelijst.baby-dump.be/nl/
            </a>{' '}
            met de unieke geboortelijstlink.
            <br />
            <span className="underline">bv.</span> Order ID:{' '}
            {activeWishlist?.OrderID} Wishlist ID: {activeWishlist?.AccessToken}
          </li>
          <li>
            Totaal bedrag schenkingen: Het algemeen besteedde bedrag op de
            geboortelijst.
          </li>
          <li>
            Nog te besteden: Dit zijn de vrije schenkingen, het bedrag kan je
            vrij besteden op een artikel naar keuze. Bij het afrekenen komt het
            bij de betaalmogelijkheden te staan.
          </li>
        </ul>
        <Text type="h3" className="mt-2">
          Belangrijk
        </Text>
        <span>
          Als u zelf een product wenst af te halen in de winkel, dan moet u dit
          product van thuis uit reserveren voor te komen ophalen. Er volgt een
          mail dat het product klaarligt.
          <br />
          <br />
          <u>Afsluitkorting:</u>
          <br />
          Bij een minimum aankoop van € 750,- ontvangt u een afsluitkorting van
          5% op uw geboortelijst.
          <br />
          Bij een minimum aankoop van € 1500,- ontvangt u een afsluitkorting van
          10% op uw geboortelijst.
          <br /> Voor eventuele verdere informatie kan u steeds terecht bij onze
          medewerkers in de dichtstbijzijnde Babydeals of u kan bellen naar de
          service-lijn 015 280120.
          <br />
          <br />
          Met vriendelijke groeten,
          <br />
          Team Babydeals
        </span>
      </div>
    </Dialog>
  );
}
