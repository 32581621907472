import Button from 'components/shared/ui/Button';
import Separator from 'components/shared/ui/Separator';
import { IOption } from 'components/shared/ui/types/IOption';
import { range, sumBy } from 'lodash';
import { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { Form, Formik } from 'formik';
import useWishlist from 'hooks/useWishlist';
import { useHistory } from 'react-router-dom';
import ProductPrice from 'components/shared/product/ProductPrice';
import { ShoppingCartProductQuantityState } from 'state/CartState';
import { SelectedConfiguredProductIdState } from './ProductDetailsState';
import FormikSelect from '../shared/ui/FormikSelect';
import useAddWishListProductToShoppingCart from './useAddWishListProductToShoppingCart';
import useIsProductSellable from './useIsProductSellable';

interface IProductDetailsFamilyActionsProps {
  isIncludedInActiveWishList: boolean;
  isConfigurable: boolean;
  isBundle: boolean;
  productId: number;
  productPrice: number;
}

export default function ProductDetailsFamilyActions(
  props: IProductDetailsFamilyActionsProps,
) {
  const selectedConfiguredProductId = useRecoilValue(
    SelectedConfiguredProductIdState,
  );
  const { activeWishlist } = useWishlist();
  const history = useHistory();

  const productQuantityInCart = useRecoilValue(
    ShoppingCartProductQuantityState(
      props.isConfigurable ? selectedConfiguredProductId : props.productId,
    ),
  );

  const productQuantityOptions: Array<IOption> = useMemo(() => {
    const openWishListLines = activeWishlist?.Lines?.filter(
      (x) => x.Product.ID === props.productId,
    );
    if (openWishListLines) {
      const openQuantity = sumBy(openWishListLines, (x) => x.OpenQuantity);
      return range(1, 1 + openQuantity - productQuantityInCart).map((x) => ({
        value: x,
        label: `+${x}`,
      }));
    }
    return [];
  }, [activeWishlist?.Lines, productQuantityInCart, props.productId]);
  const { canOnlyBeReserved } = useIsProductSellable();

  const addWishListProductToShoppingCart = useAddWishListProductToShoppingCart();

  const addProductToShoppingCart = useCallback(
    (quantity: number) => {
      const productIdToBuy = props.isConfigurable
        ? selectedConfiguredProductId
        : props.productId;
      if (productIdToBuy && props.isIncludedInActiveWishList) {
        addWishListProductToShoppingCart({
          productId: productIdToBuy,
          quantity,
          lineActionType: canOnlyBeReserved ? 1 : 4,
        });
        history.push('/cart');
      }
    },
    [
      addWishListProductToShoppingCart,
      canOnlyBeReserved,
      history,
      props.isConfigurable,
      props.isIncludedInActiveWishList,
      props.productId,
      selectedConfiguredProductId,
    ],
  );

  return props.isIncludedInActiveWishList ? (
    <Formik<{ quantity: number }>
      initialValues={{ quantity: 1 }}
      onSubmit={({ quantity }) => {
        addProductToShoppingCart(quantity);
      }}
    >
      <Form>
        <div className="bg-white border-2 -mx-1 border-primary shadow-lg">
          <div className="p-6 text-red-500 text-2xl font-bold my-auto text-right">
            <ProductPrice
              productId={props.productId}
              isBundle={props.isBundle}
              displayPrice={props.productPrice}
            />
          </div>
          <Separator />
          <div className="bg-gray-light p-4 flex justify-between items-center">
            <div className="flex items-center">
              {productQuantityOptions.length > 1 && (
                <FormikSelect
                  options={productQuantityOptions}
                  name="quantity"
                />
              )}
            </div>
            <div className="flex items-center">
              {productQuantityOptions.length > 0 ? (
                <Button underline type="submit">
                  <FormattedMessage id="product-details-page.order" />
                </Button>
              ) : (
                <Button
                  type="button"
                  underline
                  onClick={() => history.push('/cart')}
                >
                  <FormattedMessage id="product-details-page.to-cart" />
                </Button>
              )}
            </div>
          </div>
        </div>
      </Form>
    </Formik>
  ) : (
    <div className="bg-white border-2 -mx-1 p-5 border-primary shadow-lg">
      <div className="flex justify-between items-center mb-4">
        <span className="font-bold text-xl">
          <FormattedMessage id="product-details-page.product-not-in-list" />
        </span>
      </div>
    </div>
  );
}
