import React from 'react';
import { Field, FieldProps } from 'formik';
import Checkbox from './Checkbox';

interface IFormikCheckboxProps {
  name: string;
  labelId?: string;
  fullWidth?: boolean;
  className?: string;
}

export default function FormikCheckbox(props: IFormikCheckboxProps) {
  return (
    <Field name={props.name}>
      {({ field, form, meta }: FieldProps) => (
        <Checkbox
          value={field.value}
          labelId={props.labelId}
          fullWidth={props.fullWidth}
          error={meta.touched ? meta.error : undefined}
          onChange={(checked) => form.setFieldValue(props.name, checked)}
          className={props.className}
        />
      )}
    </Field>
  );
}
