import useWishList from 'hooks/useWishlist';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { CheckoutOrderState } from '../state/CheckoutOrder';
import {
  CheckoutOrderMinDeliveryDate,
  CheckoutOrderMaxDeliveryDate,
} from '../state/CheckoutOrderDeliveryDates';
import useCheckout from './useCheckout';
import useEvaCheckoutService from './useEvaCheckoutServices';

const useDeliveryDate = () => {
  const { evaSetRequestedDate } = useEvaCheckoutService();
  const checkoutOrder = useRecoilValue(CheckoutOrderState);
  const minDeliveryDate = useRecoilValue(CheckoutOrderMinDeliveryDate);
  const maxDeliveryDate = useRecoilValue(CheckoutOrderMaxDeliveryDate);
  const { refreshCheckoutOrder } = useCheckout();
  const { activeWishlist } = useWishList();

  const [currentDeliveryDate, setCurrentDeliveryDate] = useState<
    Date | undefined
  >();

  const setDeliveryDate = useCallback(
    async (date: Date) => {
      if (checkoutOrder) {
        await evaSetRequestedDate({
          OrderID: checkoutOrder.ID,
          RequestedDate: date.toISOString(),
        });

        await refreshCheckoutOrder();
      }
    },
    [checkoutOrder, evaSetRequestedDate, refreshCheckoutOrder],
  );

  useEffect(() => {
    if (minDeliveryDate && maxDeliveryDate) {
      const firstLineWithQuantity = checkoutOrder?.Lines?.find(
        (x) => x.QuantityOpen > 0,
      );
      if (firstLineWithQuantity?.RequestedDate) {
        const current = new Date(firstLineWithQuantity.RequestedDate);
        setCurrentDeliveryDate(current);
      } else if (activeWishlist?.Data?.requestedDeliveryDate) {
        const dateFromList = new Date(
          activeWishlist?.Data.requestedDeliveryDate,
        );

        if (
          dateFromList.getTime() > minDeliveryDate.getTime() &&
          dateFromList.getTime() < maxDeliveryDate.getTime()
        ) {
          setCurrentDeliveryDate(dateFromList);
        } else {
          setCurrentDeliveryDate(minDeliveryDate);
        }
      } else {
        setCurrentDeliveryDate(minDeliveryDate);
      }
    }
  }, [activeWishlist, checkoutOrder, maxDeliveryDate, minDeliveryDate]);

  return {
    currentDeliveryDate,
    minDeliveryDate,
    maxDeliveryDate,
    setDeliveryDate,
  };
};

export default useDeliveryDate;
