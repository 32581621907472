import Price from 'components/shared/ui/Price';
import useGetWishListAccumulatedDiscount from 'hooks/useGetWishListAccumulatedDiscount';
import useGetWishListCard from 'hooks/useGetWishListUserCard';
import useWishlist from 'hooks/useWishlist';
import { sum } from 'lodash';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

export default function WishListCoverFundings() {
  const { activeWishlist } = useWishlist();

  const { wishListCardBalance } = useGetWishListCard();
  const totalWishListFundings = useMemo(
    () =>
      sum(activeWishlist?.Fundings?.map((x) => x.Amount)) +
      sum(activeWishlist?.Lines?.map((x) => x.FundedAmountAsPayment)),
    [activeWishlist?.Fundings, activeWishlist?.Lines],
  );
  const wishListAccumulatedDiscount = useGetWishListAccumulatedDiscount(
    activeWishlist?.OrderID,
  );

  return (
    <>
      {totalWishListFundings > 0 && (
        <>
          <div className="bg-gradient-to-r from-white h-px mt-auto mb-4 hidden lg:block" />
          <div className="grid sm:grid-cols-3 sm:justify-center">
            <span className="text-white lg:text-xl flex flex-col">
              <FormattedMessage id="home-page.wish-list.cover.total-fundings" />
              <span className="font-bold">
                <Price value={totalWishListFundings} />
              </span>
            </span>
            <span className="text-white lg:text-xl flex flex-col">
              <FormattedMessage id="home-page.wish-list.cover.spendable-amount" />
              <span className="font-bold">
                <Price value={wishListCardBalance} />
              </span>
            </span>
            {(wishListAccumulatedDiscount ?? 0) > 0 && (
              <span className="text-white lg:text-xl flex flex-col">
                <FormattedMessage id="home-page.wish-list.cover.accumulated-discount" />
                <span className="font-bold">
                  <Price value={wishListAccumulatedDiscount} />
                </span>
              </span>
            )}
          </div>
        </>
      )}
    </>
  );
}
