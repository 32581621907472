import { useCallService } from 'eva/EvaHooks';
import { useState, useEffect } from 'react';
import { FTH } from 'eva/eva-services-fth';
import useGetCurrentUser from 'hooks/useGetCurrentUser';

const useGetWishListAccumulatedDiscount = (orderId?: number) => {
  const [
    wishListAccumulatedDiscount,
    setWishListAccumulatedDiscount,
  ] = useState<number>();
  const { currentUser } = useGetCurrentUser();

  const getWishListDiscount = useCallService({
    service: FTH.FTHGetWishListDiscount,
  });

  useEffect(() => {
    if (orderId && currentUser) {
      getWishListDiscount({ OrderID: orderId }).then((response) => {
        setWishListAccumulatedDiscount(response?.Amount);
      });
    }
  }, [currentUser, getWishListDiscount, orderId]);

  return wishListAccumulatedDiscount;
};

export default useGetWishListAccumulatedDiscount;
