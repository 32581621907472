import { hooks, state } from '@springtree/eva-sdk-react-recoil';
import { Core } from '@springtree/eva-services-core';
import { useSetServiceStale } from 'eva/EvaHooks';
import { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import {
  ActiveWishListIdentifierState,
  SelectedUserWishListState,
  UserWishListsState,
} from 'state/WishlistState';
import { ActiveWishListState } from '../state/WishlistState';
import useGetCurrentUser from './useGetCurrentUser';

const useWishList = () => {
  // atom wich defines the current active list
  const activeWishListIdentifier = useRecoilValue(
    ActiveWishListIdentifierState,
  );

  const activeWishlist = useRecoilValue(ActiveWishListState);

  const userWishlists = useRecoilValue(UserWishListsState);

  const { currentUser, isEmployee } = useGetCurrentUser();

  const isParent = useMemo(
    () => currentUser && currentUser.ID === activeWishlist?.Customer?.ID,
    [activeWishlist?.Customer?.ID, currentUser],
  );

  const selectedUserWishList = useRecoilValue(SelectedUserWishListState);

  const refreshWishList = useSetServiceStale({
    serviceState: state.wishList.wishListDetailsState,
  });

  // create wishlist call trigger
  const createWishList = hooks.useCallService({ service: Core.CreateWishList });

  // add product to list trigger
  const addProductToWishList = hooks.useCallService({
    service: Core.AddProductToWishList,
  });

  // callback to create new list
  const createNewWishlist = useCallback(
    (request: EVA.Core.CreateWishList, token?: string) => {
      return createWishList(
        request,
        token
          ? {
              authenticationToken: token,
            }
          : undefined,
      );
    },
    [createWishList],
  );

  // callback to add item to current active list, as long as you own it
  const addProductToActiveWishList = useCallback(
    (productId: number, quantity = 1) => {
      if (activeWishlist) {
        return addProductToWishList({
          ProductID: productId,
          OrderID: activeWishlist.OrderID,
          Quantity: quantity,
          SingleProductPerLine: true,
        });
      }
      return Promise.resolve(undefined);
    },
    [activeWishlist, addProductToWishList],
  );

  return {
    activeWishListIdentifier,
    activeWishlist,
    userWishlists,
    isParent,
    isParentOrEmployee: isParent || isEmployee,
    createNewWishlist,
    addProductToActiveWishList,
    selectedUserWishList,
    refreshWishList,
  };
};

export default useWishList;
