import { useCallback, useEffect, useMemo, useState } from 'react';
import Button from 'components/shared/ui/Button';
import FormikInput from 'components/shared/ui/FormikInput';
import Text from 'components/shared/ui/Text';
import { Form, Formik, setNestedObjectValues } from 'formik';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { find, isEqual } from 'lodash';
import {
  AddressValues,
  InitialAddressValues,
} from 'components/shared/address/AddressValues';
import useGetCurrentUser from 'hooks/useGetCurrentUser';
import { useSetRecoilState } from 'recoil';
import Address from '../../shared/address/Address';
import {
  InitialCheckoutProfileValues,
  CheckoutProfileValues,
} from './CheckoutProfileTypes';
import useUpdateOrderAddress from '../hooks/useUpdateOrderAddress';
import useUpdateUser from '../hooks/useUpdateUser';
import useCheckout from '../hooks/useCheckout';
import { CheckoutToken } from '../state/CheckoutToken';
import useEvaCheckoutService from '../hooks/useEvaCheckoutServices';
import useCheckoutProfileValidationSchema from './useCheckoutProfileValidationSchema';

interface ICheckoutProfileProps {
  order: EVA.Core.OrderDto | undefined;
  type: 'parent' | 'family';
}

export default function CheckoutProfile(props: ICheckoutProfileProps) {
  const history = useHistory();
  const { updateOrderAddressesByAddressBookID } = useUpdateOrderAddress();
  const { updateUserInfo, createFamilyUser } = useUpdateUser();
  const { evaAttachCustomer } = useEvaCheckoutService();
  const { refreshCheckoutOrder } = useCheckout();
  const setCheckoutUserToken = useSetRecoilState(CheckoutToken);

  const checkoutProfileValidationSchema = useCheckoutProfileValidationSchema();

  const { currentUser } = useGetCurrentUser();

  const [customerValues, setCustomerValues] = useState<CheckoutProfileValues>(
    InitialCheckoutProfileValues,
  );

  const mapAddress = useCallback((address: EVA.Core.AddressDto) => {
    const mappedAddress: AddressValues =
      address !== undefined
        ? {
            zipcode: address.ZipCode ?? '',
            houseNumber: address.HouseNumber?.split(' ')[0] ?? '',
            addressAddition: address.HouseNumber?.split(' ')[1] ?? '',
            street: address.Street ?? '',
            city: address.City ?? '',
            countryId: address.CountryID,
          }
        : InitialAddressValues;

    return mappedAddress;
  }, []);

  const attachAndRefresh = useCallback(
    async (orderId: number, customerId: number, token?: string) => {
      await evaAttachCustomer({ OrderID: orderId, UserID: customerId }, token);

      console.log('attact & refresh');
      await refreshCheckoutOrder(token);
    },
    [evaAttachCustomer, refreshCheckoutOrder],
  );

  const updateCustomer = useCallback(
    async (userId: number, values: CheckoutProfileValues) => {
      const newAddressBook = await updateUserInfo(userId, values);

      const billing = find(newAddressBook?.Result.Page, {
        DefaultBillingAddress: true,
      });
      const shipping = find(newAddressBook?.Result.Page, {
        DefaultShippingAddress: true,
      });

      if (props.order?.ID && billing?.ID && shipping?.ID) {
        await updateOrderAddressesByAddressBookID(
          props.order.ID,
          billing.ID,
          shipping.ID,
        );
        console.log('update customer');
        await refreshCheckoutOrder();
      }
    },
    [
      props?.order?.ID,
      refreshCheckoutOrder,
      updateOrderAddressesByAddressBookID,
      updateUserInfo,
    ],
  );

  const updateOrCreateUser = useCallback(
    async (values: CheckoutProfileValues) => {
      if (props.order?.ID) {
        if (currentUser?.ID && props.type === 'parent') {
          if (props.order?.CustomerID) {
            await updateCustomer(currentUser.ID, values);
          } else {
            await updateCustomer(currentUser.ID, values);
            await attachAndRefresh(props.order.ID, currentUser.ID);
          }
          history.push('/checkout/overview');
        }

        if (props.type === 'family') {
          if (props.order?.CustomerID) {
            await updateCustomer(props.order?.CustomerID, values);
            history.push('/checkout/overview');
          } else {
            const newUser = await createFamilyUser(values);
            if (newUser) {
              console.log('NEW TOKEN', newUser.AuthenticationToken);
              setCheckoutUserToken(newUser.AuthenticationToken);
              await attachAndRefresh(
                props.order.ID,
                newUser.ID,
                newUser.AuthenticationToken,
              );
              history.push('/checkout/overview');
            }
          }
        }
      }
    },
    [
      props.order?.ID,
      props.order?.CustomerID,
      props.type,
      currentUser?.ID,
      history,
      updateCustomer,
      attachAndRefresh,
      createFamilyUser,
      setCheckoutUserToken,
    ],
  );

  useEffect(() => {
    if (props.order && props.order.Customer) {
      const hasShippingAddress =
        !!props.order.BillingAddress &&
        !!props.order.ShippingAddress &&
        !isEqual(props.order.BillingAddress, props.order.ShippingAddress);

      setCustomerValues({
        firstName: props.order.Customer.FirstName,
        lastName: props.order.Customer.LastName,
        phoneNumber: props.order.Customer.PhoneNumber,
        email: props.order.Customer.EmailAddress,
        billingAddress: mapAddress(props.order.BillingAddress),
        billingAddressID: props.order.BillingAddressID,
        hasShippingAddress,
        shippingAddress: hasShippingAddress
          ? mapAddress(props.order.ShippingAddress)
          : undefined,
        shippingAddressID: props.order.ShippingAddressID,
      });
    }
  }, [mapAddress, props, setCustomerValues]);

  const shouldValidateInitially = useMemo(
    () =>
      props.order?.Customer !== undefined &&
      (props.order?.BillingAddress === undefined ||
        props.order.ShippingAddress === undefined),
    [
      props.order?.BillingAddress,
      props.order?.Customer,
      props.order?.ShippingAddress,
    ],
  );

  return (
    <Formik<CheckoutProfileValues>
      initialValues={customerValues}
      enableReinitialize
      onSubmit={updateOrCreateUser}
      validationSchema={checkoutProfileValidationSchema}
      validateOnMount={shouldValidateInitially}
      initialTouched={
        shouldValidateInitially
          ? setNestedObjectValues(customerValues, true)
          : undefined
      }
    >
      <Form>
        <Text type="h1" className="mb-2">
          <FormattedMessage id="checkout-page.profile.title" />
        </Text>
        <Text>
          <FormattedMessage id="checkout-page.profile.description" />
        </Text>
        <div className="bg-white p-4 md:p-8 my-4">
          <Text type="h3" className="mb-2 uppercase">
            <FormattedMessage id="register-page.personal-information" />
          </Text>
          <div className="grid grid-cols-1 md:grid-cols-2">
            <div className="md:mr-2">
              <FormikInput
                name="firstName"
                labelId="generic.first-name"
                type="text"
              />
              <FormikInput
                name="lastName"
                labelId="generic.last-name"
                type="text"
              />
            </div>
            <div className="md:ml-2">
              <FormikInput
                name="phoneNumber"
                labelId="generic.telephone"
                type="text"
              />
              <FormikInput
                name="email"
                labelId="generic.email-address"
                type="text"
                readonly={props.type === 'parent'}
              />
            </div>
          </div>
          <Address />
          <div className="flex justify-between">
            <Button
              variant="text"
              onClick={() => history.push('/checkout/overview')}
            >
              <FormattedMessage id="generic.back" />
            </Button>
            <Button type="submit" underline>
              {props?.order?.Customer ? (
                <FormattedMessage id="checkout-page.profile.create" />
              ) : (
                <FormattedMessage id="checkout-page.profile.edit" />
              )}
            </Button>
          </div>
        </div>
      </Form>
    </Formik>
  );
}
