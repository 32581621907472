import { FormikValues } from 'formik';

export enum WishListSortType {
  Position = 'Position',
  PriceAscending = 'PriceAscending',
  PriceDescending = 'PriceDescending',
}

export const WishListSortTypeTranslations: Record<WishListSortType, string> = {
  [WishListSortType.Position]: 'home-page.wish-list.header.sort-type.position',
  [WishListSortType.PriceAscending]:
    'home-page.wish-list.header.sort-type.price-ascending',
  [WishListSortType.PriceDescending]:
    'home-page.wish-list.header.sort-type.price-descending',
};

export interface WishListFilterValues extends FormikValues {
  showNotOrdered: boolean;
  showUniqueProducts: boolean;
  sortType: WishListSortType;
}

export interface WishListCover {
  wishListName: string;
  imageId?: number;
  orderId?: number;
  accessToken?: string;
}
