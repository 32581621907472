/** @jsxImportSource @emotion/react */
import { useCallback, useEffect, useMemo, useState, Fragment } from 'react';
import { useTable, Column, Row } from 'react-table';
import tw, { css } from 'twin.macro';
import Icon from './Icon';
import Pagination, { IPaginationProps } from './Pagination';

interface ITableProps<T extends object> {
  columns: Array<Column<T>>;
  data: Array<T>;
  handleSelectRow?: (row: T) => void;
  renderExpandedRowComponent?: (row: T) => React.ReactNode;
  paginationOptions?: IPaginationProps;
  maxHeight?: number;
}

export default function Table<T extends object>(props: ITableProps<T>) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
  } = useTable({ columns: props.columns, data: props.data });

  const [selectedRowId, setSelectedRowId] = useState<string>();

  const handleSelectRow = useMemo(() => props.handleSelectRow, [
    props.handleSelectRow,
  ]);
  const selectRow = useCallback(
    (row: Row<T>) => {
      handleSelectRow?.(row.original);
      setSelectedRowId(row.id);
    },
    [handleSelectRow],
  );

  useEffect(() => {
    setSelectedRowId(undefined);
  }, [props.data]);

  const [expandedRowIds, setExpandedRowIds] = useState<Array<string>>([]);

  const toggleIsRowExpanded = (rowId: string) =>
    setExpandedRowIds((current) =>
      current.includes(rowId)
        ? current.filter((x) => x !== rowId)
        : [...current, rowId],
    );

  const scrollStyle = useMemo(
    () =>
      props.maxHeight &&
      css`
        max-height: ${props.maxHeight}px;
      `,
    [props.maxHeight],
  );

  return (
    <div className="max-w-full overflow-x-auto">
      <div
        css={
          props.paginationOptions === undefined
            ? [scrollStyle, tw`overflow-y-auto`]
            : []
        }
      >
        <table {...getTableProps()} className="w-full table-auto">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="bg-gray-light"
              >
                {props.renderExpandedRowComponent && <td className="w-6" />}
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    className=" p-2 text-gray-dark text-left"
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                // @ts-ignore
                <Fragment key={row.id}>
                  <tr
                    {...row.getRowProps()}
                    onClick={() => {
                      if (props.handleSelectRow) {
                        selectRow(row);
                      }
                      if (props.renderExpandedRowComponent) {
                        toggleIsRowExpanded(row.id);
                      }
                    }}
                    css={[
                      tw`transition`,
                      (props.handleSelectRow ||
                        props.renderExpandedRowComponent) &&
                        tw`cursor-pointer`,
                      selectedRowId === row.id &&
                        props.handleSelectRow &&
                        tw`bg-blue bg-opacity-25`,
                    ]}
                  >
                    {props.renderExpandedRowComponent && (
                      <td className="text-center text-2xl">
                        <div
                          className={
                            expandedRowIds.includes(row.id)
                              ? 'transition-transform transform w-6 rotate-90'
                              : 'transition-transform transform w-6'
                          }
                        >
                          <Icon name="arrow-right" />
                        </div>
                      </td>
                    )}
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className="p-2 align-middle"
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                  {expandedRowIds.includes(row.id) &&
                    props.renderExpandedRowComponent && (
                      <tr>
                        <td colSpan={visibleColumns.length + 1}>
                          {props.renderExpandedRowComponent(row.original)}
                        </td>
                      </tr>
                    )}
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
      {props.paginationOptions && (
        <div className="mt-2 p-2">
          <Pagination {...props.paginationOptions} />
        </div>
      )}
    </div>
  );
}
