/** @jsxImportSource @emotion/react */
import SuspenseFallback from 'eva/SuspenseFallback';
import ProductDetails from './ProductDetails';

export default function ProductDetailsPage() {
  return (
    <div className="container mx-auto p-6 lg:p-10">
      <SuspenseFallback>
        <ProductDetails />
      </SuspenseFallback>
    </div>
  );
}
