import { hooks } from '@springtree/eva-sdk-react-recoil';
import { Core } from '@springtree/eva-services-core';
import useIsMounted from 'hooks/useIsMounted';
import { isEqual } from 'lodash';
import { useEffect, useState, useCallback } from 'react';
import { AddressValues } from '../shared/address/AddressValues';

const useAddressBook = () => {
  const isMounted = useIsMounted();
  const [billingAddress, setBillingAddress] = useState<AddressValues>();
  const [shippingAddress, setShippingAddress] = useState<AddressValues>();
  const [billingAddressId, setBillingAddressId] = useState<number>();
  const [shippingAddressId, setShippingAddressId] = useState<number>();
  const getAddressBook = hooks.useCallService({
    service: Core.ListAddressBook,
  });
  const updateAddressBookItemValues = hooks.useCallService({
    service: Core.UpdateAddressBookItem,
  });
  const [triggerRefresh, setTriggerRefresh] = useState(false);

  useEffect(() => {
    getAddressBook().then((response) => {
      if (response?.Result && isMounted) {
        const shippingAddressDto = response.Result.Page.find(
          (x) => x.DefaultShippingAddress,
        );
        if (shippingAddressDto) {
          setShippingAddress(
            mapAddressBookDtoToAddressValues(shippingAddressDto),
          );
          setShippingAddressId(shippingAddressDto?.ID);
        }
        const billingAddressDto = response.Result.Page.find(
          (x) => x.DefaultBillingAddress,
        );
        if (billingAddressDto) {
          setBillingAddress(
            mapAddressBookDtoToAddressValues(billingAddressDto),
          );
          setBillingAddressId(billingAddressDto?.ID);
        }
      }
    });
  }, [getAddressBook, isMounted, triggerRefresh]);

  const updateBillingAddress = useCallback(
    (values: AddressValues) => {
      if (billingAddressId) {
        updateAddressBookItemValues({
          ID: billingAddressId,
          Address: mapAddressValuesToAddressDataDto(values),
        }).then(() => setTriggerRefresh((prev) => !prev));
      }
    },
    [billingAddressId, updateAddressBookItemValues],
  );

  const updateShippingAddress = useCallback(
    (values: AddressValues) => {
      if (shippingAddressId) {
        updateAddressBookItemValues({
          ID: shippingAddressId,
          Address: mapAddressValuesToAddressDataDto(values),
        }).then(() => setTriggerRefresh((prev) => !prev));
      }
    },
    [shippingAddressId, updateAddressBookItemValues],
  );

  return {
    billingAddress,
    shippingAddress,
    shippingAddressId,
    updateBillingAddress,
    updateShippingAddress,
    hasShippingAddress: !isEqual(billingAddress, shippingAddress),
  };
};

export default useAddressBook;

const mapAddressBookDtoToAddressValues = (
  addressBookDto: EVA.Core.AddressBookDto,
): AddressValues => ({
  city: addressBookDto.Address.City ?? '',
  countryId: addressBookDto.Address.CountryID,
  street: addressBookDto.Address.Street ?? '',
  zipcode: addressBookDto.Address.ZipCode ?? '',
  houseNumber: addressBookDto.Address.HouseNumber?.split(' ')[0] ?? '',
  addressAddition: addressBookDto.Address.HouseNumber?.split(' ')[1] ?? '',
});

const mapAddressValuesToAddressDataDto = (
  values: AddressValues,
): EVA.Core.AddressDataDto => ({
  City: values.city,
  CountryID: values.countryId,
  ZipCode: values.zipcode,
  HouseNumber: `${values.houseNumber} ${values.addressAddition ?? ''}`,
  Street: values.street,
});
