import Table from 'components/shared/ui/Table';
import Text from 'components/shared/ui/Text';
import { useState, useMemo, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Column } from 'react-table';
import { useRecoilState } from 'recoil';
import { IPaginationProps } from 'components/shared/ui/Pagination';
import CustomerWishListsTable from './CustomerWishListsTable';
import { ICustomer } from './EmployeeTypes';
import { CustomersTableFiltersState } from './EmployeeState';

interface ICustomersTableProps {
  customers?: Array<ICustomer>;
  totalCustomers?: number;
}

export default function CustomersTable(props: ICustomersTableProps) {
  const [selectedCustomer, setSelectedCustomer] = useState<ICustomer>();

  useEffect(() => {
    setSelectedCustomer(undefined);
  }, [props.customers]);

  const columns: Array<Column<ICustomer>> = useMemo(
    () => [
      {
        accessor: 'FirstName',
        Header: <FormattedMessage id="generic.first-name" />,
      },
      {
        accessor: 'LastName',
        Header: <FormattedMessage id="generic.last-name" />,
      },
      {
        accessor: 'EmailAddress',
        Header: <FormattedMessage id="generic.email-address" />,
      },
      {
        accessor: 'PhoneNumber',
        Header: <FormattedMessage id="generic.telephone" />,
      },
    ],
    [],
  );

  const [customersTableFilters, setCustomersTableFilters] = useRecoilState(
    CustomersTableFiltersState,
  );

  const paginationOptions: IPaginationProps = useMemo(
    () => ({
      currentPage: customersTableFilters.currentPage,
      pageSize: customersTableFilters.pageSize,
      handlePageChange: (currentPage) =>
        setCustomersTableFilters((current) => ({ ...current, currentPage })),
      numberOfPages: Math.ceil(
        (props.totalCustomers ?? 0) / customersTableFilters.pageSize,
      ),
      totalItems: props.totalCustomers,
    }),
    [
      customersTableFilters.currentPage,
      customersTableFilters.pageSize,
      props.totalCustomers,
      setCustomersTableFilters,
    ],
  );

  return (
    <>
      {props.customers !== undefined && (
        <div className="my-10 mx-auto bg-white p-6">
          {props.customers.length > 0 ? (
            <>
              <Text type="h2" className="mb-4 font-bold">
                <FormattedMessage id="employee-page.customers-table.title" />
              </Text>
              <Table
                data={props.customers}
                columns={columns}
                handleSelectRow={(customer) => setSelectedCustomer(customer)}
                paginationOptions={paginationOptions}
              />
            </>
          ) : (
            <Text type="h2" className="font-bold">
              <FormattedMessage id="employee-page.no-customers-found" />
            </Text>
          )}
        </div>
      )}
      {selectedCustomer && (
        <CustomerWishListsTable customer={selectedCustomer} />
      )}
    </>
  );
}
