import { atom } from 'recoil';

export const ActiveWishListIdentifierState = atom<
  { orderId: number; accessToken: string } | undefined
>({
  key: 'ActiveWishListIdentifier',
  default: undefined,
});

export const ActiveWishListState = atom<EVA.Core.WishList | undefined>({
  key: 'ActiveWishList',
  default: undefined,
});

export const UserWishListsState = atom<Array<EVA.Core.WishList> | undefined>({
  key: 'UserWishLists',
  default: undefined,
});

export const WishListPageUrlParamsState = atom<{
  orderId?: string;
  accessToken?: string;
}>({
  key: 'WishListPageUrlParams',
  default: {},
});

export const SelectedUserWishListState = atom<EVA.Core.WishList | undefined>({
  key: 'SelectedUserWishList',
  default: undefined,
});

export const WishListNotFoundForUrlState = atom<boolean | undefined>({
  key: 'WishListNotFoundForUrl',
  default: undefined,
});
