import {
  AddressValues,
  InitialAddressValues,
} from 'components/shared/address/AddressValues';
import { FormikValues } from 'formik';

export interface CheckoutProfileValues extends FormikValues {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  billingAddress?: AddressValues;
  billingAddressID?: number;
  hasShippingAddress: boolean;
  shippingAddress?: AddressValues;
  shippingAddressID?: number;
}

export const InitialCheckoutProfileValues: CheckoutProfileValues = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  billingAddress: InitialAddressValues,
  shippingAddress: InitialAddressValues,
  hasShippingAddress: false,
};
