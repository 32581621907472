import { hooks } from '@springtree/eva-sdk-react-recoil';
import { useCallback, useState } from 'react';
import { Core } from '@springtree/eva-services-core';
import useIsMounted from './useIsMounted';

const useProductAvailability = () => {
  const isMounted = useIsMounted();
  const [
    productAvailability,
    setProductAvailability,
  ] = useState<EVA.Core.GetProductAvailabilityResponse>();
  const getProductDetailAvailability = hooks.useCallService({
    service: Core.GetProductAvailability,
  });

  const getProductAvailability = useCallback(
    (products: Array<{ ID: number; QuantityRequested: number }>) => {
      if (products.length) {
        getProductDetailAvailability({
          Products: products,
          Options: {
            FutureAvailability: true,
            QuantityAvailable: true,
            Delivery: { AvailabilityDate: true },
            Pickup: {
              AvailabilityDate: true,
            },
          },
        }).then((response) => {
          if (isMounted) {
            setProductAvailability(response);
          }
        });
      }
    },
    [getProductDetailAvailability, isMounted],
  );

  return {
    getProductAvailability,
    productAvailability,
  };
};

export default useProductAvailability;
