import { PaymentCore } from '@springtree/eva-services-payment-core';
import { useCallService } from 'eva/EvaHooks';
import { useState, useEffect } from 'react';
import useGetCurrentUser from 'hooks/useGetCurrentUser';

const useGetWishListCard = () => {
  const { currentUser } = useGetCurrentUser();
  const [wishListCard, setWishListCard] = useState<EVA.Core.UserCardDto>();

  const getUserCards = useCallService({
    service: PaymentCore.GetUserCardsForUser,
  });

  useEffect(() => {
    if (currentUser) {
      getUserCards().then((response) => {
        setWishListCard(
          response?.UserCards?.find((x) => x.Type.Name === 'WishList'),
        );
      });
    }
  }, [currentUser, getUserCards]);

  return {
    wishListCard,
    wishListCardBalance: wishListCard?.CurrentBalance?.CurrencyBalance,
  };
};

export default useGetWishListCard;
