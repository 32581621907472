import { Switch, Route, Redirect } from 'react-router-dom';
import SuspenseFallback from 'eva/SuspenseFallback';
import LoginPage from 'components/login/LoginPage';
import CartPage from 'components/cart/CartPage';
import WishListPage from 'components/home/WishListPage';
import ForgotPasswordPage from 'components/forgot-password/ForgotPasswordPage';
import ResetPasswordPage from 'components/reset-password/ResetPasswordPage';
import RegisterPage from 'components/register/RegisterPage';
import ProfilePage from 'components/profile/ProfilePage';
import SearchPage from 'components/search/SearchPage';
import EmployeePage from 'components/employee/EmployeePage';
import OrdersOverviewPage from 'components/orders-overview/OrdersOverviewPage';
import CheckoutOverviewPage from 'components/checkout/CheckoutOverviewPage';
import CheckoutComplete from 'components/checkout/CheckoutComplete';
import useGetCurrentUser from 'hooks/useGetCurrentUser';
import ProductDetailsPage from './components/product-details/ProductDetailsPage';
import NewWishListPage from './components/new-wishlist/NewWishListPage';
import DonatePage from './components/donate/DonatePage';
import DonateSuccessPage from './components/donate-success/DonateSuccessPage';
import CheckoutRegisterPage from './components/checkout/CheckoutRegisterPage';
import CheckoutReturn from './components/checkout/CheckoutReturn';

export default function Routes() {
  const { isLoggedIn, isEmployee } = useGetCurrentUser();

  return (
    <Switch>
      <Route path="/cart">
        <CartPage />
      </Route>
      <Route path="/forgotpassword">
        <ForgotPasswordPage />
      </Route>
      <Route path="/resetpassword/:token">
        <ResetPasswordPage />
      </Route>
      <Route path="/register">
        <RegisterPage />
      </Route>
      <Route path="/profile">
        {isLoggedIn ? <ProfilePage /> : <Redirect to="/" />}
      </Route>
      <Route path="/employee">
        {isEmployee ? <EmployeePage /> : <Redirect to="/" />}
      </Route>
      <Route path="/search/:query?">
        <SearchPage />
      </Route>
      <Route path="/product/:productId/:productSlug?">
        <ProductDetailsPage />
      </Route>
      <Route path="/geboortelijst/nieuw">
        <NewWishListPage />
      </Route>
      <Route path="/geboortelijst/:orderId/:accessToken?">
        <WishListPage />
      </Route>
      <Route path="/checkout/register">
        <CheckoutRegisterPage />
      </Route>
      <Route path="/checkout/overview">
        <CheckoutOverviewPage />
      </Route>
      <Route path="/checkout/complete/:isUnpaidPickup?">
        <CheckoutComplete />
      </Route>
      <Route path="/checkout/return/:orderId?/:userToken?">
        <CheckoutReturn />
      </Route>
      <Route path="/orders-overview">
        <OrdersOverviewPage />
      </Route>
      <Route path="/donate/success/:donationValue">
        <DonateSuccessPage />
      </Route>
      <Route path="/donate">
        <SuspenseFallback>
          <DonatePage />
        </SuspenseFallback>
      </Route>
      <Route path="/">
        <LoginPage />
      </Route>
    </Switch>
  );
}
