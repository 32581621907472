import { useRecoilValue, useSetRecoilState } from 'recoil';
import { state, helpers } from '@springtree/eva-sdk-react-recoil';
import { useEffect, useMemo, useState } from 'react';
import useProductImage from 'components/shared/product/useProductImage';
import useWishlist from 'hooks/useWishlist';
import { useIsServiceLoading } from 'eva/EvaHooks';
import { SelectedConfiguredProductIdState } from './ProductDetailsState';

const useProduct = (productId: number) => {
  const [rootProductId, setRootProductId] = useState(productId);
  const productImage = useProductImage(rootProductId, 300, 300);
  const isLoading = useIsServiceLoading({
    serviceState: state.product.productDetailState,
  });
  const setProductDetailsRequest = useSetRecoilState(
    state.product.productDetailState.request,
  );
  useEffect(() => {
    setProductDetailsRequest({ ID: rootProductId });
  }, [rootProductId, setProductDetailsRequest]);

  const productDetailsResponse = useRecoilValue(
    state.product.productDetailState.response,
  );
  const configurableProductDetailsResponse = useRecoilValue(
    state.product.productDetailConfigurableState.response,
  );
  const { activeWishlist } = useWishlist();

  const setSelectedConfiguredProductId = useSetRecoilState(
    SelectedConfiguredProductIdState,
  );

  useEffect(() => {
    setRootProductId(productId);
    setSelectedConfiguredProductId(undefined);
  }, [productId, setSelectedConfiguredProductId]);

  const isConfigurable = useMemo(
    () =>
      productDetailsResponse?.Result
        ? helpers.isConfigurableProduct(productDetailsResponse)
        : false,
    [productDetailsResponse],
  );
  const isBundle = useMemo(
    () =>
      productDetailsResponse?.Result
        ? helpers.isBundleProduct(productDetailsResponse)
        : false,
    [productDetailsResponse],
  );

  const isIncludedInActiveWishList = useMemo(
    () =>
      isConfigurable
        ? configurableProductDetailsResponse?.Configurable?.Children?.map(
            (x) => x.ProductID,
          )?.some(
            (childProductId) =>
              activeWishlist?.Lines?.some(
                (x) => x.Product.ID === childProductId,
              ) ?? false,
          ) ?? false
        : activeWishlist?.Lines?.some((x) => x.Product.ID === rootProductId) ??
          false,
    [
      activeWishlist?.Lines,
      configurableProductDetailsResponse?.Configurable?.Children,
      isConfigurable,
      rootProductId,
    ],
  );

  const wishlistDiscount = useMemo(() => {
    const wishlistLine = activeWishlist?.Lines?.find(
      (x) => x.Product.ID === rootProductId,
    );

    const discount = wishlistLine?.Children.filter(
      (x) => x.OpenAmount < 0,
    ).reduce((acc, curr) => acc + curr.OpenAmount, 0);
    return discount || 0;
  }, [activeWishlist?.Lines, rootProductId]);

  const wishlistLineUnitPrice = useMemo(() => {
    const wishlistLine = activeWishlist?.Lines?.find(
      (line) => line.Product.ID === rootProductId,
    );
    const unitPrice = wishlistLine?.UnitPrice as number;
    return unitPrice;
  }, [activeWishlist?.Lines, rootProductId]);

  useEffect(() => {
    if (productDetailsResponse?.Result?.logical_level !== 'root') {
      const rootId = productDetailsResponse?.Result?.logical_level_hierarchy?.find(
        (x: any) => x.name === 'root',
      )?.product_id;
      if (rootId) {
        setSelectedConfiguredProductId(
          productDetailsResponse?.Result?.product_id,
        );
        setRootProductId(rootId);
      }
    }
  }, [
    productDetailsResponse?.Result?.logical_level,
    productDetailsResponse?.Result?.logical_level_hierarchy,
    productDetailsResponse?.Result?.product_id,
    productId,
    setSelectedConfiguredProductId,
  ]);

  return {
    productDetails: productDetailsResponse?.Result,
    productImage,
    isIncludedInActiveWishList,
    wishlistDiscount,
    wishlistLineUnitPrice,
    isConfigurable,
    isBundle,
    isLoading,
  };
};

export default useProduct;
