/* eslint-disable import/prefer-default-export */
import { atom } from 'recoil';

export type DeliveryMethodType = 'delivery' | 'pickup' | 'parents';
export interface DeliveryMethod {
  name: string;
  value: DeliveryMethodType;
  isDisabled: boolean;
  isHidden: boolean;
}

export const SelectedDeliveryMethodState = atom<DeliveryMethod | undefined>({
  key: 'Checkout:SelectedDeliveryMethod',
  default: undefined,
});
