import { atom } from 'recoil';

export const CheckoutOrderMinDeliveryDate = atom<Date | undefined>({
  key: 'Checkout:MinDeliveryDate',
  default: undefined,
});

export const CheckoutOrderMaxDeliveryDate = atom<Date | undefined>({
  key: 'Checkout:MaxDeliveryDate',
  default: undefined,
});
