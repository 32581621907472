/** @jsxImportSource @emotion/react */
import tw, { css } from 'twin.macro';
import { Field, useFormikContext } from 'formik';
import { useCallback } from 'react';
import FormikInput from '../ui/FormikInput';
import Slider from '../ui/Slider';
import TextArea from '../ui/TextArea';
import { WishListDonationValues } from './DonateTypes';

export default function WishListDonationFields() {
  const {
    values,
    setFieldValue,
    errors,
    touched,
  } = useFormikContext<WishListDonationValues>();

  const getDonationInputStep = useCallback((currentValue: number) => {
    if (100 - currentValue > 0.6) {
      return 0.5;
    }
    if (100 - currentValue > 0.1) {
      return 0.1;
    }
    return 0.01;
  }, []);

  const handleSliderChange = useCallback(
    (value: number) => setFieldValue('donationValue', value),
    [setFieldValue],
  );

  return (
    <div
      css={[
        tw`bg-white`,
        css`
          min-width: 250px;

          @media (min-width: 640px) {
            min-width: 400px;
          }

          @media (min-width: 768px) {
            min-width: 650px;
          }
        `,
      ]}
    >
      <div className="mb-4">
        <div className="flex flex-col sm:flex-row ">
          <div className="flex-1 hidden sm:block">
            <Slider
              startValue={5}
              endValue={100}
              selectedValue={values.donationValue}
              onAfterChange={handleSliderChange}
            />
          </div>
          <div className="border border-gray sm:ml-2 p-2 flex items-center">
            <span className="mr-2">€</span>
            <Field
              name="donationValue"
              type="number"
              className="outline-none sm:text-center flex-1 sm:w-12"
              min={5}
              max={100}
              step={getDonationInputStep(values.donationValue)}
            />
          </div>
        </div>
        {errors.donationValue && touched.donationValue && (
          <div className="text-sm flex-1 text-error mt-2">
            {errors.donationValue}
          </div>
        )}
      </div>

      <FormikInput
        type="text"
        name="name"
        labelId="wishlist-donation.your-name"
        capitalizeLabel={false}
      />
      <FormikInput
        type="text"
        name="email"
        labelId="wishlist-donation.your-email"
        capitalizeLabel={false}
      />
      <TextArea
        name="remark"
        labelId="wishlist-donation.remark"
        capitalizeLabel={false}
      />
    </div>
  );
}
