import { Field, Form, Formik, FormikValues } from 'formik';
import { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { FormattedMessage } from 'react-intl';
import useDebouncedValue from 'hooks/useDebouncedValue';
import { CheckoutOrderState } from '../state/CheckoutOrder';
import useEvaCheckoutService from '../hooks/useEvaCheckoutServices';
import { CheckoutRequireFamilyMessage } from '../state/CheckoutRequireFamilyMessage';

export interface MessageValues extends FormikValues {
  message: string;
}
export default function OrderFamilyMessage() {
  const checkoutOrder = useRecoilValue(CheckoutOrderState);
  const { evaUpdateWishlistFundingService } = useEvaCheckoutService();
  const [initialValues, setInitialValues] = useState<MessageValues>({
    message: '',
  });
  const setRequiresFamilyMessage = useSetRecoilState(
    CheckoutRequireFamilyMessage,
  );
  const debouncedRemark = useDebouncedValue(initialValues.message);

  useEffect(() => {
    if (checkoutOrder) {
      setRequiresFamilyMessage(debouncedRemark.trim().length === 0);

      evaUpdateWishlistFundingService({
        OrderID: checkoutOrder.ID,
        Remark: debouncedRemark,
        Name: checkoutOrder.Customer.FullName,
      });
    }
  }, [
    checkoutOrder,
    debouncedRemark,
    evaUpdateWishlistFundingService,
    setRequiresFamilyMessage,
  ]);

  return (
    <>
      <Formik<MessageValues>
        initialValues={initialValues}
        enableReinitialize
        onSubmit={() => {}}
      >
        {() => (
          <div className="h-full flex flex-col items-stretch">
            <div className="mb-4">
              <FormattedMessage id="checkout-page.family-message" />
            </div>
            <Form className="flex-1">
              <Field
                as="textarea"
                id="family-message"
                name="family-message"
                onChange={(e: { target: { value: string } }) => {
                  setInitialValues({ message: e.target.value });
                }}
                rows={5}
                className="border border-gray h-full w-full"
              />
            </Form>
          </div>
        )}
      </Formik>
    </>
  );
}
