import { useMemo } from 'react';
import { FormattedNumber } from 'react-intl';
import { CurrentCurrencySelector } from 'state/CartState';
import { useGetState } from 'eva/EvaHooks';

interface IPriceProps {
  currency?: string;
  value?: number;
  prefix?: string;
  hideCurrency?: boolean;
  alwaysShowDecimals?: boolean;
  numberOfDecimals?: number;
}

export default function Price(props: IPriceProps) {
  const isInteger = useMemo(
    () =>
      props.value !== undefined &&
      Number.isFinite(props.value) &&
      Math.floor(props.value) === props.value,
    [props.value],
  );

  const currentCurrency = useGetState(CurrentCurrencySelector);
  const numberOfDecimals = useMemo(() => {
    if (props.numberOfDecimals !== undefined) {
      return props.numberOfDecimals;
    }
    return !isInteger || props.alwaysShowDecimals ? 2 : 0;
  }, [isInteger, props.alwaysShowDecimals, props.numberOfDecimals]);

  return (
    <>
      {props.prefix && (
        <span className="inline-block mr-1">{props.prefix}</span>
      )}
      <FormattedNumber
        value={props.value ?? 0}
        // eslint-disable-next-line react/style-prop-object
        style={props.hideCurrency || !currentCurrency ? undefined : 'currency'}
        currencyDisplay="symbol"
        currency={props.currency || currentCurrency}
        minimumFractionDigits={numberOfDecimals}
        maximumFractionDigits={numberOfDecimals}
      />
      {isInteger &&
        !props.alwaysShowDecimals &&
        props.numberOfDecimals === undefined &&
        ',-'}
    </>
  );
}
