import Text from 'components/shared/ui/Text';
import { FormattedMessage } from 'react-intl';
import { Form, Formik } from 'formik';
import DatePicker from 'components/shared/ui/DatePicker';
import { addMonths } from 'date-fns';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Button from 'components/shared/ui/Button';
import { useSetServiceStale } from 'eva/EvaHooks';
import { state } from '@springtree/eva-sdk-react-recoil';
import { NewWishListValues } from './NewWishListTypes';
import FormikInput from '../shared/ui/FormikInput';
import useWishList from '../../hooks/useWishlist';

export default function NewWishListPage() {
  const { createNewWishlist } = useWishList();
  const history = useHistory();
  const refreshUserWishLists = useSetServiceStale({
    serviceState: state.wishList.wishListsForUserState,
  });

  const handleSubmit = useCallback(
    async (values: NewWishListValues) => {
      const response = await createNewWishlist({
        Name: values.wishListName,
        Data: {
          dueDate: values.dueDate,
          requestedDeliveryDate: values.requestedDeliveryDate,
        },
      });

      if (response?.OrderID && response?.AccessToken) {
        history.push(
          `/geboortelijst/${response.OrderID}/${response.AccessToken}`,
        );
        refreshUserWishLists();
      }
    },
    [createNewWishlist, history, refreshUserWishLists],
  );

  return (
    <div className="container mx-auto p-6 lg:p-10">
      <Text type="h1" className="font-bold mb-4">
        <FormattedMessage id="new-wishlist-page.title" />
      </Text>

      <Formik<NewWishListValues>
        initialValues={{ wishListName: '' }}
        onSubmit={handleSubmit}
      >
        <Form>
          <div className="lg:w-1/2 bg-white p-4 lg:p-6">
            <FormikInput
              type="text"
              name="wishListName"
              labelId="new-wishlist-page.wishlist-name"
            />
            <DatePicker
              labelId="new-wishlist-page.due-date"
              name="dueDate"
              minDate={new Date()}
              maxDate={addMonths(new Date(), 9)}
            />
            <DatePicker
              labelId="new-wishlist-page.requested-delivery-date"
              name="requestedDeliveryDate"
              minDate={new Date()}
              disableSundays
            />
            <Button
              variant="contained"
              type="submit"
              className="w-full lg:w-auto"
            >
              <FormattedMessage id="generic.save" />
            </Button>
          </div>
        </Form>
      </Formik>
    </div>
  );
}
