/** @jsxImportSource @emotion/react */
import Text from 'components/shared/ui/Text';
import { FormattedMessage } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { Column, CellProps } from 'react-table';
import { useMemo } from 'react';
import OrderLineProductImage from 'components/shared/order/OrderLineProductImage';
import Table from 'components/shared/ui/Table';
import Price from 'components/shared/ui/Price';
import tw from 'twin.macro';
import { CheckoutOrderState } from '../state/CheckoutOrder';

export default function OrderDetails() {
  const checkoutOrder = useRecoilValue(CheckoutOrderState);

  const columns = useMemo<Array<Column<EVA.Core.OrderLineDto>>>(
    () => [
      {
        accessor: 'Description',
        Header: () => (
          <FormattedMessage id="checkout-page.order-details.description" />
        ),
        Cell: (props: CellProps<EVA.Core.OrderLineDto>) => (
          <div className="flex items-center">
            {props.cell.row.original.ProductID && (
              <OrderLineProductImage
                productId={props.cell.row.original.ProductID}
                className="mr-4"
              />
            )}
            <div css={!props.cell.row.original.ProductID && tw`ml-20`}>
              {props.cell.value}
            </div>
          </div>
        ),
      },
      {
        accessor: 'TotalQuantityToShip',
        Header: () => (
          <FormattedMessage id="checkout-page.order-details.quantity" />
        ),
      },
      {
        accessor: 'UnitPriceInTax',
        Header: () => (
          <FormattedMessage id="checkout-page.order-details.price" />
        ),
        Cell: (props: CellProps<EVA.Core.OrderLineDto>) => (
          <Price
            value={props.cell.value}
            currency={props.cell.row.original.CurrencyID}
          />
        ),
      },
      {
        accessor: 'TotalAmountInTax',
        Header: () => (
          <div className="text-right">
            <FormattedMessage id="checkout-page.order-details.subtotal" />
          </div>
        ),
        Cell: (props: CellProps<EVA.Core.OrderLineDto>) => (
          <div className="text-right">
            <Price
              value={props.cell.value}
              currency={props.cell.row.original.CurrencyID}
            />
          </div>
        ),
      },
    ],
    [],
  );

  return (
    <>
      {checkoutOrder && (
        <>
          <Text type="h2" className="mb-4">
            <FormattedMessage id="checkout-page.order-details.title" />
          </Text>
          <Table
            data={checkoutOrder.Lines.filter((x) => x.QuantityOpen > 0)}
            columns={columns}
          />
        </>
      )}
    </>
  );
}
