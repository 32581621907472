import ProductCard from 'components/shared/product/ProductCard';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { SearchPageResultsState } from './SearchPageState';

export default function SearchPageResults() {
  const products = useRecoilValue(SearchPageResultsState);

  return (
    <>
      {products && (
        <>
          {products.Products.length > 0 ? (
            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6 mb-4">
              {products.Products.map((x) => (
                <Link
                  to={`/product/${x.product_id}/${x.slug}`}
                  key={x.product_id}
                >
                  <ProductCard
                    id={x.product_id}
                    brandName={x.brand_name}
                    name={x.display_value}
                    price={x.display_price}
                    isBundle={x.product_types?.includes(2048)}
                  />
                </Link>
              ))}
            </div>
          ) : (
            <FormattedMessage id="search-page.no-results" />
          )}
        </>
      )}
    </>
  );
}
