import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { setCoreSetting } from '@springtree/eva-sdk-core-settings';
import { setServiceSetting } from '@springtree/eva-sdk-core-service';
import App from './App';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import 'assets/scss/icons.scss';

setCoreSetting('appName', 'babydump-wishlist-react');
setCoreSetting('appVersion', '1.0.0');
const organizationUnitQuery = {
  Name: 'Geboortelijst',
  BackendID: 'geboortelijst',
};
setServiceSetting('requestedOrganizationUnitQuery', organizationUnitQuery);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
