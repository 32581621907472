import { useCallService } from 'eva/EvaHooks';
import { Core } from '@springtree/eva-services-core';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { ICustomer } from './EmployeeTypes';
import useIsMounted from '../../hooks/useIsMounted';
import { CustomersTableFiltersState } from './EmployeeState';

const useSearchCustomers = () => {
  const [
    searchUsersResponse,
    setSearchUsersResponse,
  ] = useState<EVA.Core.SearchUsersResponse>();
  const searchUsers = useCallService({ service: Core.SearchUsers });
  const isMounted = useIsMounted();
  const customersTableFilters = useRecoilValue(CustomersTableFiltersState);

  useEffect(() => {
    if (customersTableFilters.query) {
      const start =
        (customersTableFilters.currentPage - 1) *
        customersTableFilters.pageSize;

      searchUsers({
        Types: 2,
        SearchQuery: customersTableFilters.query,
        PageConfig: {
          Start: start,
          Limit: customersTableFilters.pageSize,
        },
      }).then((response) => {
        if (isMounted) {
          setSearchUsersResponse(response);
        }
      });
    }
  }, [
    customersTableFilters.currentPage,
    customersTableFilters.pageSize,
    customersTableFilters.query,
    isMounted,
    searchUsers,
  ]);

  return {
    customers: searchUsersResponse?.Result?.Page as
      | Array<ICustomer>
      | undefined,
    totalCustomers: searchUsersResponse?.Result?.Total,
  };
};

export default useSearchCustomers;
