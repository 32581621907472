/* eslint-disable react-hooks/exhaustive-deps */
import AppConfiguration from 'AppConfiguration';
import { useState, useCallback, useMemo, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { UserCardPaymentMethodSelector } from 'state/PaymentState';
import Input from 'components/shared/ui/Input';
import Button from 'components/shared/ui/Button';
import Text from 'components/shared/ui/Text';
import useWishList from 'hooks/useWishlist';
import useGetWishListCard from 'hooks/useGetWishListUserCard';
import Separator from 'components/shared/ui/Separator';
import { WishListDonationValues } from 'components/shared/donate/DonateTypes';
import useCreateCheckoutPayment from 'components/shared/payment/useCreateCheckoutPayment';
import { useHistory } from 'react-router-dom';
import useCreateWishListPayment from 'components/shared/payment/useCreateWishListPayment';
import useCheckout from 'components/checkout/hooks/useCheckout';
import { CheckoutAmountsState } from 'components/checkout/state/CheckoutAmount';
import { IPaymentMethod, PaymentTypes } from './PaymentTypes';
import usePayInStore from './usePayInStore';
import Price from '../ui/Price';

interface ICreatePayment {
  isButtonDisabled?: boolean;
  payInStoreEnabled?: boolean;
  paymentType: PaymentTypes;
  wishListDonationValues?: WishListDonationValues;
  availableMethods: IPaymentMethod[];
}

export default function CreatePayment(props: ICreatePayment) {
  const { isParent } = useWishList();
  const { wishListCardBalance, wishListCard } = useGetWishListCard();
  const userCardPaymentMethod = useRecoilValue(UserCardPaymentMethodSelector);
  const checkoutAmounts = useRecoilValue(CheckoutAmountsState);
  const [selectedMethod, setSelectedMethod] = useState<IPaymentMethod>();
  const maxUserCardSpendableAmount = useMemo(() => {
    const balance = Math.min(
      checkoutAmounts?.Open?.InTax ?? 0,
      wishListCardBalance ?? 0,
    );
    return balance;
  }, [checkoutAmounts?.Open?.InTax, wishListCardBalance]);

  const availableUserCardBalance = useMemo(() => {
    let balance = wishListCardBalance || 0;

    if (checkoutAmounts?.Paid.Pending) {
      balance -= checkoutAmounts.Paid.Pending;
    }
    return balance;
  }, [checkoutAmounts, wishListCardBalance]);

  const [userCardValueToSpend, setUserCardValueToSpend] = useState<number>(
    maxUserCardSpendableAmount,
  );
  useEffect(() => {
    setUserCardValueToSpend(maxUserCardSpendableAmount);
  }, [maxUserCardSpendableAmount]);
  const createWishListPayment = useCreateWishListPayment();
  const createCheckoutPayment = useCreateCheckoutPayment();
  const createPayInStore = usePayInStore();
  const { refreshCheckoutOrder } = useCheckout();
  const history = useHistory();

  const handleCreatePayment = useCallback(async () => {
    if (!selectedMethod) {
      return;
    }

    if (
      props.paymentType === PaymentTypes.WishListDonation &&
      props.wishListDonationValues
    ) {
      createWishListPayment(selectedMethod.ID, props.wishListDonationValues);
    }

    if (props.paymentType === PaymentTypes.Checkout) {
      if (
        selectedMethod.Name === 'USERCARD' &&
        userCardValueToSpend <= maxUserCardSpendableAmount
      ) {
        const result = await createCheckoutPayment({
          paymentMethod: selectedMethod,
          userCard: wishListCard,
          amount: userCardValueToSpend,
        });

        if (result?.OpenAmount !== undefined && result.OpenAmount > 0) {
          refreshCheckoutOrder();
          setSelectedMethod(undefined);
        } else {
          await refreshCheckoutOrder();
          history.push('/checkout/return');
        }
      }

      if (/buckaroo/i.test(selectedMethod.Name)) {
        let amount: number | undefined;

        // if we have pending payment, explicitly set amount to openAmount
        if (checkoutAmounts && checkoutAmounts?.Paid?.Pending > 0) {
          amount = checkoutAmounts?.Open.InTax;
        }

        const result = await createCheckoutPayment({
          paymentMethod: selectedMethod,
          amount,
        });

        console.log('PAYMENT', result);

        if (result?.Properties?.RedirectUrl) {
          window.location.href = result?.Properties?.RedirectUrl;
        }
      }

      if (selectedMethod.Code === 'payInStore') {
        createPayInStore();
        history.push('/checkout/complete/true');
      }
    }
  }, [
    maxUserCardSpendableAmount,
    selectedMethod,
    userCardValueToSpend,
    wishListCard,
  ]);

  return (
    <div className="bg-white p-4">
      {props.availableMethods !== undefined && (
        <div className="flex flex-col">
          <Text type="h2">
            <FormattedMessage id="generic.payment-methods" />
          </Text>
          <Separator color="gray" className="my-4" />
          {props.availableMethods.map((x) => (
            <label
              key={`payment-method-${x.Name}`}
              htmlFor={`payment-method-${x.Name}`}
              className="flex items-center my-4 cursor-pointer"
            >
              <input
                id={`payment-method-${x.Name}`}
                type="radio"
                checked={selectedMethod === x}
                onChange={() => {
                  setSelectedMethod(x);
                }}
              />
              <img
                className="w-8 h-8 mx-4"
                src={
                  (AppConfiguration.paymentMethodImages as Record<
                    string,
                    string
                  >)[x.Code] || AppConfiguration.paymentMethodImages.default
                }
                alt={x.Name}
              />
              {x.Name.replace(/buckaroo/i, '')}
            </label>
          ))}
          {props.payInStoreEnabled && (
            <>
              <label
                htmlFor="payment-method-payinstore"
                className="flex items-center my-4 cursor-pointer"
              >
                <input
                  id="payment-method-payinstore"
                  type="radio"
                  checked={selectedMethod?.Code === 'payInStore'}
                  onChange={() => {
                    setSelectedMethod({
                      Code: 'payInStore',
                      Name: 'Betaal in winkel',
                      ID: 0,
                    });
                  }}
                />
                <img
                  className="w-8 h-8 mx-4"
                  src={AppConfiguration.paymentMethodImages.default}
                  alt="Pay in store"
                />
                <FormattedMessage id="payment-methods.pay-in-store" />
              </label>
            </>
          )}
          {wishListCardBalance &&
          wishListCardBalance > 0 &&
          availableUserCardBalance > 0 &&
          isParent ? (
            <>
              <label
                htmlFor="payment-method-usercard"
                className="flex items-center my-4 cursor-pointer"
              >
                <input
                  id="payment-method-usercard"
                  type="radio"
                  checked={selectedMethod === userCardPaymentMethod}
                  onChange={() => {
                    setSelectedMethod(userCardPaymentMethod);
                  }}
                />
                <img
                  className="w-8 h-8 mx-4"
                  src={
                    (AppConfiguration.paymentMethodImages as Record<
                      string,
                      string
                    >).USERCARD || AppConfiguration.paymentMethodImages.default
                  }
                  alt="USERCARD"
                />
                <div>
                  <div>
                    <FormattedMessage id="payment-methods.user-card.pay-with-donations" />
                  </div>
                  <div>
                    (
                    <FormattedMessage
                      id="payment-methods.user-card.pay-with-donations-available"
                      values={{ spendableAmount: availableUserCardBalance }}
                    />
                    <Price
                      value={availableUserCardBalance}
                      currency={wishListCard?.CurrencyID}
                    />
                    )
                  </div>
                </div>
              </label>
              {selectedMethod === userCardPaymentMethod && (
                <div className="ml-4">
                  <div className="flex flex-col sm:flex-row sm:items-center p-2">
                    <FormattedMessage id="payment-methods.user-card.amount-to-use" />
                    <div className="flex-1 mt-2 ml-2">
                      <Input
                        type="number"
                        value={userCardValueToSpend}
                        onChange={(e) =>
                          setUserCardValueToSpend(parseFloat(e.target.value))
                        }
                      />
                    </div>
                  </div>
                  {userCardValueToSpend > maxUserCardSpendableAmount && (
                    <span className="text-error">
                      <FormattedMessage id="payment-methods.user-card.amount-to-use.max-amount-error" />
                    </span>
                  )}
                </div>
              )}
            </>
          ) : undefined}
          <Button
            className="self-end"
            disabled={props.isButtonDisabled || !selectedMethod}
            onClick={handleCreatePayment}
          >
            <FormattedMessage id="generic.pay" />
          </Button>
        </div>
      )}
    </div>
  );
}
