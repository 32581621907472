/* eslint-disable import/prefer-default-export */
import { atom } from 'recoil';
import { IOrdersOverviewFilters } from './OrdersOverviewTypes';

export const OrdersOverviewFiltersState = atom<IOrdersOverviewFilters>({
  key: 'OrdersOverview:Filters',
  default: {
    pageSize: 15,
    currentPage: 1,
  },
});
