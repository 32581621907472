import React from 'react';
import Button from 'components/shared/ui/Button';
import DatePicker from 'components/shared/ui/DatePicker';
import FormikInput from 'components/shared/ui/FormikInput';
import Link from 'components/shared/ui/Link';
import Text from 'components/shared/ui/Text';
import { Form, Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { addMonths } from 'date-fns';
import Address from '../shared/address/Address';
import {
  InitialRegisterValues,
  RegisterValues,
} from '../shared/profile/RegisterTypes';
import useRegistration from './useRegistration';
import useRegisterValidationSchema from '../shared/profile/useRegisterValidationSchema';

export default function RegisterPage() {
  const history = useHistory();
  const loginValidationSchema = useRegisterValidationSchema();
  const { register, emailAlreadyInUseError } = useRegistration();

  return (
    <Formik<RegisterValues>
      initialValues={InitialRegisterValues}
      onSubmit={register}
      validationSchema={loginValidationSchema}
    >
      {() => (
        <Form>
          <div className="container mx-auto p-10">
            <Text type="h1" className="mb-2">
              <FormattedMessage id="register-page.title" />
            </Text>
            <Text>
              <FormattedMessage id="register-page.description" />
            </Text>
            <div className="bg-white p-4 md:p-8 my-4">
              <Text type="h3" className="mb-2 uppercase">
                <FormattedMessage id="register-page.personal-information" />
              </Text>
              <div className="grid grid-cols-1 md:grid-cols-2">
                <div className="md:mr-2">
                  <FormikInput
                    name="firstName"
                    labelId="generic.first-name"
                    type="text"
                  />
                  <FormikInput
                    name="lastName"
                    labelId="generic.last-name"
                    type="text"
                  />
                  <FormikInput
                    name="wishlistName"
                    labelId="register-page.wishlist-name"
                    type="text"
                  />
                </div>
                <div className="md:ml-2">
                  <FormikInput
                    name="phoneNumber"
                    labelId="generic.telephone"
                    type="text"
                  />
                  <FormikInput
                    name="email"
                    labelId="generic.email-address"
                    type="text"
                  />
                  <DatePicker
                    minDate={new Date()}
                    maxDate={addMonths(new Date(), 9)}
                    name="dueDate"
                    labelId="register-page.calculated-date"
                  />
                  <DatePicker
                    minDate={new Date()}
                    name="requestedDeliveryDate"
                    labelId="register-page.desired-delivery-date"
                    disableSundays
                  />
                </div>
              </div>
              <Address />
              <Text type="h3" className="mb-2 uppercase">
                <FormattedMessage id="register-page.create-password" />
              </Text>
              <div className="grid grid-cols-2">
                <div>
                  <FormikInput
                    name="password"
                    labelId="generic.password"
                    type="password"
                  />
                  <FormikInput
                    name="confirmPassword"
                    labelId="generic.repeat-password"
                    type="password"
                  />
                </div>
              </div>
              {emailAlreadyInUseError && (
                <div className="text-right mb-2 text-sm">
                  <span className="text-red-500 mr-1">
                    <FormattedMessage id="register-page.error.email-in-use" />
                  </span>
                  <Link to="/login">
                    <FormattedMessage id="register-page.error.email-in-use.login-here" />
                  </Link>
                </div>
              )}
              <div className="flex justify-between">
                <Button variant="text" onClick={() => history.push('/')}>
                  <FormattedMessage id="generic.back" />
                </Button>
                <Button type="submit" underline>
                  <FormattedMessage id="register-page.action" />
                </Button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
