/** @jsxImportSource @emotion/react */
import useProductImage from 'components/shared/product/useProductImage';
import Button from 'components/shared/ui/Button';
import Icon from 'components/shared/ui/Icon';
import Separator from 'components/shared/ui/Separator';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import AppConfiguration from 'AppConfiguration';
import Price from 'components/shared/ui/Price';
import tw, { css } from 'twin.macro';
import { IProductAvailabilityInformation } from './CartTypes';

interface ICartItemProps {
  orderLine: EVA.Core.OrderLineDto;
  onRemove: () => void;
  hasPromotion?: boolean;
  cantRemove?: boolean;
  productAvailabilityInformation?: IProductAvailabilityInformation;
}

export default function CartItem(props: ICartItemProps) {
  const productImage = useProductImage(props.orderLine.ProductID, 98, 98);

  return (
    <>
      <div
        css={[
          css`
            grid-template-columns: 3fr 1fr 1fr;
            grid-template-rows: repeat(2, auto);
          `,
          tw`sm:grid gap-4`,
        ]}
      >
        <div className="sm:flex">
          {props.orderLine.ProductID && (
            <Link
              to={`/product/${props.orderLine.ProductID}`}
              className="sm:flex-shrink-0"
            >
              <img
                src={productImage}
                alt=""
                className="w-16 h-16 mb-4 sm:h-24 sm:w-24 mx-auto object-center sm:mr-4"
              />
            </Link>
          )}
          <div>
            {props.orderLine.Product?.BrandName && (
              <div className="text-center sm:text-left">
                {props.orderLine.Product?.BrandName}
              </div>
            )}
            <div className="text-center sm:text-left">
              {props.orderLine.Description}
            </div>
            {!props.cantRemove && (
              <Button
                variant="stroked"
                className="w-full sm:w-auto my-4 flex items-center justify-center"
                onClick={props.onRemove}
              >
                <FormattedMessage id="generic.delete" />
                <span className="mt-0.5">
                  <Icon name="times" />
                </span>
              </Button>
            )}
          </div>
        </div>
        <div className="lg:flex lg:items-start lg:self-start">
          <div className="bg-gray w-full sm:w-16 text-center p-2 ">
            {props.orderLine.TotalQuantityToShip}
          </div>
          {props.orderLine.TotalQuantityToShip > 1 && (
            <span className="text-sm hidden lg:block lg:self-center">
              <Icon name="times" />
              <Price hideCurrency value={props.orderLine.UnitPriceInTax} />
              <span className="inline-block mx-2">=</span>
            </span>
          )}
        </div>
        <div className="">
          <div className="text-xl text-right">
            <Price value={props.orderLine.TotalAmountInTax} />
          </div>
          {props.hasPromotion && (
            <div className="bg-red-600 text-center py-0.5 my-4 text-sm text-white uppercase">
              <FormattedMessage id="cart-page.cart-item.promo" />
            </div>
          )}
          <div className="lg:mt-8">
            {props.productAvailabilityInformation && (
              <div className="text-gray-dark text-sm text-right">
                {props.productAvailabilityInformation.hasStockForDelivery && (
                  <FormattedMessage id="cart-page.cart-item.in-stock" />
                )}
                {!props.productAvailabilityInformation.hasStockForDelivery &&
                  !props.productAvailabilityInformation
                    .earliestAvailabilityDate &&
                  props.productAvailabilityInformation.availableStores &&
                  props.productAvailabilityInformation.availableStores.length >
                    0 && (
                    <FormattedMessage
                      id="cart-page.cart-item.available-in-stores"
                      values={{
                        stores: props.productAvailabilityInformation.availableStores.join(
                          ', ',
                        ),
                      }}
                    />
                  )}
                {!props.productAvailabilityInformation.hasStockForDelivery &&
                  props.productAvailabilityInformation
                    .earliestAvailabilityDate && (
                    <>
                      <div className="text-gray-dark text-sm text-right">
                        <FormattedMessage id="cart-page.expected-delivery-date" />
                      </div>
                      <div>
                        {format(
                          props.productAvailabilityInformation
                            .earliestAvailabilityDate,
                          AppConfiguration.dateFormat,
                        )}
                      </div>
                    </>
                  )}
              </div>
            )}
          </div>
        </div>
      </div>
      <Separator className="my-8" />
    </>
  );
}
