/* eslint-disable import/prefer-default-export */
import { atom } from 'recoil';
import { ICustomersTableFilters } from './EmployeeTypes';

export const CustomersTableFiltersState = atom<ICustomersTableFilters>({
  key: 'EmployeePage:CustomersTableFilters',
  default: {
    pageSize: 10,
    currentPage: 1,
  },
});
