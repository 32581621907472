import { atom, selector } from 'recoil';
import { state } from '@springtree/eva-sdk-react-recoil';
import {
  WishListCover,
  WishListFilterValues,
  WishListSortType,
} from './WishListTypes';

export const WishListFiltersState = atom<WishListFilterValues>({
  key: 'WishList:Filters',
  default: {
    sortType: WishListSortType.Position,
    showNotOrdered: false,
    showUniqueProducts: false,
  },
});

export const WishListCoverSelector = selector<WishListCover>({
  key: 'WishList:Cover',
  get: ({ get }) => {
    const wishListDetails = get(state.wishList.wishListDetailsState.response);
    return {
      wishListName: wishListDetails?.Result?.Name ?? '',
      orderId: wishListDetails?.Result?.OrderID,
      accessToken: wishListDetails?.Result?.AccessToken,
      imageId: wishListDetails?.Result?.Data?.imgId,
    };
  },
});

export const ActiveWishListItemAvailabilityState = atom<
  EVA.Core.GetAvailabilityIndicationResponse | undefined
>({
  key: 'WishList:ActiveWishListItemAvailability',
  default: undefined,
});

export const ShowParentInformationDialogState = atom<boolean>({
  key: 'WishList:ShowParentInformationDialogState',
  default: localStorage.getItem('parentInformationModalShown') === null,
});

export const ShowFamilyInformationDialogState = atom<boolean>({
  key: 'WishList:ShowFamilyInformationDialogState',
  default: localStorage.getItem('familyInformationModalShown') === null,
});
