interface IIconProps {
  name:
    | 'circle'
    | 'arrow-up'
    | 'arrow-down'
    | 'arrow-left'
    | 'arrow-right'
    | 'facebook'
    | 'twitter'
    | 'instagram'
    | 'star'
    | 'star-o'
    | 'star-half-o'
    | 'cart'
    | 'clock'
    | 'package'
    | 'returns'
    | 'klarna'
    | 'reviews'
    | 'heart'
    | 'check'
    | 'minus'
    | 'times'
    | 'menu'
    | 'arrow-thin-left'
    | 'arrow-thin-right'
    | 'enlarge'
    | 'search'
    | 'plus'
    | 'check'
    | 'info';
}

export default function Icon(props: IIconProps) {
  return <span className={`icon icon-${props.name}`} />;
}
