import useIsMounted from 'hooks/useIsMounted';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  SearchPageFiltersState,
  SearchPageResultsState,
} from './SearchPageState';
import { IFilterItem } from './SearchPageTypes';

const useBrandsFilter = () => {
  const isMounted = useIsMounted();

  const searchProductsResponse = useRecoilValue(SearchPageResultsState);
  const [searchPageFilters, setSearchPageFilters] = useRecoilState(
    SearchPageFiltersState,
  );
  const [brands, setBrands] = useState<IFilterItem[]>([]);

  const toggleBrand = useCallback(
    (brand: string) => {
      if (searchPageFilters.brands.includes(brand)) {
        setSearchPageFilters((current) => ({
          ...current,
          currentPage: 1,
          brands: current.brands.filter((x) => x !== brand),
          usePriceRange: false,
        }));
      } else {
        setSearchPageFilters((current) => ({
          ...current,
          currentPage: 1,
          brands: [...current.brands, brand],
          usePriceRange: false,
        }));
      }
    },
    [searchPageFilters.brands, setSearchPageFilters],
  );

  useEffect(() => {
    if (searchProductsResponse?.Aggregations?.brand_name && isMounted) {
      setBrands(
        searchProductsResponse.Aggregations.brand_name.values
          .map(
            (x: any): IFilterItem => ({
              name: x.value,
              numberOfProducts: x.count,
              selected: searchPageFilters.brands.includes(x.value),
            }),
          )
          .sort((a: IFilterItem, b: IFilterItem) =>
            a.name.localeCompare(b.name),
          ),
      );
    }
  }, [isMounted, searchPageFilters.brands, searchProductsResponse]);

  return { brands, toggleBrand };
};

export default useBrandsFilter;
