/** @jsxImportSource @emotion/react */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import useIsMounted from 'hooks/useIsMounted';
import {
  SearchPageFiltersState,
  SearchPageResultsState,
} from './SearchPageState';

export default function usePagination() {
  const isMounted = useIsMounted();
  const searchProductsResponse = useRecoilValue(SearchPageResultsState);
  const [totalNumberOfPages, setNumberOfPages] = useState<number>();

  const [searchPageFilters, setSearchPageFilters] = useRecoilState(
    SearchPageFiltersState,
  );

  const pageSizes = useMemo(
    () => [
      { label: '15', value: 15 },
      { label: '30', value: 30 },
      { label: '45', value: 45 },
    ],
    [],
  );

  const scrollToTop = useCallback(
    () => window.scrollTo({ left: 0, top: 0 }),
    [],
  );

  const setPageSize = useCallback(
    (pageSize: number) => {
      setSearchPageFilters((current) => ({
        ...current,
        pageSize,
      }));
      scrollToTop();
    },
    [scrollToTop, setSearchPageFilters],
  );

  const setCurrentPage = useCallback(
    (currentPage: number) => {
      setSearchPageFilters((current) => ({
        ...current,
        currentPage,
      }));
      scrollToTop();
    },
    [scrollToTop, setSearchPageFilters],
  );

  useEffect(() => {
    if (searchProductsResponse && isMounted) {
      setNumberOfPages(
        Math.ceil(searchProductsResponse.Total / searchPageFilters.pageSize),
      );
    }
  }, [isMounted, searchPageFilters.pageSize, searchProductsResponse]);

  useEffect(() => {
    if (
      totalNumberOfPages &&
      searchPageFilters.currentPage > totalNumberOfPages &&
      isMounted
    ) {
      setCurrentPage(1);
    }
  }, [
    isMounted,
    totalNumberOfPages,
    searchPageFilters.currentPage,
    setCurrentPage,
  ]);

  return {
    pageSizes,
    searchPageFilters,
    totalNumberOfPages,
    setPageSize,
    setCurrentPage,
  };
}
