import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { AddressValues } from './AddressValues';

const useAddressValidationSchema = () => {
  const intl = useIntl();

  const validationSchema: yup.SchemaOf<AddressValues> = yup
    .object()
    .shape({
      zipcode: yup
        .string()
        .required(
          intl.formatMessage({ id: 'generic.address.zipcode.required' }),
        ),
      houseNumber: yup
        .string()
        .required(
          intl.formatMessage({ id: 'generic.address.house-number.required' }),
        ),
      street: yup
        .string()
        .required(
          intl.formatMessage({ id: 'generic.address.street-name.required' }),
        ),
      city: yup
        .string()
        .required(intl.formatMessage({ id: 'generic.address.city.required' })),
      countryId: yup
        .string()
        .required(
          intl.formatMessage({ id: 'generic.address.country.required' }),
        ),
    })
    .defined();

  return validationSchema;
};

export default useAddressValidationSchema;
