import { useCallback } from 'react';
import useGetCurrentUser from 'hooks/useGetCurrentUser';
import { find } from 'lodash';
import useUpdateOrderAddress from './useUpdateOrderAddress';
import useEvaCheckoutService from './useEvaCheckoutServices';
import { CheckoutProfileValues } from '../account/CheckoutProfileTypes';

const useUpdateUser = () => {
  const { currentUser } = useGetCurrentUser();
  const { mapAddress } = useUpdateOrderAddress();
  const {
    evaCreateCustomer,
    evaUpdateCustomer,
    evaCreateAddressBookItem,
    evaUpdateAddressBookItem,
    evaGetAddressBook,
  } = useEvaCheckoutService();

  const updateUserInfo = useCallback(
    async (userId: number, values: CheckoutProfileValues) => {
      const addressBook = await evaGetAddressBook({});

      if (
        currentUser?.FirstName !== values.firstName ||
        currentUser?.LastName !== values.lastName
      ) {
        await evaUpdateCustomer({
          ID: userId,
          FirstName: values.firstName,
          LastName: values.lastName,
          PhoneNumber: values.phoneNumber,
        });
      }

      if (values.billingAddress) {
        const addressBookItem = find(addressBook?.Result.Page, {
          DefaultBillingAddress: true,
        });
        if (addressBookItem) {
          await evaUpdateAddressBookItem({
            ID: addressBookItem.ID,
            Address: mapAddress(values.billingAddress),
          });
        } else {
          await evaCreateAddressBookItem({
            UseAsDefaultBillingAddress: true,
            Address: mapAddress(values.billingAddress),
          });
        }
      }

      if (values.hasShippingAddress && values.shippingAddress) {
        const addressBookItem = find(addressBook?.Result.Page, {
          DefaultShippingAddress: true,
        });
        if (addressBookItem) {
          await evaUpdateAddressBookItem({
            ID: addressBookItem.ID,
            Address: mapAddress(values.shippingAddress),
          });
        } else {
          await evaCreateAddressBookItem({
            UseAsDefaultShippingAddress: true,
            Address: mapAddress(values.shippingAddress),
          });
        }
      } else if (values.billingAddress) {
        const addressBookItem = find(addressBook?.Result.Page, {
          DefaultShippingAddress: true,
        });
        if (addressBookItem) {
          await evaUpdateAddressBookItem({
            ID: addressBookItem.ID,
            Address: mapAddress(values.billingAddress),
          });
        } else {
          await evaCreateAddressBookItem({
            UseAsDefaultShippingAddress: true,
            Address: mapAddress(values.billingAddress),
          });
        }
      }
      const updatedAddressBook = await evaGetAddressBook({});

      return updatedAddressBook;
    },
    [
      currentUser?.FirstName,
      currentUser?.LastName,
      evaCreateAddressBookItem,
      evaGetAddressBook,
      evaUpdateAddressBookItem,
      evaUpdateCustomer,
      mapAddress,
    ],
  );

  const createFamilyUser = useCallback(
    async (values: CheckoutProfileValues) => {
      if (values && values.shippingAddress && values.billingAddress) {
        const createCustomerResponse = await evaCreateCustomer({
          AutoLogin: true,
          NoAccount: true,
          User: {
            FirstName: values.firstName,
            LastName: values.lastName,
            EmailAddress: values.email,
            PhoneNumber: values.phoneNumber,
            ShippingAddress: values.hasShippingAddress
              ? mapAddress(values.shippingAddress)
              : mapAddress(values.billingAddress),
            BillingAddress: mapAddress(values.billingAddress),
          },
        });

        return createCustomerResponse?.User;
      }

      return null;
    },
    [evaCreateCustomer, mapAddress],
  );

  return { updateUserInfo, createFamilyUser };
};

export default useUpdateUser;
