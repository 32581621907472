import { IEvaServiceCallOptions } from '@springtree/eva-sdk-core-service';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { CheckoutToken } from '../state/CheckoutToken';

const useCheckoutRequestOptions = () => {
  const checkoutToken = useRecoilValue(CheckoutToken);

  const getOptions = useMemo(() => {
    const options: IEvaServiceCallOptions = {};

    if (checkoutToken) {
      options.authenticationToken = checkoutToken;
    }

    return options;
  }, [checkoutToken]);

  return { getOptions };
};

export default useCheckoutRequestOptions;
