import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';

interface IHelmetTitleProps {
  title: string;
}

export default function HelmetTitle(props: IHelmetTitleProps) {
  const intl = useIntl();

  return (
    <Helmet
      titleTemplate={`%s | ${intl.formatMessage({ id: 'generic.app-title' })}`}
      defer={false}
    >
      <title>{props.title}</title>
    </Helmet>
  );
}
