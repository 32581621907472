import { Field, Form, Formik, FormikValues } from 'formik';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useRecoilValue } from 'recoil';
import useDebouncedValue from 'hooks/useDebouncedValue';
import useEvaCheckoutService from '../hooks/useEvaCheckoutServices';
import { CheckoutOrderState } from '../state/CheckoutOrder';

export interface RemarkValues extends FormikValues {
  remark: string;
}
export default function OrderRemark() {
  const checkoutOrder = useRecoilValue(CheckoutOrderState);
  const { evaSetRemarkOnOrderService } = useEvaCheckoutService();
  const [currentRemark, setCurrentRemark] = useState<RemarkValues>({
    remark: '',
  });
  const debouncedRemark = useDebouncedValue(currentRemark.remark);
  useEffect(() => {
    if (checkoutOrder) {
      evaSetRemarkOnOrderService({
        OrderID: checkoutOrder.ID,
        Remark: debouncedRemark,
      });
    }
  }, [checkoutOrder, debouncedRemark, evaSetRemarkOnOrderService]);

  useEffect(() => {
    if (checkoutOrder?.Remark) {
      setCurrentRemark({
        remark: checkoutOrder.Remark,
      });
    }
  }, [checkoutOrder]);

  return (
    <>
      <Formik<RemarkValues>
        initialValues={currentRemark}
        enableReinitialize
        onSubmit={() => {}}
      >
        {() => (
          <div className="h-full flex flex-col items-stretch">
            <div className="mb-4">
              <FormattedMessage id="checkout-page.order-remark" />
            </div>
            <Form className="flex-1">
              <Field
                as="textarea"
                id="remark"
                name="remark"
                onChange={(e: { target: { value: string } }) => {
                  setCurrentRemark({ remark: e.target.value });
                }}
                rows={5}
                className="border border-gray h-full w-full"
              />
            </Form>
          </div>
        )}
      </Formik>
    </>
  );
}
