import { useCallService, useSetServiceStale } from 'eva/EvaHooks';
import { Core } from '@springtree/eva-services-core';
import { useCallback } from 'react';
import { state } from '@springtree/eva-sdk-react-recoil';
import useWishlist from 'hooks/useWishlist';

const useToggleWishListItemVisibility = () => {
  const { activeWishListIdentifier } = useWishlist();
  const setWishListOrderLineData = useCallService({
    service: Core.SetWishListOrderLineData,
  });
  const refreshWishListDetails = useSetServiceStale({
    serviceState: state.wishList.wishListDetailsState,
  });

  return useCallback(
    async (wishListLine: EVA.Core.WishListLine) => {
      if (activeWishListIdentifier) {
        await setWishListOrderLineData({
          OrderID: activeWishListIdentifier.orderId,
          OrderLineID: wishListLine.ID,
          IsHidden: !wishListLine.IsHidden,
        });
        refreshWishListDetails();
      }
    },
    [
      activeWishListIdentifier,
      refreshWishListDetails,
      setWishListOrderLineData,
    ],
  );
};

export default useToggleWishListItemVisibility;
