import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { SearchWishlistValues } from './LoginTypes';

const useSearchWishlistValidationSchema = () => {
  const intl = useIntl();

  const validationSchema: yup.SchemaOf<SearchWishlistValues> = yup
    .object()
    .shape({
      accessToken: yup
        .string()
        .required(intl.formatMessage({ id: 'generic.error.required' })),
      orderId: yup
        .string()
        .required(intl.formatMessage({ id: 'generic.error.required' })),
    })
    .defined();

  return validationSchema;
};

export default useSearchWishlistValidationSchema;
