import Button from 'components/shared/ui/Button';
import FormikInput from 'components/shared/ui/FormikInput';
import { Form, Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import { useSetRecoilState } from 'recoil';
import useSearchCustomers from './useSearchCustomers';
import useSearchCustomersValidationSchema from './useSearchCustomersValidationSchema';
import CustomersTable from './CustomersTable';
import { CustomersTableFiltersState } from './EmployeeState';

export default function EmployeePage() {
  const { customers, totalCustomers } = useSearchCustomers();
  const setCustomersTableFilters = useSetRecoilState(
    CustomersTableFiltersState,
  );
  const searchCustomersValidationSchema = useSearchCustomersValidationSchema();

  return (
    <div className="container p-6 lg:p-10 mx-auto">
      <div className="bg-white p-6 lg:w-1/2 mx-auto">
        <div className="mb-2 uppercase text-sm">
          <FormattedMessage id="employee-page.search-customer" />
        </div>
        <div className="mb-2 text-sm">
          <FormattedMessage id="employee-page.search-customer.help-text" />
        </div>
        <Formik<{ query: string }>
          initialValues={{ query: '' }}
          onSubmit={({ query }) =>
            setCustomersTableFilters((current) => ({
              ...current,
              query,
              currentPage: 1,
            }))
          }
          validationSchema={searchCustomersValidationSchema}
        >
          <Form>
            <FormikInput type="text" name="query" />
            <Button type="submit" variant="contained" className="w-full">
              <FormattedMessage id="generic.search" />
            </Button>
          </Form>
        </Formik>
      </div>
      <CustomersTable customers={customers} totalCustomers={totalCustomers} />
    </div>
  );
}
