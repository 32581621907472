import { state } from '@springtree/eva-sdk-react-recoil';
import { cloneDeep } from 'lodash';
import { useState, useEffect, useCallback } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { IOption } from 'components/shared/ui/types/IOption';
import { ConfigurableProperty } from './ProductDetailsTypes';
import { SelectedConfiguredProductIdState } from './ProductDetailsState';
import useIsMounted from '../../hooks/useIsMounted';

const useConfigurableProduct = () => {
  const [configurableProperties, setConfigurableProperties] = useState<
    Array<ConfigurableProperty>
  >([]);
  const productDetailConfigurableResponse = useRecoilValue(
    state.product.productDetailConfigurableState.response,
  );
  const [
    configuredProductSelectedId,
    setConfiguredProductSelectedId,
  ] = useRecoilState(SelectedConfiguredProductIdState);
  const isMounted = useIsMounted();

  const mapLevelToConfigurableProperty = useCallback(
    (level: EVA.Core.ConfigurableProductDto): ConfigurableProperty => ({
      name: level.ConfigurableProperty,
      options: level.Children.map(
        (x): IOption => ({
          label: x.Value,
          value: x.ProductID,
        }),
      ).sort((a, b) => a.label.localeCompare(b.label)),
      selectedProductId:
        configuredProductSelectedId &&
        level.Children.some((x) => x.ProductID === configuredProductSelectedId)
          ? configuredProductSelectedId
          : level.Children[0].ProductID,
    }),
    [configuredProductSelectedId],
  );

  useEffect(() => {
    if (productDetailConfigurableResponse?.Configurable) {
      let currentLevel = productDetailConfigurableResponse.Configurable;
      const properties: Array<ConfigurableProperty> = [];

      while (currentLevel.Children.some((x) => x.Value)) {
        const mappedConfigurableProperty = mapLevelToConfigurableProperty(
          currentLevel,
        );
        properties.push(mappedConfigurableProperty);
        currentLevel = currentLevel.Children.find(
          (x) => x.ProductID === mappedConfigurableProperty.selectedProductId,
        ) as EVA.Core.ConfigurableProductDto;
      }

      if (
        properties.length &&
        (!configuredProductSelectedId ||
          !properties
            .flatMap((x) => x.options)
            .some((x) => x.value === configuredProductSelectedId))
      ) {
        setConfiguredProductSelectedId(
          properties[properties.length - 1].selectedProductId,
        );
      }

      if (isMounted) {
        setConfigurableProperties(properties);
      }
    }
  }, [
    configuredProductSelectedId,
    isMounted,
    mapLevelToConfigurableProperty,
    productDetailConfigurableResponse?.Configurable,
    setConfiguredProductSelectedId,
  ]);

  const selectConfiguration = useCallback(
    (selectedId: number, propertyName: string) => {
      if (isMounted) {
        setConfigurableProperties((current) => {
          const properties = cloneDeep(current);
          const changedProperty = properties.find(
            (y) => y.name === propertyName,
          );
          if (changedProperty) {
            changedProperty.selectedProductId = selectedId;
          }
          return properties;
        });
      }
      setConfiguredProductSelectedId(selectedId);
    },
    [isMounted, setConfiguredProductSelectedId],
  );

  return {
    configurableProperties,
    selectConfiguration,
  };
};

export default useConfigurableProduct;
