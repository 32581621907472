import Button from 'components/shared/ui/Button';
import { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Checkbox from 'components/shared/ui/Checkbox';
import Select from 'components/shared/ui/Select';
import { useRecoilState } from 'recoil';
import useWishlist from 'hooks/useWishlist';
import ShareWishListDialog from './ShareWishListDialog';
import { WishListFiltersState } from './WishListState';
import {
  WishListSortType,
  WishListSortTypeTranslations,
} from './WishListTypes';
import useBuyWishList from './useBuyWishList';

export default function WishListGridHeader() {
  const intl = useIntl();
  const [showShareDialog, setShowShareDialog] = useState(false);
  const [wishListFilters, setWishListFilters] = useRecoilState(
    WishListFiltersState,
  );
  const buyWishList = useBuyWishList();
  const { isParentOrEmployee, activeWishlist } = useWishlist();

  const sortTypeDropdownOptions = useMemo(() => {
    return Object.values(WishListSortType).map((x) => ({
      value: x,
      label: intl.formatMessage({ id: WishListSortTypeTranslations[x] }),
    }));
  }, [intl]);

  return (
    <>
      {activeWishlist && (
        <>
          <div className="flex flex-col md:flex-row mt-8 md:items-center">
            <div className="flex items-center text-sm flex-1 mb-2">
              <div className="mr-2">
                <FormattedMessage id="home-page.wish-list.header.sort-by" />
              </div>
              <Select
                value={wishListFilters.sortType}
                options={sortTypeDropdownOptions}
                onChange={(e) =>
                  setWishListFilters((current) => ({
                    ...current,
                    sortType: e.target.value as WishListSortType,
                  }))
                }
              />
            </div>
            <div className="flex items-center flex-1 mb-2">
              {isParentOrEmployee && (
                <div className="bg-white px-2 pt-2 mx-2">
                  <Checkbox
                    labelId="home-page.wish-list.header.show-not-ordered"
                    value={wishListFilters.showNotOrdered}
                    onChange={(showNotOrdered) =>
                      setWishListFilters((current) => ({
                        ...current,
                        showNotOrdered,
                      }))
                    }
                  />
                </div>
              )}
              <div className="bg-white px-2 pt-2 mx-2">
                <Checkbox
                  labelId="home-page.wish-list.header.show-unique"
                  value={wishListFilters.showUniqueProducts}
                  onChange={(showUniqueProducts) =>
                    setWishListFilters((current) => ({
                      ...current,
                      showUniqueProducts,
                    }))
                  }
                />
              </div>
            </div>
            <div className="flex flex-1 md:justify-end justify-between">
              {isParentOrEmployee && (
                <>
                  <Button
                    variant="text"
                    className="mx-2 focus:outline-none bg-white"
                    onClick={() => setShowShareDialog(true)}
                  >
                    <FormattedMessage id="home-page.wish-list.header.share-list" />
                  </Button>
                  <Button
                    underline
                    className="ml-2 focus:outline-none"
                    onClick={buyWishList}
                  >
                    <FormattedMessage id="home-page.wish-list.header.buy-list" />
                  </Button>
                </>
              )}
            </div>
          </div>
          {showShareDialog && (
            <ShareWishListDialog
              closeHandler={() => setShowShareDialog(false)}
            />
          )}
        </>
      )}
    </>
  );
}
