import { useCallback } from 'react';
import { IPaymentMethod } from 'components/shared/payment/PaymentTypes';
import { useRecoilValue } from 'recoil';
import CheckoutOrderState from 'components/checkout/state/CheckoutOrder';
import useEvaCheckoutService from 'components/checkout/hooks/useEvaCheckoutServices';
import CheckoutToken from 'components/checkout/state/CheckoutToken';

const useCreatePayment = () => {
  const checkoutOrder = useRecoilValue(CheckoutOrderState);
  const checkoutToken = useRecoilValue(CheckoutToken);
  const { evaCreatePayment, evaPlaceOrder } = useEvaCheckoutService();

  return useCallback(
    async ({
      paymentMethod,
      amount,
      userCard,
    }: {
      paymentMethod: IPaymentMethod;
      amount?: number;
      userCard?: EVA.Core.UserCardDto;
    }) => {
      if (checkoutOrder) {
        console.log('START PAYMENT', amount);
        await evaPlaceOrder({ OrderID: checkoutOrder.ID });

        let properties: { [key: string]: any } = {};

        if (paymentMethod.Name === 'USERCARD') {
          properties = {
            UserID: checkoutOrder.Customer.ID,
            UserCardID: userCard?.ID,
            CardAmount: amount,
          };
        }

        if (/buckaroo/i.test(paymentMethod.Name)) {
          let returnUrl = `${document.location.protocol}//${document.location.host}/checkout/return/${checkoutOrder.ID}`;
          if (checkoutToken) {
            returnUrl += `/${checkoutToken}/`;
          }
          properties = {
            ReturnUrl: returnUrl,
          };
        }

        return evaCreatePayment({
          OrderID: checkoutOrder.ID,
          Amount: amount,
          PaymentTypeID: paymentMethod.ID,
          Properties: properties,
        });
      }
      return Promise.resolve(undefined);
    },
    [checkoutOrder, evaPlaceOrder, evaCreatePayment, checkoutToken],
  );
};

export default useCreatePayment;
