import useCallService from '@springtree/eva-sdk-react-recoil/lib/hooks/use-call-service';
import { Core } from '@springtree/eva-services-core';
import { useEffect, useState } from 'react';
import useIsMounted from '../../hooks/useIsMounted';

const useCustomerWishLists = (customerId: number) => {
  const [customerWishLists, setCustomerWishLists] = useState<
    Array<EVA.Core.WishList>
  >();
  const getWishListsForUser = useCallService({
    service: Core.GetWishListsForUser,
  });
  const isMounted = useIsMounted();

  useEffect(() => {
    setCustomerWishLists(undefined);
    getWishListsForUser({ ID: customerId, ShowClosedOrders: false }).then(
      (response) => {
        if (response?.Result && isMounted) {
          setCustomerWishLists(response.Result);
        }
      },
    );
  }, [customerId, getWishListsForUser, isMounted]);

  return customerWishLists;
};
export default useCustomerWishLists;
