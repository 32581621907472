import useWishlist from 'hooks/useWishlist';
import WishListItem from './WishListItem';
import AddWishListItem from './AddWishListItem';
import useFilteredWishListLines from './useFilteredWishListLines';
import AddWishListDonation from './AddWishListDonation';
import useGetActiveWishListItemAvailability from './useGetActiveWishListItemAvailability';

export default function WishListGrid() {
  const filteredWishListLines = useFilteredWishListLines();
  const { isParentOrEmployee } = useWishlist();

  useGetActiveWishListItemAvailability();

  return (
    <>
      {filteredWishListLines !== undefined && (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
          {!isParentOrEmployee && <AddWishListDonation />}
          {filteredWishListLines.map((line, index) => (
            <WishListItem
              key={line.ID}
              wishListLine={line}
              position={index + 1}
            />
          ))}
          {isParentOrEmployee && <AddWishListItem />}
        </div>
      )}
    </>
  );
}
