/** @jsxImportSource @emotion/react */
import React from 'react';
import tw from 'twin.macro';
import { FormattedMessage } from 'react-intl';

interface ICheckboxProps {
  labelId?: string;
  fullWidth?: boolean;
  value: boolean;
  onChange: (checked: boolean) => void;
  error?: string;
  className?: string;
}

export default function Checkbox(props: ICheckboxProps) {
  return (
    <div
      css={[
        tw`inline-flex items-center pb-2 justify-start cursor-pointer select-none`,
        tw`focus:outline-none`,
        (props.fullWidth ?? true) && tw`w-full`,
      ]}
      onClick={() => props.onChange(!props.value)}
      onKeyDown={() => {}}
      role="checkbox"
      tabIndex={0}
      aria-checked={props.value}
      className={props.className}
    >
      <div css={[tw`relative`]}>
        <div
          css={[
            tw`w-4 h-4 border border-gray`,
            props.value ? tw`bg-primary` : tw`bg-white`,
          ]}
        >
          {props.value && (
            <div
              css={[
                tw`absolute w-1.5 h-2.5 left-1/3 top-px`,
                tw`border-r-2 border-b-2 border-white`,
                tw`transform rotate-45`,
              ]}
            />
          )}
        </div>
      </div>
      {props.labelId && (
        <span className="text-sm pl-2">
          <FormattedMessage id={props.labelId} />
        </span>
      )}
      {props.error && (
        <div className="text-sm flex-grow text-right text-error">
          {props.error}
        </div>
      )}
    </div>
  );
}
