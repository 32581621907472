import { useEffect, useState } from 'react';

const useDebouncedValue = <T>(value: T, delay?: number) => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay ?? 250);

    return () => clearTimeout(handler);
  }, [delay, value]);

  return debouncedValue;
};

export default useDebouncedValue;
