import SuspenseFallback from 'eva/SuspenseFallback';
import CartPageItems from './CartPageItems';

export default function CartPage() {
  return (
    <div className="container mx-auto p-6 lg:p-10">
      <SuspenseFallback>
        <CartPageItems />
      </SuspenseFallback>
    </div>
  );
}
