import { useRecoilState } from 'recoil';
import { useMemo } from 'react';
import { CellProps, Column } from 'react-table';
import { FormattedMessage } from 'react-intl';
import Table from 'components/shared/ui/Table';
import { format, parseISO } from 'date-fns';
import AppConfiguration from 'AppConfiguration';
import Price from 'components/shared/ui/Price';
import { IPaginationProps } from 'components/shared/ui/Pagination';
import OrderDetailsCard from './OrderDetailsCard';
import { OrdersOverviewFiltersState } from './OrdersOverviewState';
import useCurrentCustomerOrders from './useCurrentCustomerOrders';

export default function OrdersTable() {
  const customerOrdersResponse = useCurrentCustomerOrders();
  const [ordersOverviewFilters, setOrdersOverviewFilters] = useRecoilState(
    OrdersOverviewFiltersState,
  );

  const paginationOptions: IPaginationProps = useMemo(
    () => ({
      currentPage: ordersOverviewFilters.currentPage,
      handlePageChange: (currentPage) =>
        setOrdersOverviewFilters((current) => ({ ...current, currentPage })),
      pageSize: ordersOverviewFilters.pageSize,
      numberOfPages: Math.ceil(
        (customerOrdersResponse?.Result?.Total ?? 0) /
          ordersOverviewFilters.pageSize,
      ),
      totalItems: customerOrdersResponse?.Result?.Total,
    }),
    [
      ordersOverviewFilters.currentPage,
      ordersOverviewFilters.pageSize,
      customerOrdersResponse?.Result?.Total,
      setOrdersOverviewFilters,
    ],
  );

  const columns: Array<Column<EVA.Core.OrderDto>> = useMemo(
    () => [
      {
        Header: () => (
          <FormattedMessage id="orders-overview-page.orders-table.order-number" />
        ),
        accessor: 'ID',
        Cell: (props: CellProps<EVA.Core.OrderDto>) =>
          `# ${[props.cell.value]}`,
      },
      {
        Header: () => (
          <FormattedMessage id="orders-overview-page.orders-table.date" />
        ),
        accessor: 'CreationTime',
        Cell: (props: CellProps<EVA.Core.OrderDto>) =>
          format(parseISO(props.cell.value), AppConfiguration.dateFormat),
      },
      {
        Header: () => (
          <FormattedMessage id="orders-overview-page.orders-table.amount" />
        ),
        accessor: 'TotalAmountInTax',
        Cell: (props: CellProps<EVA.Core.OrderDto>) => (
          <Price
            value={props.cell.value}
            currency={props.row.original.CurrencyID}
          />
        ),
      },
    ],
    [],
  );

  return (
    <div className="bg-white p-4">
      {customerOrdersResponse?.Result?.Page !== undefined && (
        <>
          {customerOrdersResponse?.Result?.Total === 0 ? (
            <FormattedMessage id="orders-overview-page.no-orders" />
          ) : (
            <Table
              data={customerOrdersResponse?.Result?.Page}
              columns={columns}
              renderExpandedRowComponent={(order) => (
                <OrderDetailsCard order={order} />
              )}
              paginationOptions={paginationOptions}
            />
          )}
        </>
      )}
    </div>
  );
}
