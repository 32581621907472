import { isMatch, parse, parseISO } from 'date-fns';
import { useCallback } from 'react';
import AppConfiguration from '../AppConfiguration';

const useParseDate = () => {
  const parseDate = useCallback((dateString: string): Date | undefined => {
    if (isMatch(dateString, AppConfiguration.dateFormat)) {
      return parse(dateString, AppConfiguration.dateFormat, new Date());
    }
    try {
      const parsedDate = parseISO(dateString);
      return parsedDate;
    } catch {
      return undefined;
    }
  }, []);

  return parseDate;
};

export default useParseDate;
