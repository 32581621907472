import { useCallback } from 'react';
import useEvaCheckoutServices from './useEvaCheckoutServices';

const useAvailabilityForStores = () => {
  const {
    evaGetProductAvailability,
    evaGetProductAvailabilityIndication,
  } = useEvaCheckoutServices();

  const getAvailabilityForStores = useCallback(
    async (orderLines: EVA.Core.OrderLineDto[], stores: number[]) => {
      const productAvailabilityProduct: any[] = [];

      // filter out closed lines
      orderLines.forEach((line) => {
        if (line.Product && line.Product.ID && line.QuantityOpen > 0) {
          productAvailabilityProduct.push({
            ID: line.Product.ID,
            QuantityRequested: line.QuantityOpen,
            ShippingMethodID: null,
          });
        }
      });

      const availability = await evaGetProductAvailability({
        Options: {
          QuantityAvailable: true,
          Delivery: {
            AvailabilityDate: true,
          },
          Pickup: {
            AvailabilityDate: true,
            OrganizationUnitIDs: stores,
          },
        },
        Products: productAvailabilityProduct,
      });

      return availability;
    },
    [evaGetProductAvailability],
  );

  const getAvailabilityForProductSet = useCallback(
    async (products: EVA.Core.ProductQuantityDto[]) => {
      const indication = await evaGetProductAvailabilityIndication({
        Products: products,
      });

      return indication?.Products;
    },
    [evaGetProductAvailabilityIndication],
  );

  return { getAvailabilityForStores, getAvailabilityForProductSet };
};

export default useAvailabilityForStores;
