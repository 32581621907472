/** @jsxImportSource @emotion/react */
import { range } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import tw from 'twin.macro';
import { FormattedMessage } from 'react-intl';
import useHorizontalWindowResize from 'hooks/useHorizontalWindowResize';
import Icon from './Icon';
import Select from './Select';
import { IOption } from './types/IOption';
import { IPageButton } from './types/IPageButton';

export interface IPaginationProps {
  totalItems?: number;
  currentPage: number;
  numberOfPages: number;
  handlePageChange: (page: number) => void;
  pageSize: number;
  pageSizeOptions?: Array<IOption>;
  handlePageSizeChange?: (pageSize: number) => void;
}

export default function Pagination(props: IPaginationProps) {
  const [showLargePagination, setShowLargePagination] = useState(
    window.matchMedia('(min-width: 768px)').matches,
  );

  const handleHorizontalResize = useCallback(() => {
    setShowLargePagination(window.matchMedia('(min-width: 768px)').matches);
  }, []);

  useHorizontalWindowResize(handleHorizontalResize);

  const pageButtons = useMemo(
    () =>
      getPaginationButtons(
        props.currentPage,
        props.numberOfPages,
        showLargePagination,
      ),
    [props.currentPage, props.numberOfPages, showLargePagination],
  );

  return (
    <>
      {props.numberOfPages > 0 && (
        <div className="flex flex-1 flex-col lg:flex-row lg:justify-between">
          {props.handlePageSizeChange && props.pageSizeOptions && (
            <Select
              options={props.pageSizeOptions}
              value={props.pageSize}
              onChange={(e) =>
                props.handlePageSizeChange?.(parseInt(e.target.value, 10))
              }
              className="mb-4 lg:mb-0"
            />
          )}
          <div className="lg:ml-auto flex justify-center lg:justify-start">
            {props.totalItems !== undefined && (
              <div className="mx-4 text-sm self-center">
                <FormattedMessage
                  id="pagination.total-items"
                  values={{
                    start: 1 + props.pageSize * (props.currentPage - 1),
                    end: Math.min(
                      props.currentPage * props.pageSize,
                      props.totalItems,
                    ),
                    totalItems: props.totalItems,
                  }}
                />
              </div>
            )}
            <button
              disabled={props.currentPage === 1}
              type="button"
              css={[
                tw`bg-white text-black py-1 px-1 text-lg outline-none`,
                props.currentPage === 1 &&
                  tw`text-gray-dark cursor-not-allowed`,
              ]}
              onClick={() => props.handlePageChange(props.currentPage - 1)}
            >
              <Icon name="arrow-left" />
            </button>

            {pageButtons.map((x, index) => (
              <button
                // eslint-disable-next-line react/no-array-index-key
                key={`${x.page}-${index}`}
                type="button"
                css={[
                  tw`bg-white px-2 py-2 mx-1 focus:outline-none`,
                  props.currentPage === x.page
                    ? tw`text-primary font-bold`
                    : tw`text-black`,
                ]}
                onClick={() => x.page && props.handlePageChange(x.page)}
              >
                {x.text}
              </button>
            ))}

            <button
              disabled={props.currentPage === props.numberOfPages}
              type="button"
              css={[
                tw`bg-white text-black py-1 px-1 text-lg outline-none`,
                props.currentPage === props.numberOfPages &&
                  tw`text-gray-dark cursor-not-allowed`,
              ]}
              onClick={() => props.handlePageChange(props.currentPage + 1)}
            >
              <Icon name="arrow-right" />
            </button>
          </div>
        </div>
      )}
    </>
  );
}

const getPaginationButtons = (
  currentPage: number,
  numberOfPages: number,
  showLargePagination: boolean,
): Array<IPageButton> => {
  const pageButtons: Array<IPageButton> = [];
  if (numberOfPages === 0) {
    return pageButtons;
  }

  const threshold = showLargePagination ? 3 : 1;

  if (currentPage <= threshold) {
    range(1, Math.min(2 * threshold, numberOfPages)).forEach((x) =>
      pageButtons.push({ page: x, text: x.toString() }),
    );
    if (numberOfPages >= 2 * threshold + 1) {
      pageButtons.push({ text: '...' });
    }
    pageButtons.push({ page: numberOfPages, text: numberOfPages.toString() });
  } else if (currentPage < numberOfPages - threshold - 1) {
    pageButtons.push({ page: 1, text: '1' });

    if (numberOfPages >= 2 * threshold + 1 && currentPage > threshold + 1) {
      pageButtons.push({ text: '...' });
    }

    range(currentPage - threshold + 1, currentPage + threshold).forEach((x) =>
      pageButtons.push({ page: x, text: x.toString() }),
    );

    if (
      numberOfPages >= 2 * threshold + 1 &&
      currentPage < numberOfPages - threshold
    ) {
      pageButtons.push({ text: '...' });
    }
    pageButtons.push({ page: numberOfPages, text: numberOfPages.toString() });
  } else {
    pageButtons.push({ page: 1, text: '1' });

    if (numberOfPages >= 2 * threshold + 1) {
      pageButtons.push({ text: '...' });
    }

    range(
      Math.max(numberOfPages - threshold - 1, 2),
      numberOfPages + 1,
    ).forEach((x) => pageButtons.push({ page: x, text: x.toString() }));
  }
  return pageButtons;
};
