/* eslint-disable max-classes-per-file */
/// <reference path="./EVA.FTH.d.ts" />
import { IEvaServiceDefinition } from '@springtree/eva-services-core';

export namespace FTH {
  /**
   * Get the expected due date for the baby on the order.
   *
   * @export
   * @class FTHGetDueDateForOrder
   * @implements {EvaService}
   */
  export class FTHGetDueDateForOrder implements IEvaServiceDefinition {
    name = 'FTH:FTHGetDueDateForOrder';

    path = '/api/fth/FTHGetDueDateForOrder';

    request?: EVA.FTH.FTHGetDueDateForOrder;

    response?: EVA.FTH.FTHGetDueDateResponse;
  }

  /**
   * Get the expected wish list discount built up through fundings ('parents' and employees only).
   *
   * @export
   * @class FTHGetWishListDiscount
   * @implements {EvaService}
   */
  export class FTHGetWishListDiscount implements IEvaServiceDefinition {
    name = 'FTH:FTHGetWishListDiscount';

    path = '/api/fth/FTHGetWishListDiscount';

    request?: EVA.FTH.FTHGetWishListDiscount;

    response?: EVA.FTH.FTHGetWishListDiscountResponse;
  }

  /**
   * Set the expected due date for the baby on the order. When `DueDate` == null it will be removed.
   *
   * @export
   * @class FTHSetDueDateOnOrder
   * @implements {EvaService}
   */
  export class FTHSetDueDateOnOrder implements IEvaServiceDefinition {
    name = 'FTH:FTHSetDueDateOnOrder';

    path = '/api/fth/FTHSetDueDateOnOrder';

    request?: EVA.FTH.FTHSetDueDateOnOrder;

    response?: EVA.Core.EmptyResponseMessage;
  }
}
