import { Core } from '@springtree/eva-services-core';
import { WishListDonationValues } from 'components/shared/donate/DonateTypes';
import { useCallService } from 'eva/EvaHooks';
import { useCallback } from 'react';
import useWishList from 'hooks/useWishlist';
import useGetCurrentUser from 'hooks/useGetCurrentUser';

const useCreateWishListPayment = () => {
  const createWishListPayment = useCallService({
    service: Core.CreateWishListPayment,
  });
  const createCustomer = useCallService({ service: Core.CreateCustomer });
  const { activeWishlist } = useWishList();
  const { currentUser } = useGetCurrentUser();

  return useCallback(
    async (paymentTypeID, values: WishListDonationValues) => {
      if (activeWishlist) {
        let authenticationToken: string | undefined;

        if (!currentUser) {
          const createUserResponse = await createCustomer({
            User: {
              EmailAddress: values.email,
              FirstName: values.name,
            },
            AutoLogin: true,
            NoAccount: true,
          });

          if (createUserResponse?.Result === 0) {
            authenticationToken = createUserResponse.User.AuthenticationToken;
          }
        }

        const result = await createWishListPayment(
          {
            OrderID: activeWishlist.OrderID,
            AccessToken: activeWishlist.AccessToken,
            Amount: values.donationValue,
            Name: values.name,
            Remark: values.remark,
            PaymentTypeID: paymentTypeID,
            Properties: {
              ReturnUrl: `${document.location.href}/success/${values.donationValue}`,
            },
          },
          authenticationToken ? { authenticationToken } : undefined,
        );
        if (result?.Properties?.RedirectUrl) {
          window.location.href = result?.Properties?.RedirectUrl;
        }
      }
    },
    [activeWishlist, createCustomer, createWishListPayment, currentUser],
  );
};

export default useCreateWishListPayment;
