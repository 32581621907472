import { useRecoilValue } from 'recoil';
import { CurrentUserState, CurrentUserTypeState } from 'state/CurrentUserState';

export default function useGetCurrentUser() {
  const currentUser = useRecoilValue(CurrentUserState);
  const currentUserType = useRecoilValue(CurrentUserTypeState);

  return {
    currentUser,
    ...currentUserType,
  };
}
