import { useMemo } from 'react';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';

const useProductQuantityValidationSchema = () => {
  const intl = useIntl();

  return useMemo(
    () =>
      Yup.object().shape({
        quantity: Yup.number().min(
          0,
          intl.formatMessage({
            id: 'product-details-page.number-of-products.error',
          }),
        ),
      }),
    [intl],
  );
};

export default useProductQuantityValidationSchema;
