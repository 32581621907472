import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { WishListDonationValues } from './DonateTypes';

const useWishListDonationValidationSchema = () => {
  const intl = useIntl();

  const validationSchema: yup.SchemaOf<WishListDonationValues> = yup
    .object()
    .shape({
      email: yup
        .string()
        .email(intl.formatMessage({ id: 'generic.error.valid.email-address' }))
        .required(intl.formatMessage({ id: 'generic.error.required' })),
      name: yup
        .string()
        .required(intl.formatMessage({ id: 'generic.error.required' })),
      remark: yup
        .string()
        .required(intl.formatMessage({ id: 'generic.error.required' })),
      donationValue: yup
        .number()
        .required(intl.formatMessage({ id: 'generic.error.required' }))
        .min(
          5,
          intl.formatMessage(
            {
              id: 'wishlist-donation.donationValue.minimum-value-error',
            },
            { minimumValue: 5 },
          ),
        ),
    })
    .defined();

  return validationSchema;
};

export default useWishListDonationValidationSchema;
