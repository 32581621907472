import ProductCard from 'components/shared/product/ProductCard';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import HiddenItemImage from 'assets/images/closed-eye.svg';
import Icon from 'components/shared/ui/Icon';
import { isNaN } from 'lodash';
import ConfirmRemoveWishListItemDialog from 'components/shared/product/ConfirmRemoveWishListItemDialog';
import useWishlist from 'hooks/useWishlist';
import { useRecoilValue } from 'recoil';
import Price from 'components/shared/ui/Price';
import ProductPrice from 'components/shared/product/ProductPrice';
import useChangeWishListItemPosition from './useChangeWishListItemPosition';
import useRemoveWishListLine from './useRemoveWishListLine';
import useToggleWishListItemVisibility from './useToggleWishListItemVisibility';
import { ActiveWishListItemAvailabilityState } from './WishListState';

interface IWishListItemProps {
  position: number;
  wishListLine: EVA.Core.WishListLine;
}

export default function WishListItem(props: IWishListItemProps) {
  const [showOverlay, setShowOverlay] = useState(false);
  const history = useHistory();
  const [positionValue, setPositionValue] = useState(props.position.toString());
  const [showRemoveDialog, setShowRemoveDialog] = useState(false);

  const toggleWishListItemVisibility = useToggleWishListItemVisibility();
  const removeWishListItem = useRemoveWishListLine();
  const changeWishListItemPosition = useChangeWishListItemPosition();

  useEffect(() => {
    setPositionValue(props.position.toString());
  }, [props.position, showOverlay]);

  const handlePositionChange = useCallback(() => {
    const parsedPosition = parseInt(positionValue, 10);
    if (!isNaN(parsedPosition)) {
      changeWishListItemPosition(props.wishListLine, parsedPosition);
    }
  }, [changeWishListItemPosition, positionValue, props.wishListLine]);

  const activeWishlistItemAvailability = useRecoilValue(
    ActiveWishListItemAvailabilityState,
  );
  const { isParentOrEmployee } = useWishlist();
  const obligation = useMemo(
    (): boolean => props.wishListLine.Product.Properties.afnameverplichting,
    [props.wishListLine.Product.Properties.afnameverplichting],
  );

  const isOutOfStock = useMemo(() => {
    const availabilityForLineProduct = activeWishlistItemAvailability?.Products?.find(
      (x) => x.ProductID === props.wishListLine.Product.ID,
    );

    if (
      availabilityForLineProduct?.ProductStatus === 32 ||
      availabilityForLineProduct?.ProductStatus === 34
    ) {
      const hasStock =
        availabilityForLineProduct.StoreAvailabilityCount > 0 ||
        (availabilityForLineProduct.Delivery.QuantityAvailable ?? 0) > 0;

      return !hasStock;
    }
    return false;
  }, [activeWishlistItemAvailability?.Products, props.wishListLine.Product.ID]);

  const isFullyFunded = useMemo(
    () =>
      props.wishListLine.OpenQuantity <= 0 &&
      props.wishListLine.Fundings.length > 0,
    [props.wishListLine],
  );

  const remainingAmount = useMemo(
    () => props.wishListLine.TotalAmount - props.wishListLine.FundedAmount,
    [props.wishListLine.FundedAmount, props.wishListLine.TotalAmount],
  );

  const isFullyDonated = useMemo(
    () =>
      props.wishListLine.FundedQuantityAsPayment ===
      props.wishListLine.Quantity,
    [props.wishListLine],
  );

  const isFullyPurchased = useMemo(
    () =>
      props.wishListLine.Quantity ===
      props.wishListLine.FundedQuantityAsDelivery,
    [props.wishListLine],
  );

  const isBundle = useMemo(() => props.wishListLine.Product.Type === 2048, [
    props.wishListLine.Product.Type,
  ]);

  const potentialDiscount = useMemo(
    () =>
      -props.wishListLine.Children.filter((x) => x.OpenAmount < 0).reduce(
        (acc, curr) => acc + curr.OpenAmount,
        0,
      ),
    [props.wishListLine.Children],
  );

  return (
    <>
      <div
        className="inline-block relative md:max-w-sm"
        onMouseEnter={() => setShowOverlay(true)}
        onMouseLeave={() => setShowOverlay(false)}
        onClick={() =>
          !isParentOrEmployee &&
          history.push(
            `/product/${props.wishListLine.Product.ID}/${props.wishListLine.Product.Properties.slug}`,
          )
        }
        role="button"
        onKeyDown={() => {}}
        tabIndex={0}
      >
        <ProductCard
          id={props.wishListLine.Product.ID}
          backendId={props.wishListLine.Product.BackendID}
          brandName={props.wishListLine.Product.Properties.brand_name}
          name={props.wishListLine.Product.Name}
          footer={
            <div className="p-2 my-auto flex self-stretch items-center justify h-12">
              {props.wishListLine.Quantity > 1 && (
                <div className="flex flex-col items-center mr-auto ml-1">
                  <span className="text-xs">
                    <FormattedMessage id="home-page.wish-list.wishlist-item.quantity" />
                  </span>
                  <span className="font-bold">
                    {props.wishListLine.Quantity}
                  </span>
                </div>
              )}
              {potentialDiscount > 0 && (
                <>
                  <div className="text-primary mx-1">
                    <Price value={potentialDiscount} />
                  </div>
                  <FormattedMessage id="generic.discount" />
                </>
              )}
              <div className="ml-auto mr-3 text-red-500 text-2xl font-bold">
                {!isFullyFunded && remainingAmount === 0 ? (
                  <FormattedMessage id="generic.free" />
                ) : (
                  <ProductPrice
                    productId={props.wishListLine.Product.ID}
                    isBundle={isBundle}
                    displayPrice={
                      props.wishListLine.UnitPrice - potentialDiscount
                    }
                  />
                )}
              </div>
            </div>
          }
          isBundle={isBundle}
        />
        {props.wishListLine.IsHidden && (
          <img
            className="absolute top-3 right-3 h-6 w-6"
            src={HiddenItemImage}
            alt=""
          />
        )}
        {isOutOfStock && (
          <div className="absolute inset-0 flex justify-center items-center bg-gray-light bg-opacity-90 border-2 border-gray">
            <div className="font-semibold text-xl">
              <FormattedMessage id="home-page.wish-list.wishlist-item.not-in-stock" />
            </div>
          </div>
        )}
        {isFullyFunded && (
          <div className="absolute inset-0 flex flex-col justify-center items-center bg-gray-light bg-opacity-90 border-2 border-gray">
            <span className="text-primary-dark text-5xl">
              <Icon name="check" />
            </span>
            {isFullyDonated && (
              <span className="font-semibold text-sm">
                <FormattedMessage id="home-page.wish-list.wishlist-item.fully-donated" />
              </span>
            )}
            {isFullyPurchased && (
              <span className="font-semibold text-sm">
                <FormattedMessage id="home-page.wish-list.wishlist-item.fully-purchased" />
              </span>
            )}
            {!isFullyDonated && !isFullyPurchased && (
              <span className="font-semibold text-sm">
                <FormattedMessage id="home-page.wish-list.wishlist-item.fully-purchased-and-donated" />
              </span>
            )}
            {props.wishListLine.Product.Type !== 2048 && (
              <span className="text-sm">
                {props.wishListLine.FundedAmount}{' '}
                <FormattedMessage
                  id={
                    props.wishListLine.Fundings.length > 1
                      ? 'home-page.wish-list.wishlist-item.donated-by-people'
                      : 'home-page.wish-list.wishlist-item.donated-by-person'
                  }
                  values={{ donations: props.wishListLine.Fundings.length }}
                />
              </span>
            )}
          </div>
        )}
        {showOverlay && isParentOrEmployee && (
          <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-70 flex flex-col justify-around p-8 shadow">
            <div className="flex items-center">
              <div className="text-white mr-3">
                <FormattedMessage id="home-page.wish-list.wishlist-item.position" />
              </div>
              <input
                type="text"
                className="w-16 p-2 border border-white bg-transparent text-white text-center font-bold focus:outline-none"
                value={positionValue}
                onChange={(e) => setPositionValue(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handlePositionChange();
                  }
                }}
              />
              <button
                type="button"
                className="p-2 flex-1 text-center bg-white border-white border text-primary "
                onClick={handlePositionChange}
              >
                <Icon name="check" />
              </button>
            </div>
            {props.wishListLine.FundedQuantityAsDelivery === 0 && !obligation && (
              <button
                type="button"
                className="bg-red-500 text-white font-bold p-3 focus:outline-none"
                onClick={() => setShowRemoveDialog(true)}
              >
                <FormattedMessage id="generic.delete" />
              </button>
            )}
            <button
              type="button"
              className="bg-red-500 text-white font-bold p-3 focus:outline-none"
              onClick={() => toggleWishListItemVisibility(props.wishListLine)}
            >
              <FormattedMessage
                id={
                  props.wishListLine.IsHidden
                    ? 'home-page.wish-list.wishlist-item.show-to-family'
                    : 'home-page.wish-list.wishlist-item.hide-from-family'
                }
              />
            </button>
            <button
              type="button"
              className=" bg-transparent border border-white text-white font-bold p-3 focus:outline-none"
              onClick={() =>
                history.push(
                  `/product/${props.wishListLine.Product.ID}/${props.wishListLine.Product.Properties.slug}`,
                )
              }
            >
              <FormattedMessage id="home-page.wish-list.wishlist-item.see" />
            </button>
          </div>
        )}
      </div>
      {showRemoveDialog && (
        <ConfirmRemoveWishListItemDialog
          closeHandler={() => setShowRemoveDialog(false)}
          confirmHandler={() => removeWishListItem(props.wishListLine)}
        />
      )}
    </>
  );
}
