import useGetCurrentUser from 'hooks/useGetCurrentUser';
import useLogin from 'components/login/useLogin';
import { FormattedMessage } from 'react-intl';

export default function HeaderLogout() {
  const { currentUser } = useGetCurrentUser();
  const { logout } = useLogin();

  return (
    <>
      {currentUser && (
        <button
          type="button"
          onClick={logout}
          className="font-semibold hover:underline"
        >
          <FormattedMessage id="generic.logout" />
        </button>
      )}
    </>
  );
}
