/** @jsxImportSource @emotion/react */
import useGetCurrentUser from 'hooks/useGetCurrentUser';
import useWishlist from 'hooks/useWishlist';
import { useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import tw from 'twin.macro';
import { useRecoilValue } from 'recoil';
import { state } from '@springtree/eva-sdk-react-recoil';
import HeaderWishListInformationButton from './HeaderWishListInformationButton';

export default function HeaderMenu() {
  const { currentUser } = useGetCurrentUser();
  const { activeWishlist, isParent, selectedUserWishList } = useWishlist();
  const intl = useIntl();
  const { isEmployee } = useRecoilValue(state.currentUser.currentUserTypeState);

  return (
    <>
      <div className="bg-primary-dark">
        <div className="container px-2 mx-auto flex ">
          {activeWishlist && (
            <MenuItem
              label={
                !isParent
                  ? intl.formatMessage(
                      { id: 'menu.wishlist-of' },
                      {
                        wishlistName:
                          activeWishlist?.Name ??
                          activeWishlist?.Customer?.FullName,
                      },
                    )
                  : intl.formatMessage({ id: 'menu.my-wishlist' })
              }
              link={`/geboortelijst/${activeWishlist.OrderID}/${activeWishlist.AccessToken}`}
            />
          )}
          {currentUser && !isParent && selectedUserWishList && (
            <MenuItem
              label={intl.formatMessage({ id: 'menu.my-wishlist' })}
              link={`/geboortelijst/${selectedUserWishList.OrderID}/${selectedUserWishList.AccessToken}`}
            />
          )}
          {currentUser && (
            <>
              <MenuItem
                label={intl.formatMessage({ id: 'menu.my-account' })}
                link="/profile"
              />
              {isEmployee && (
                <MenuItem
                  label={intl.formatMessage({ id: 'menu.search-wishlist' })}
                  link="/employee"
                />
              )}
            </>
          )}
          <div className="ml-auto">
            <HeaderWishListInformationButton />
          </div>
        </div>
      </div>
    </>
  );
}

function MenuItem(props: { link: string; label: string }) {
  const location = useLocation();

  return (
    <Link to={props.link}>
      <div
        className="p-2"
        css={[
          location.pathname.includes(props.link) && tw`bg-primary`,
          tw`text-white font-semibold text-sm`,
        ]}
      >
        {props.label}
      </div>
    </Link>
  );
}
