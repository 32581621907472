import { FormikValues } from 'formik';
import { AddressValues, InitialAddressValues } from '../address/AddressValues';

export interface RegisterValues extends FormikValues {
  firstName: string;
  lastName: string;
  wishlistName?: string;
  phoneNumber: string;
  email: string;
  password?: string;
  confirmPassword?: string;
  dueDate?: Date;
  requestedDeliveryDate?: Date;
  billingAddress?: AddressValues;
  hasShippingAddress: boolean;
  shippingAddress?: AddressValues;
}

export const InitialRegisterValues: RegisterValues = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  password: '',
  confirmPassword: '',
  requestedDeliveryDate: undefined,
  dueDate: undefined,
  billingAddress: InitialAddressValues,
  shippingAddress: InitialAddressValues,
  hasShippingAddress: false,
};
