import { hooks } from '@springtree/eva-sdk-react-recoil';
import { Core } from '@springtree/eva-services-core';
import useIsMounted from 'hooks/useIsMounted';
import { useEffect, useState } from 'react';
import { IOption } from '../ui/types/IOption';

const useCountryOptions = () => {
  const isMounted = useIsMounted();
  const [countryOptions, setCountryOptions] = useState<Array<IOption>>([]);

  const getCountries = hooks.useCallService({ service: Core.ListCountries });

  useEffect(() => {
    getCountries().then((response) => {
      if (response?.Result && isMounted) {
        setCountryOptions(
          response.Result.map((x) => ({ label: x.Name, value: x.ID })),
        );
      }
    });
  }, [getCountries, isMounted]);

  return countryOptions;
};

export default useCountryOptions;
