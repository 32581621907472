import { helpers, state } from '@springtree/eva-sdk-react-recoil';
import { Core } from '@springtree/eva-services-core';
import Button from 'components/shared/ui/Button';
import FormikInput from 'components/shared/ui/FormikInput';
import Notification from 'components/shared/ui/Notification';
import Text from 'components/shared/ui/Text';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useRecoilValue } from 'recoil';
import useIsMounted from 'hooks/useIsMounted';
import { ForgotPasswordValues } from './ForgotPasswordTypes';
import useForgotPasswordValidationSchema from './useForgotPasswordValidationSchema';

export default function ForgotPasswordPage() {
  const isMounted = useIsMounted();
  const forgotPasswordValidationSchema = useForgotPasswordValidationSchema();
  const evaEndpointUrl = useRecoilValue(state.core.evaEndpointUrlState);
  const [showInstructions, setShowInstructions] = useState(false);

  const forgotPassword = async (values: ForgotPasswordValues) => {
    setShowInstructions(false);

    if (evaEndpointUrl && values.email) {
      const evaEndpoint = await helpers.getEvaEndpoint(evaEndpointUrl);
      await evaEndpoint.callService(Core.RequestPasswordResetToken, {
        EmailAddress: values.email,
      });

      if (isMounted) {
        setShowInstructions(true);
      }
    }
  };

  return (
    <div className="grid grid-cols-4">
      <div className="col-start-2 col-span-2 py-8">
        <Text type="h2" className="mb-4">
          <FormattedMessage id="forgot-password-page.title" />
        </Text>
        <Text className="mb-4">
          <FormattedMessage id="forgot-password-page.description" />
        </Text>
        <Formik<ForgotPasswordValues>
          initialValues={{ email: '' }}
          onSubmit={forgotPassword}
          validationSchema={forgotPasswordValidationSchema}
        >
          {({ isValid, dirty }) => (
            <Form>
              <FormikInput
                name="email"
                labelId="generic.email-address"
                type="text"
              />
              <Button type="submit" disabled={!isValid || !dirty}>
                <FormattedMessage id="forgot-password-page.action" />
              </Button>
            </Form>
          )}
        </Formik>
        {showInstructions && (
          <Notification
            className="mt-4"
            type="success"
            textId="forgot-password-page.success"
          />
        )}
      </div>
    </div>
  );
}
