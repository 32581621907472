import useAvailabilityIndication from 'hooks/useAvailabilityIndication';
import useProductAvailability from 'hooks/useProductAvailability';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { ProductAvailabilityDetailsState } from './ProductDetailsState';

const useGetProductAvailabilityDetails = (productId?: number) => {
  const {
    productAvailability,
    getProductAvailability,
  } = useProductAvailability();
  const {
    availabilityIndication,
    getAvailabilityIndication,
  } = useAvailabilityIndication();

  useEffect(() => {
    if (productId) {
      getAvailabilityIndication([
        { ProductID: productId, QuantityRequested: 1 },
      ]);
      getProductAvailability([{ ID: productId, QuantityRequested: 1 }]);
    }
  }, [getAvailabilityIndication, getProductAvailability, productId]);

  const setProductAvailabilityDetails = useSetRecoilState(
    ProductAvailabilityDetailsState,
  );

  useEffect(() => {
    setProductAvailabilityDetails((current) => ({
      ...current,
      productAvailability,
    }));
  }, [productAvailability, setProductAvailabilityDetails]);

  useEffect(() => {
    setProductAvailabilityDetails((current) => ({
      ...current,
      availabilityIndication,
    }));
  }, [availabilityIndication, setProductAvailabilityDetails]);
};

export default useGetProductAvailabilityDetails;
