import { useCallback } from 'react';
import useWishList from 'hooks/useWishlist';
import WishListFamilyInformationDialog from 'components/home/WishListFamilyInformationDialog';
import WishListParentInformationDialog from 'components/home/WishListParentInformationDialog';
import { FormattedMessage } from 'react-intl';
import { useSetRecoilState } from 'recoil';
import {
  ShowParentInformationDialogState,
  ShowFamilyInformationDialogState,
} from 'components/home/wish-list/WishListState';
import { useLocation } from 'react-router-dom';

export default function HeaderWishListInformationButton() {
  const { isParent, activeWishlist } = useWishList();
  const setShowParentInformationDialog = useSetRecoilState(
    ShowParentInformationDialogState,
  );
  const setShowFamilyInformationDialog = useSetRecoilState(
    ShowFamilyInformationDialogState,
  );
  const openInformationDialog = useCallback(() => {
    if (isParent) {
      setShowParentInformationDialog(true);
    } else {
      setShowFamilyInformationDialog(true);
    }
  }, [
    isParent,
    setShowFamilyInformationDialog,
    setShowParentInformationDialog,
  ]);

  const location = useLocation();

  return (
    <>
      {activeWishlist && location.pathname.includes('geboortelijst') && (
        <>
          <button
            className="p-1 mt-px focus:outline-none"
            type="button"
            onClick={openInformationDialog}
          >
            <span className="text-white font-semibold text-sm">
              <FormattedMessage id="menu.how-it-works" />
            </span>
          </button>
          {isParent ? (
            <WishListParentInformationDialog />
          ) : (
            <WishListFamilyInformationDialog />
          )}
        </>
      )}
    </>
  );
}
