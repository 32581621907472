/** @jsxImportSource @emotion/react */
import { Field, FieldProps } from 'formik';
import React from 'react';
import Select from './Select';
import { IOption } from './types/IOption';

interface IFormikSelectProps {
  name: string;
  labelId?: string;
  options: Array<IOption>;
  fullWidth?: boolean;
  className?: string;
  hideErrorMessage?: boolean;
}

export default function FormikSelect(props: IFormikSelectProps) {
  return (
    <Field name={props.name}>
      {({ field, meta }: FieldProps) => (
        <Select
          {...field}
          labelId={props.labelId}
          error={meta.touched ? meta.error : undefined}
          options={props.options}
          fullWidth={props.fullWidth}
          className={props.className}
          hideErrorMessage={props.hideErrorMessage}
        />
      )}
    </Field>
  );
}
