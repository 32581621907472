/** @jsxImportSource @emotion/react */

import tw from 'twin.macro';
import useProductImage from '../product/useProductImage';

export default function OrderLineProductImage(props: {
  productId: number;
  className?: string;
}) {
  const productImageUrl = useProductImage(props.productId, 64, 64);

  return (
    <img
      css={tw`w-16 h-16 object-cover block`}
      src={productImageUrl}
      alt=""
      className={props.className}
    />
  );
}
