import Checkbox from 'components/shared/ui/Checkbox';
import React from 'react';

interface IFilterItemProps {
  label: string;
  numberOfProducts: number;
  selected: boolean;
  toggleSelected: () => void;
}

export default function FilterItem(props: IFilterItemProps) {
  return (
    <div className="flex items-start">
      <Checkbox
        className="mt-1"
        fullWidth={false}
        value={props.selected}
        onChange={() => props.toggleSelected()}
      />
      <span className="ml-2 pb-2 text-sm">
        {props.label}
        <span className="text-gray-dark"> ({props.numberOfProducts})</span>
      </span>
    </div>
  );
}
