import { useCallback } from 'react';
import { IUserExpiredCallbackParameters } from '@springtree/eva-sdk-react-recoil';

export default function useUserExpired() {
  const userExpired = useCallback((params: IUserExpiredCallbackParameters) => {
    if (params.currentToken === params.expiredToken) {
      params.setUserTokenState('');
    }
  }, []);

  return {
    userExpired,
  };
}
