import React from 'react';
import { ReactComponent as CalendarIcon } from 'assets/images/calendar-icon.svg';

interface ICustomDatePickerInputProps {
  value?: string;
  onClick?: () => void;
}

export default React.forwardRef(
  (
    props: ICustomDatePickerInputProps,
    ref: React.ForwardedRef<HTMLButtonElement>,
  ) => (
    <div className="w-full flex mt-2">
      <input
        type="text"
        className="bg-gray border border-gray mr-2 p-2 text-sm font-light flex-grow focus:border-blue"
        value={props.value}
        readOnly
      />
      <button
        type="button"
        className="w-10 rounded border border-gray p-2"
        ref={ref}
        onClick={props.onClick}
      >
        <CalendarIcon width="24px" height="24px" />
      </button>
    </div>
  ),
);
