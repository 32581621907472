/** @jsxImportSource @emotion/react */
import { useHistory } from 'react-router-dom';
import SuspenseFallback from 'eva/SuspenseFallback';
import useWishList from 'hooks/useWishlist';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { FormattedMessage } from 'react-intl';
import Text from 'components/shared/ui/Text';
import tw, { css } from 'twin.macro';
import OrderCustomerDetails from './order/OrderCustomerDetails';
import OrderDeliveryMethod from './order/OrderDeliveryMethod';
import OrderDeliveryWeek from './order/OrderDeliveryWeek';
import OrderDetails from './order/OrderDetails';
import OrderFamilyMessage from './order/OrderFamilyMessage';
import OrderPayment from './order/OrderPayment';
import OrderRemark from './order/OrderRemark';
import OrderTotals from './order/OrderTotals';
import { CheckoutAmountsState } from './state/CheckoutAmount';
import { CheckoutOrderState } from './state/CheckoutOrder';
import { SelectedDeliveryMethodState } from './state/SelectedDeliveryMethod';

export default function CheckoutOverviewPage() {
  const history = useHistory();
  const checkoutOrder = useRecoilValue(CheckoutOrderState);
  const checkoutAmounts = useRecoilValue(CheckoutAmountsState);
  const { isParent } = useWishList();
  const selectedDeliveryMethod = useRecoilValue(SelectedDeliveryMethodState);

  useEffect(() => {
    if (checkoutOrder && checkoutAmounts && checkoutAmounts.OpenAmountIsZero) {
      history.push('/checkout/complete');
    }
  }, [checkoutAmounts, checkoutOrder, history]);

  return (
    <div className="container mx-auto p-6 lg:p-10">
      <Text type="h1" className="font-bold mb-2 sm:mb-4">
        <FormattedMessage id="checkout-page.title" />
      </Text>
      <SuspenseFallback>
        {checkoutOrder && (
          <>
            <div className="bg-white p-6">
              <OrderDetails />
              <div
                css={[
                  css`
                    @media (min-width: 768px) {
                      grid-template-rows: repeat(2, auto);
                    }
                  `,
                  tw`grid grid-cols-1 lg:grid-cols-3 gap-4 mt-8`,
                ]}
              >
                <div className="lg:grid-row-1 lg:col-span-2">
                  <OrderRemark />
                </div>
                <div className="lg:col-start-3 lg:row-span-full">
                  <OrderTotals />
                </div>
                {!isParent && (
                  <div className="lg:grid-row-1 lg:col-span-2">
                    <OrderFamilyMessage />
                  </div>
                )}
              </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mt-6">
              <OrderCustomerDetails />
              <div className="flex flex-col gap-6 bg-white p-4">
                <OrderDeliveryMethod />
                {selectedDeliveryMethod?.value !== 'pickup' && (
                  <OrderDeliveryWeek />
                )}
              </div>
              <OrderPayment />
            </div>
          </>
        )}
      </SuspenseFallback>
    </div>
  );
}
