import ReactSlider from 'react-slider';
import { useEffect, useState, useCallback } from 'react';
import Price from './Price';

interface ISliderProps {
  startValue: number;
  endValue: number;
  selectedValue: number;
  onAfterChange: (value: number) => void;
}

export default function Slider(props: ISliderProps) {
  const [localSelectedValue, setLocalSelectedValue] = useState(
    props.selectedValue,
  );

  useEffect(() => setLocalSelectedValue(props.selectedValue), [
    props.selectedValue,
  ]);

  const handleChange = useCallback(
    (value: number) => {
      const castedValue = value as number;
      setLocalSelectedValue(castedValue);
      props.onAfterChange(castedValue);
    },
    [props],
  );

  return (
    <>
      <div className="flex items-center p-4 bg-gray-light">
        <span className="text-sm mr-2">
          <Price alwaysShowDecimals value={props.startValue} />
        </span>
        <ReactSlider
          step={1}
          className="flex-1 h-0.5"
          value={localSelectedValue}
          min={props.startValue}
          max={props.endValue}
          renderTrack={(trackProps) => (
            <div {...trackProps} className="h-full bg-gray" />
          )}
          renderThumb={(thumbProps) => (
            <div
              {...thumbProps}
              className="bg-primary-dark h-6 w-6 relative -top-3 rounded-full outline-none cursor-pointer"
            />
          )}
          onChange={handleChange}
        />
        <span className="text-sm ml-2">
          <Price alwaysShowDecimals value={props.endValue} />
        </span>
      </div>
    </>
  );
}
