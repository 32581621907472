import { state } from '@springtree/eva-sdk-react-recoil';
import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  ShoppingCartDetailsState,
  ShoppingCartAmountsState,
} from 'state/CartState';
import { useShoppingCartModify } from 'eva/EvaHooks';
import { Core } from '@springtree/eva-services-core';
import { sum, uniq } from 'lodash';
import useCallService from '@springtree/eva-sdk-react-recoil/lib/hooks/use-call-service';
import useWishList from './useWishlist';

const useFetchShoppingCart = () => {
  const shoppingCartResponse = useRecoilValue(
    state.checkout.shoppingCartState.response,
  );
  const setShoppingCartRequest = useSetRecoilState(
    state.checkout.shoppingCartState.request,
  );

  const setShoppingCartDetails = useSetRecoilState(ShoppingCartDetailsState);
  const setShoppingCartAmounts = useSetRecoilState(ShoppingCartAmountsState);
  useRecoilValue(state.checkout.shoppingCartState.storage);

  useEffect(() => {
    setShoppingCartDetails(shoppingCartResponse?.ShoppingCart);
    setShoppingCartAmounts(shoppingCartResponse?.Amounts);
  }, [setShoppingCartAmounts, setShoppingCartDetails, shoppingCartResponse]);

  const { activeWishlist, isParent } = useWishList();

  const modifyQuantityOrdered = useShoppingCartModify({
    service: Core.ModifyQuantityOrdered,
  });
  const cancelOrder = useShoppingCartModify({
    service: Core.CancelOrder,
  });

  const createOrder = useCallService({ service: Core.CreateOrder });

  useEffect(() => {
    if (!shoppingCartResponse) {
      createOrder({
        Type: 0,
      }).then((response) => {
        if (response) {
          setShoppingCartRequest((current) => ({
            ...current,
            OrderID: response.OrderID,
          }));
        }
      });
    }
  }, [createOrder, setShoppingCartRequest, shoppingCartResponse]);

  useEffect(() => {
    if (shoppingCartResponse?.ShoppingCart && activeWishlist && !isParent) {
      const orderLinesToModify: Array<{
        id: number;
        quantityToRemove: number;
      }> = [];
      const orderLinesToRemove: Array<number> = [];
      uniq(
        shoppingCartResponse.ShoppingCart.Lines.filter(
          (x) => x.Product?.ID,
        ).map((x) => x.Product?.ID),
      ).forEach((productId) => {
        const openQuantityOnList = sum(
          activeWishlist.Lines.filter((x) => x.Product.ID === productId).map(
            (x) => x.OpenQuantity,
          ),
        );
        const quantityInCart = sum(
          shoppingCartResponse.ShoppingCart.Lines.filter(
            (x) => x.Product?.ID === productId,
          ).map((x) => x.TotalQuantityToShip),
        );

        if (quantityInCart > openQuantityOnList) {
          let quantityToRemove = quantityInCart - openQuantityOnList;
          const orderLinesWithProduct = shoppingCartResponse.ShoppingCart.Lines.filter(
            (x) => x.Product?.ID === productId,
          );

          while (quantityToRemove > 0) {
            if (quantityToRemove >= orderLinesWithProduct[0].QuantityOpen) {
              orderLinesToRemove.push(orderLinesWithProduct[0].ID);
            } else {
              orderLinesToModify.push({
                id: orderLinesWithProduct[0].ID,
                quantityToRemove: Math.min(
                  quantityToRemove,
                  orderLinesWithProduct[0].QuantityOpen,
                ),
              });
            }
            quantityToRemove -= orderLinesWithProduct[0].QuantityOpen;
            orderLinesWithProduct.shift();
          }
        }
      });
      orderLinesToModify.forEach(({ id, quantityToRemove }) => {
        const line = shoppingCartResponse.ShoppingCart.Lines.find(
          (x) => x.ID === id,
        );
        if (line) {
          modifyQuantityOrdered({
            payload: {
              OrderLineID: id,
              NewQuantityOrdered: line.QuantityOpen - quantityToRemove,
            },
          });
        }
      });
      if (orderLinesToRemove.length) {
        cancelOrder({
          payload: {
            OrderID: shoppingCartResponse.ShoppingCart.ID,
            OrderLines: orderLinesToRemove.map((x) => ({ OrderLineID: x })),
          },
        });
      }
    }
  }, [
    activeWishlist,
    cancelOrder,
    isParent,
    modifyQuantityOrdered,
    shoppingCartResponse?.ShoppingCart,
  ]);
};

export default useFetchShoppingCart;
