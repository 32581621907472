import { useCallback } from 'react';
import { AddressValues } from 'components/shared/address/AddressValues';
import useEvaCheckoutService from './useEvaCheckoutServices';

const useUpdateOrderAddress = () => {
  const {
    evaUpdateOrderBillingAddress,
    evaUpdateOrderShippingAddress,
  } = useEvaCheckoutService();
  const mapAddress = useCallback((address: AddressValues) => {
    let { houseNumber } = address;
    if (address.addressAddition) {
      houseNumber = `${address.houseNumber} ${address.addressAddition}`;
    }

    const mappedAddress: EVA.Core.AddressDataDto = {
      Street: address.street,
      HouseNumber: houseNumber,
      ZipCode: address.zipcode,
      CountryID: address.countryId,
      City: address.city,
    };

    return mappedAddress;
  }, []);

  const updateOrderAddressesByAddressBookID = useCallback(
    async (orderId: number, billingId: number, shippingId: number) => {
      if (billingId) {
        await evaUpdateOrderBillingAddress({
          OrderID: orderId,
          // Address: mapAddress(values.billingAddress),
          AddressBookID: billingId,
        });
      }

      if (shippingId) {
        await evaUpdateOrderShippingAddress({
          OrderID: orderId,
          // Address: mapAddress(values.shippingAddress),
          AddressBookID: shippingId,
        });
      } else if (billingId) {
        await evaUpdateOrderShippingAddress({
          OrderID: orderId,
          // Address: mapAddress(values.billingAddress),
          AddressBookID: billingId,
        });
      }
    },
    [evaUpdateOrderBillingAddress, evaUpdateOrderShippingAddress],
  );

  return {
    updateOrderAddressesByAddressBookID,
    mapAddress,
  };
};

export default useUpdateOrderAddress;
