/** @jsxImportSource @emotion/react */
import ReactSlider from 'react-slider';
import SliderArrow from 'assets/images/slider arrow.png';
import tw, { css } from 'twin.macro';
import { useEffect, useState, useCallback } from 'react';
import Price from './Price';

interface IRangeSliderProps {
  startValue: number;
  endValue: number;
  selectedStartValue: number;
  selectedEndValue: number;
  onAfterChange: (startValue: number, endValue: number) => void;
}

export default function RangeSlider(props: IRangeSliderProps) {
  const [localSelectedStartValue, setLocalSelectedStartValue] = useState(
    props.selectedStartValue,
  );
  const [localSelectedEndValue, setLocalSelectedEndValue] = useState<number>(
    props.selectedEndValue,
  );

  useEffect(() => {
    setLocalSelectedEndValue(props.selectedEndValue);
  }, [props.selectedEndValue]);

  useEffect(() => {
    setLocalSelectedStartValue(props.selectedStartValue);
  }, [props.selectedStartValue]);

  const onChange = useCallback((values: number[]) => {
    const castedValues = values as [number, number];
    setLocalSelectedStartValue(castedValues[0]);
    setLocalSelectedEndValue(castedValues[1]);
  }, []);

  return (
    <>
      <ReactSlider
        step={1}
        className="w-full h-4"
        value={[localSelectedStartValue, localSelectedEndValue]}
        min={props.startValue}
        max={props.endValue}
        renderTrack={(trackProps, { index }) => (
          <div
            {...trackProps}
            css={[
              tw`h-full `,
              index === 1 ? tw`bg-primary rounded-full` : tw`bg-gray`,
            ]}
          />
        )}
        renderThumb={(thumbProps) => (
          <div
            {...thumbProps}
            css={[
              SliderArrowBackgroundStyle,
              tw`bg-no-repeat h-6 w-4 -top-px outline-none `,
            ]}
          />
        )}
        onAfterChange={(values) => {
          const castedValues = values as [number, number];
          props.onAfterChange(castedValues[0], castedValues[1]);
        }}
        onChange={onChange}
      />
      <div className="flex justify-between p-2 mb-2">
        <span>
          <Price numberOfDecimals={0} value={localSelectedStartValue} />
        </span>
        <span>
          <Price numberOfDecimals={0} value={localSelectedEndValue} />
        </span>
      </div>
    </>
  );
}

const SliderArrowBackgroundStyle = css`
  background-image: url(${SliderArrow});
`;
