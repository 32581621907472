import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { ProductAvailabilityDetailsState } from './ProductDetailsState';

const useIsProductSellable = () => {
  const { availabilityIndication } = useRecoilValue(
    ProductAvailabilityDetailsState,
  );

  return useMemo(
    () => ({
      isSellable:
        (availabilityIndication?.Products[0]?.ProductStatus !== 32 &&
          availabilityIndication?.Products[0]?.ProductStatus !== 34) ||
        availabilityIndication?.Products[0]?.StoreAvailabilityCount > 0 ||
        (availabilityIndication?.Products[0]?.Delivery?.QuantityAvailable ??
          0) > 0,
      canOnlyBeReserved:
        (availabilityIndication?.Products[0]?.ProductStatus === 32 ||
          availabilityIndication?.Products[0]?.ProductStatus === 34) &&
        availabilityIndication?.Products[0]?.StoreAvailabilityCount > 0 &&
        (availabilityIndication?.Products[0]?.Delivery?.QuantityAvailable ??
          0) <= 0,
    }),
    [availabilityIndication?.Products],
  );
};

export default useIsProductSellable;
