import useAvailabilityIndication from 'hooks/useAvailabilityIndication';
import useWishlist from 'hooks/useWishlist';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { ActiveWishListItemAvailabilityState } from './WishListState';

const useGetActiveWishListItemAvailability = () => {
  const { activeWishlist } = useWishlist();
  const {
    availabilityIndication,
    getAvailabilityIndication,
  } = useAvailabilityIndication();
  const setActiveWishListItemAvailability = useSetRecoilState(
    ActiveWishListItemAvailabilityState,
  );

  useEffect(() => {
    if (activeWishlist?.Lines) {
      getAvailabilityIndication(
        activeWishlist.Lines.map((x) => ({
          ProductID: x.Product.ID,
          QuantityRequested: x.Quantity,
        })),
      );
    }
  }, [activeWishlist?.Lines, getAvailabilityIndication]);

  useEffect(() => {
    setActiveWishListItemAvailability(availabilityIndication);
  }, [availabilityIndication, setActiveWishListItemAvailability]);
};

export default useGetActiveWishListItemAvailability;
