import React, { useCallback, useMemo } from 'react';
import useWishList from 'hooks/useWishlist';
import { useHistory } from 'react-router-dom';
import { IOption } from 'components/shared/ui/types/IOption';
import Select from 'components/shared/ui/Select';
import { FormattedMessage } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { SelectedCustomerWishListsState } from 'state/EmployeeState';
import useClearShoppingCart from 'hooks/useClearShoppingCart';

export default function WishListSwitcher() {
  const {
    userWishlists,
    selectedUserWishList,
    activeWishlist,
    isParent,
  } = useWishList();
  const selectedCustomerWishLists = useRecoilValue(
    SelectedCustomerWishListsState,
  );
  const history = useHistory();
  const clearShoppingCart = useClearShoppingCart();

  const wishListsOptions: Array<IOption> = useMemo(
    () =>
      (isParent ? userWishlists : selectedCustomerWishLists)?.map((x) => ({
        label: x.Name,
        value: x.OrderID,
      })) ?? [],
    [isParent, selectedCustomerWishLists, userWishlists],
  );

  const currentValue = useMemo(
    () => (isParent ? selectedUserWishList?.OrderID : activeWishlist?.OrderID),
    [activeWishlist?.OrderID, isParent, selectedUserWishList?.OrderID],
  );

  const handleChange = useCallback(
    (orderId: number) => {
      const selectedWishList = (isParent
        ? userWishlists
        : selectedCustomerWishLists
      )?.find((x) => x.OrderID === orderId);
      if (selectedWishList) {
        history.push(
          `/geboortelijst/${selectedWishList.OrderID}/${selectedWishList.AccessToken}`,
        );
        clearShoppingCart();
      }
    },
    [
      clearShoppingCart,
      history,
      isParent,
      selectedCustomerWishLists,
      userWishlists,
    ],
  );

  return (
    <>
      {wishListsOptions && wishListsOptions.length > 1 && (
        <div className="mt-8 flex items-center">
          <span className="mr-2">
            <FormattedMessage id="home-page.wish-list.wishlist-switcher.label" />
          </span>
          <Select
            value={currentValue}
            options={wishListsOptions}
            onChange={(e) => {
              const selectedWishListOrderID = parseInt(e.target.value, 10);
              handleChange(selectedWishListOrderID);
            }}
          />
        </div>
      )}
    </>
  );
}
