import buildServiceState from '@springtree/eva-sdk-react-recoil/lib/builders/build-service-state';
import { Core } from '@springtree/eva-services-core';

export const pickupStores = buildServiceState({
  service: Core.ListOrganizationUnits,
  allowEmptyRequest: true,
  key: 'Checkout:PickupStores1',
  beforeRequest: ({ req }) => {
    const newRequest: EVA.Core.ListOrganizationUnits = {
      ...req,
      PageConfig: {
        Filter: {
          CountryIDs: ['BE'],
          TypeID: 8,
        },
      },
    };

    return newRequest;
  },
});

export default pickupStores;
