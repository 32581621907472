import Button from 'components/shared/ui/Button';
import FormikSelect from 'components/shared/ui/FormikSelect';
import Select from 'components/shared/ui/Select';
import Separator from 'components/shared/ui/Separator';
import { IOption } from 'components/shared/ui/types/IOption';
import SuspenseFallback from 'eva/SuspenseFallback';
import { Formik, Form } from 'formik';
import { range } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import useWishlist from 'hooks/useWishlist';
import { useHistory } from 'react-router-dom';
import Dialog from 'components/shared/ui/Dialog';
import { NotificationType } from 'components/shared/ui/types/NotificationType';
import ConfigurableProductSelection from './ConfigurableProductSelection';
import { SelectedConfiguredProductIdState } from './ProductDetailsState';
import useRemoveItemFromActiveWishList from './useRemoveWishListItem';
import ConfirmRemoveWishListItemDialog from '../shared/product/ConfirmRemoveWishListItemDialog';
import useAddProductToShoppingCart from './useAddProductToShoppingCart';
import useAddWishListProductToShoppingCart from './useAddWishListProductToShoppingCart';
import ProductPrice from '../shared/product/ProductPrice';
import useIsProductSellable from './useIsProductSellable';
import useProductQuantityValidationSchema from './useProductQuantityValidationSchema';
import useNotifications from '../../hooks/useNotifications';

interface IProductDetailsParentActionsProps {
  isIncludedInActiveWishList: boolean;
  isConfigurable: boolean;
  isBundle: boolean;
  productId: number;
  productPrice: number;
}

export default function ProductDetailsParentActions(
  props: IProductDetailsParentActionsProps,
) {
  const [showRemoveDialog, setShowRemoveDialog] = useState(false);
  const selectedConfiguredProductId = useRecoilValue(
    SelectedConfiguredProductIdState,
  );
  const intl = useIntl();
  const placeHolderTranslation = useMemo(
    () => intl.formatMessage({ id: 'product-details-page.number-of-products' }),
    [intl],
  );
  const history = useHistory();
  const productQuantityValidationSchema = useProductQuantityValidationSchema();
  const { addNotification } = useNotifications();

  const productQuantityOptions: Array<IOption> = useMemo(
    () => [
      {
        label: placeHolderTranslation,
        value: props.isIncludedInActiveWishList ? '' : 0,
        hidden: true,
      },
      ...range(1, 16).map((x) => ({ value: x, label: `+${x}` })),
    ],
    [placeHolderTranslation, props.isIncludedInActiveWishList],
  );

  const {
    addProductToActiveWishList,
    activeWishlist,
    refreshWishList,
  } = useWishlist();

  const addWishListProductToShoppingCart = useAddWishListProductToShoppingCart();
  const addProductToShoppingCart = useAddProductToShoppingCart();
  const removeItemFromActiveWishList = useRemoveItemFromActiveWishList();

  const { isSellable, canOnlyBeReserved } = useIsProductSellable();

  const buyProduct = useCallback(() => {
    const productIdToBuy = props.isConfigurable
      ? selectedConfiguredProductId
      : props.productId;
    if (productIdToBuy) {
      if (props.isIncludedInActiveWishList) {
        addWishListProductToShoppingCart({
          productId: productIdToBuy,
          quantity: 1,
          lineActionType: canOnlyBeReserved ? 1 : 4,
        });
      } else {
        addProductToShoppingCart({
          productId: productIdToBuy,
          quantity: 1,
          lineActionType: canOnlyBeReserved ? 1 : 4,
        });
      }
      history.push('/cart');
    }
  }, [
    addProductToShoppingCart,
    addWishListProductToShoppingCart,
    canOnlyBeReserved,
    history,
    props.isConfigurable,
    props.isIncludedInActiveWishList,
    props.productId,
    selectedConfiguredProductId,
  ]);

  const handleAddProductToWishList = useCallback(
    async (quantity: number) => {
      const productId = props.isConfigurable
        ? selectedConfiguredProductId
        : props.productId;
      if (productId) {
        const result = await addProductToActiveWishList(
          productId,
          quantity === 0 ? 1 : quantity,
        );
        if (result) {
          if (activeWishlist) {
            history.push(
              `/geboortelijst/${activeWishlist.OrderID}/${activeWishlist.AccessToken}`,
            );
            refreshWishList();
          }
        } else {
          addNotification({
            message: intl.formatMessage({
              id: 'product-details-page.add-to-wishlist-error',
            }),
            type: NotificationType.Error,
          });
        }
      }
    },
    [
      activeWishlist,
      addNotification,
      addProductToActiveWishList,
      history,
      intl,
      props.isConfigurable,
      props.productId,
      refreshWishList,
      selectedConfiguredProductId,
    ],
  );

  return props.isIncludedInActiveWishList ? (
    <>
      <div className="bg-white border-2 -mx-1 border-primary shadow-lg">
        <div className="p-6 text-2xl font-bold text-red-500 text-right">
          <ProductPrice
            productId={props.productId}
            displayPrice={props.productPrice}
            isBundle={props.isBundle}
          />
        </div>
        <Separator />
        <div className="bg-gray-light p-4 flex flex-col md:flex-row justify-between items-center">
          <div className="flex items-center mb-4 md:mb-0">
            {isSellable ? (
              <>
                <span className="text-sm mr-2">
                  <FormattedMessage id="product-details-page.add" />
                </span>
                <Select
                  options={productQuantityOptions}
                  value=""
                  onChange={(e) => {
                    handleAddProductToWishList(parseInt(e.target.value, 10));
                  }}
                />
                {props.isConfigurable && (
                  <SuspenseFallback>
                    <ConfigurableProductSelection disableSelect />
                  </SuspenseFallback>
                )}
              </>
            ) : (
              <FormattedMessage id="product-details-page.not-in-stock" />
            )}
          </div>
          <div className="flex items-center">
            <Button
              className="mx-2"
              underline
              color="red"
              onClick={() => setShowRemoveDialog(true)}
            >
              <FormattedMessage id="product-details-page.remove-from-list" />
            </Button>
            {isSellable && (
              <Button
                underline
                disabled={props.isConfigurable && !selectedConfiguredProductId}
                onClick={buyProduct}
              >
                <FormattedMessage id="product-details-page.order" />
              </Button>
            )}
          </div>
        </div>
      </div>
      {showRemoveDialog && (
        <ConfirmRemoveWishListItemDialog
          closeHandler={() => setShowRemoveDialog(false)}
          confirmHandler={() => removeItemFromActiveWishList(props.productId)}
        />
      )}
    </>
  ) : (
    <>
      <Formik<{ quantity: number }>
        initialValues={{ quantity: 0 }}
        onSubmit={({ quantity }) => {
          handleAddProductToWishList(quantity);
        }}
        validationSchema={productQuantityValidationSchema}
      >
        {({ errors }) => (
          <Form>
            <div className="bg-white border-2 -mx-1 p-6 border-primary shadow-lg">
              <div className="flex justify-between items-center mb-4">
                <span className="font-bold text-xl">
                  <FormattedMessage id="product-details-page.product-not-in-list" />
                </span>
                <div className="p-2 mr-3 my-auto">
                  <ProductPrice
                    productId={props.productId}
                    displayPrice={props.productPrice}
                    isBundle={props.isBundle}
                  />
                </div>
              </div>
              <div className="bg-gray-light p-4">
                <div className="flex flex-col md:flex-row justify-between lg:items-center">
                  <div className="flex items-center mb-4 md:mb-0">
                    {isSellable ? (
                      <>
                        <span className="text-sm mr-2">
                          <FormattedMessage id="product-details-page.add" />
                        </span>
                        <FormikSelect
                          name="quantity"
                          options={productQuantityOptions}
                          hideErrorMessage
                        />
                        {props.isConfigurable && (
                          <SuspenseFallback>
                            <ConfigurableProductSelection />
                          </SuspenseFallback>
                        )}
                      </>
                    ) : (
                      <>
                        {props.isConfigurable && (
                          <SuspenseFallback>
                            <ConfigurableProductSelection />
                          </SuspenseFallback>
                        )}
                        <FormattedMessage id="product-details-page.not-in-stock" />
                      </>
                    )}
                  </div>
                  {isSellable && (
                    <div className="flex items-center">
                      <Button
                        className="mr-2 lg:mx-2"
                        underline
                        type="submit"
                        disabled={
                          props.isConfigurable && !selectedConfiguredProductId
                        }
                      >
                        <FormattedMessage id="product-details-page.add-to-list" />
                      </Button>
                      <Button
                        underline
                        disabled={
                          props.isConfigurable && !selectedConfiguredProductId
                        }
                        onClick={buyProduct}
                      >
                        <FormattedMessage id="product-details-page.buy-directly" />
                      </Button>
                    </div>
                  )}
                </div>
                {!!errors.quantity && (
                  <div className="text-error mt-2 text-sm">
                    {errors.quantity}
                  </div>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
