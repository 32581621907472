import { hooks } from '@springtree/eva-sdk-react-recoil';
import { Core } from '@springtree/eva-services-core';
import { useCallback } from 'react';
import { ISearchResult } from './SearchBarTypes';

const useSearch = () => {
  const searchProducts = hooks.useCallService({ service: Core.SearchProducts });

  const getSearchResults = useCallback(
    (query: string): Promise<Array<ISearchResult>> => {
      if (query) {
        return searchProducts({
          Page: 1,
          PageSize: 7,
          Query: query,
          Sort: [{ FieldName: '_score', Direction: 1 }],
          IncludedFields: ['slug', 'display_value'],
          Filters: {
            logical_level: { Values: ['root'], IncludeMissing: true },
            product_types: {
              Values: [2048],
              Negation: true,
              ExactMatch: true,
            },
            publication: {
              Values: ['no-images'],
              Negation: true,
            },
          },
        }).then(
          (response) =>
            response?.Products?.map((x) => ({
              productId: x.product_id,
              productName: x.display_value,
              productSlug: x.slug,
            })) ?? [],
        );
      }
      return Promise.resolve([]);
    },
    [searchProducts],
  );

  return { getSearchResults };
};

export default useSearch;
