import { EvaStateContext } from 'eva/EvaComponents';
import { useCallback, useContext, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { helpers, state } from '@springtree/eva-sdk-react-recoil';
import { Core } from '@springtree/eva-services-core';
import { useHistory } from 'react-router-dom';
import useIsMounted from 'hooks/useIsMounted';
import useCallService from '@springtree/eva-sdk-react-recoil/lib/hooks/use-call-service';
import { find } from 'lodash';
import { ActiveWishListIdentifierState } from 'state/WishlistState';
import { LoginValues } from './LoginTypes';

export default function useLogin() {
  const isMounted = useIsMounted();
  const history = useHistory();
  const evaStateContext = useContext(EvaStateContext);
  const evaEndpointUrl = useRecoilValue(state.core.evaEndpointUrlState);
  const getUserWishLists = useCallService({
    service: Core.GetWishListsForUser,
  });
  const setActiveWishListIdentifier = useSetRecoilState(
    ActiveWishListIdentifierState,
  );

  const listOrganizationUnits = useCallService({
    service: Core.ListOrganizationUnits,
  });
  const [loginError, setLoginError] = useState<boolean>();

  const getWishlistOuId = useCallback(async () => {
    const response = await listOrganizationUnits({
      PageConfig: {
        Filter: {
          Name: 'Geboortelijst',
        },
      },
    });
    const ou = find(response?.Result.Page, { Name: 'Geboortelijst' });

    if (ou) {
      return ou.ID;
    }

    return undefined;
  }, [listOrganizationUnits]);

  const login = async (values: LoginValues) => {
    const evaEndpoint = await helpers.getEvaEndpoint(evaEndpointUrl);
    const wishlistOuID = await getWishlistOuId();
    const loginResponse = await evaEndpoint.callService(Core.Login, {
      Username: values.email,
      Password: values.password,
      OrganizationUnitID: wishlistOuID,
    });

    if (
      loginResponse &&
      loginResponse?.Authentication === 2 &&
      evaStateContext.setUserTokenState
    ) {
      // eslint-disable-next-line no-bitwise
      const isEmployee = (loginResponse.User.Type & 1) !== 0;
      if (!isEmployee) {
        const wishlistsResponse = await getUserWishLists(
          { ID: loginResponse.User.ID, ShowClosedOrders: false },
          { authenticationToken: loginResponse.AuthenticationToken },
        );
        if (wishlistsResponse && wishlistsResponse.Result.length) {
          const {
            AccessToken: accessToken,
            OrderID: orderId,
          } = wishlistsResponse.Result[0];
          setActiveWishListIdentifier({
            accessToken,
            orderId,
          });
          localStorage.setItem('activeWishListOrderId', orderId.toString());
          localStorage.setItem('activeWishListAccessToken', accessToken);
          history.push(`/geboortelijst/${orderId}/${accessToken}`);
        }
        localStorage.removeItem('parentInformationModalShown');
      } else {
        history.push('/employee');
      }
      evaStateContext.setUserTokenState(loginResponse.AuthenticationToken);
    } else if (isMounted) {
      setLoginError(true);
    }
  };

  const logout = () => {
    if (evaStateContext.setUserTokenState) {
      localStorage.removeItem('activeWishListOrderId');
      localStorage.removeItem('activeWishListAccessToken');
      evaStateContext.setUserTokenState('');
      history.push('/');
    }
  };

  return { login, loginError, logout };
}
