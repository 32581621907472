import WishListImage1 from 'assets/images/baby-1.jpg';
import WishListImage2 from 'assets/images/baby-2.jpg';
import WishListImage3 from 'assets/images/baby-3.jpg';
import WishListImage4 from 'assets/images/baby-4.jpg';
import WishListImage5 from 'assets/images/baby-5.jpg';
import WishListImage6 from 'assets/images/baby-6.jpg';
import WishListImage7 from 'assets/images/baby-7.jpg';
import WishListImage8 from 'assets/images/baby-8.jpg';
import IDealIcon from 'assets/images/ideal.svg';
import MasterCardIcon from 'assets/images/mastercard.svg';
import VisaIcon from 'assets/images/visa.svg';
import DefaultPaymentMethodIcon from 'assets/images/generic.svg';
import GiftCardIcon from 'assets/images/giftcard.svg';
import { toast } from 'react-toastify';
import { theme } from 'twin.macro';

const AppConfiguration = {
  dateFormat: 'dd-M-yyyy',
  wishListImages: [
    { imageId: 0, imageSource: WishListImage1 },
    { imageId: 1, imageSource: WishListImage2 },
    { imageId: 2, imageSource: WishListImage3 },
    { imageId: 3, imageSource: WishListImage4 },
    { imageId: 4, imageSource: WishListImage5 },
    { imageId: 5, imageSource: WishListImage6 },
    { imageId: 6, imageSource: WishListImage7 },
    { imageId: 7, imageSource: WishListImage8 },
  ],
  productProperties: [
    'brand_name',
    'omzetgroep',
    'display_value',
    'product_id',
    'slug',
    'original_price',
    'display_price',
    'custom_id',
    'afnameverplichting',
  ],
  disabledBuckarooPaymentMethods: ['BUCKAROO_PAYPEREMAIL', 'BUCKAROO_TRANSFER'],
  paymentMethodImages: {
    BUCKAROO_IDEAL: IDealIcon,
    BUCKAROO_MASTERCARD: MasterCardIcon,
    BUCKAROO_VISA: VisaIcon,
    USERCARD: GiftCardIcon,
    default: DefaultPaymentMethodIcon,
  },
  toastOptions: {
    position: toast.POSITION.TOP_RIGHT,
    hideProgressBar: true,
    autoClose: 3000,
    style: {
      color: theme`colors.white`,
      backgroundColor: theme`colors[primary-darker]`,
      cursor: 'default',
    },
    closeButton: false,
    closeOnClick: false,
    draggable: false,
  },
};

export default AppConfiguration;
