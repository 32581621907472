import Text from 'components/shared/ui/Text';
import { FormattedMessage } from 'react-intl';
import SuspenseFallback from 'eva/SuspenseFallback';
import { useHistory } from 'react-router-dom';
import useLogin from 'components/login/useLogin';
import Button from 'components/shared/ui/Button';
import OrdersTable from './OrdersTable';

export default function OrdersOverviewPage() {
  const history = useHistory();
  const { logout } = useLogin();

  return (
    <div className="container mx-auto p-6 lg:p-10">
      <div className="flex flex-col sm:flex-row sm:items-center mb-10">
        <Text type="h1" className="font-bold mb-2 sm:mb-4">
          <FormattedMessage id="orders-overview-page.title" />
        </Text>
        <Button
          underline
          className="sm:ml-auto sm:mb-0 sm:mr-4 mb-4"
          onClick={() => history.push('/profile')}
        >
          <FormattedMessage id="orders-overview-page.my-profile" />
        </Button>
        <Button variant="text" underline className="bg-white" onClick={logout}>
          <span className="text-black">
            <FormattedMessage id="generic.logout" />
          </span>
        </Button>
      </div>

      <SuspenseFallback>
        <OrdersTable />
      </SuspenseFallback>
    </div>
  );
}
