import { useEffect, useState } from 'react';
import { sum } from 'lodash';
import { useCallService } from 'eva/EvaHooks';
import { Core } from '@springtree/eva-services-core';
import Price from 'components/shared/ui/Price';
import { FormattedMessage } from 'react-intl';

interface IProductPriceProps {
  productId: number;
  isBundle: boolean;
  displayPrice: number;
}

export default function ProductPrice(props: IProductPriceProps) {
  const getBundleDetails = useCallService({
    service: Core.GetBundleProductDetails,
  });
  const [fromPrice, setFromPrice] = useState<number>();

  useEffect(() => {
    if (props.isBundle) {
      getBundleDetails({ BundleProductID: props.productId }).then((result) => {
        if (result) {
          setFromPrice(
            sum(
              result.Products.filter((x) => x.Type === 1).map(
                (x) => x.Pricing.UnitPriceInTax,
              ),
            ),
          );
        }
      });
    }
  }, [getBundleDetails, props.isBundle, props.productId]);

  return (
    <>
      {props.isBundle && fromPrice !== undefined ? (
        <div className="flex items-center justify-end font-bold text-red-500">
          <FormattedMessage id="generic.from" />
          <span className="mx-2">
            <Price value={fromPrice} />
          </span>
          <span className="text-primary-dark font-bold">
            <FormattedMessage id="generic.now" />{' '}
            <Price value={props.displayPrice} />
          </span>
        </div>
      ) : (
        <span className="text-red-500 font-bold">
          <Price value={props.displayPrice} />
        </span>
      )}
    </>
  );
}
