import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';

export default function BaseAppTitle() {
  const intl = useIntl();
  return (
    <Helmet>
      <title>{intl.formatMessage({ id: 'generic.app-title' })}</title>
    </Helmet>
  );
}
