import { hooks } from '@springtree/eva-sdk-react-recoil';
import { useCallback, useState } from 'react';
import { Core } from '@springtree/eva-services-core';
import useIsMounted from 'hooks/useIsMounted';

const useAddressAutocomplete = () => {
  const isMounted = useIsMounted();
  const [addressSuggestions, setAddressSuggestions] = useState<
    EVA.Core.AddressSuggestion[]
  >([]);
  const [address, setAddress] = useState<
    EVA.Core.AddressSuggestionDetails | undefined
  >();

  const autocompleteAddress = hooks.useCallService({
    service: Core.AutocompleteAddress,
    options: {
      onSuccess: (response) => {
        if (response?.Result && isMounted) {
          setAddressSuggestions(response?.Result);
        }
      },
      onError: () => {
        if (isMounted) {
          setAddressSuggestions([]);
        }
      },
    },
  });

  const getAddress = hooks.useCallService({
    service: Core.GetAutocompleteAddressByReference,
    options: {
      onSuccess: (response) => {
        if (response?.Result && isMounted) {
          setAddress(response?.Result);
        }
      },
      onError: () => {
        if (isMounted) {
          setAddress(undefined);
        }
      },
    },
  });

  const getAddressSuggestions = useCallback(
    (value?: string) => {
      if (value) {
        autocompleteAddress({ Query: value });
      } else if (isMounted) {
        setAddressSuggestions([]);
      }
    },
    [autocompleteAddress, isMounted],
  );

  const getAddressByReference = useCallback(
    (reference?: string) => {
      if (reference) {
        getAddress({
          Suggestion: { Reference: reference, ID: '', Description: '' },
        });
      } else if (isMounted) {
        setAddress(undefined);
      }
    },
    [getAddress, isMounted],
  );

  return {
    addressSuggestions,
    getAddressSuggestions,
    getAddressByReference,
    address,
  };
};

export default useAddressAutocomplete;
