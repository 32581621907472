import { FormattedMessage } from 'react-intl';

export default function Footer() {
  return (
    <footer className="bg-primary text-white text-sm">
      <div className="container mx-auto p-2">
        <span className="font-semibold">
          <FormattedMessage id="footer.about-us" />
        </span>
        <ul className="font-light ml-2 mt-2">
          <li className="mt-1">
            <a
              href="https://www.baby-dump.nl/contact/"
              target="_blank"
              rel="noreferrer"
            >
              <FormattedMessage id="footer.contact-us" />
            </a>
          </li>
          <li className="mt-1">
            <a
              href="https://www.baby-dump.be/algemene-voorwaarden/"
              target="_blank"
              rel="noreferrer"
            >
              <FormattedMessage id="footer.terms-and-conditions" />
            </a>
          </li>
          <li className="mt-1">
            <a
              href="https://www.baby-dump.nl/privacybeleid/"
              target="_blank"
              rel="noreferrer"
            >
              <FormattedMessage id="footer.privacy-policy" />
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
}
