import AppConfiguration from 'AppConfiguration';
import Button from 'components/shared/ui/Button';
import Icon from 'components/shared/ui/Icon';
import { format } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import useWishlist from 'hooks/useWishlist';
import Price from 'components/shared/ui/Price';
import useGetWishListCard from 'hooks/useGetWishListUserCard';
import { ShoppingCartDetailsState } from 'state/CartState';
import useCheckout from 'components/checkout/hooks/useCheckout';
import CartItem from './CartItem';
import useShoppingCartAvailability from './useShoppingCartAvailability';
import useRemoveShoppingCartItem from './useRemoveShoppingCartItem';
import useShoppingCartPromotionInfo from './useShoppingCartPromotionInfo';
import useClearShoppingCart from '../../hooks/useClearShoppingCart';

export default function CartPageItems() {
  const history = useHistory();
  const shoppingCart = useRecoilValue(ShoppingCartDetailsState);
  const { startCheckout } = useCheckout();
  const shoppingCartAvailability = useShoppingCartAvailability();
  const { activeWishlist } = useWishlist();
  const removeShoppingCartItem = useRemoveShoppingCartItem();
  const promotionInfo = useShoppingCartPromotionInfo();
  const clearShoppingCart = useClearShoppingCart();

  const { wishListCardBalance } = useGetWishListCard();

  return (
    <>
      {!shoppingCart || !shoppingCart?.Lines?.length ? (
        <div className="bg-white w-full p-10">
          <FormattedMessage id="cart-page.empty-cart-message" />
        </div>
      ) : (
        <div className="bg-white p-6 lg:p-10">
          {shoppingCart.Lines.filter((x) => x.Type !== 5).map((x) => (
            <CartItem
              hasPromotion={
                promotionInfo.find((y) => y.productId === x.ProductID)
                  ?.hasPromotion
              }
              key={x.ID}
              orderLine={x}
              cantRemove={x.Type !== 0}
              onRemove={() => removeShoppingCartItem(x.ID)}
              productAvailabilityInformation={shoppingCartAvailability.itemAvailability.find(
                (y) => y.productId === x.ProductID,
              )}
            />
          ))}
          <div className="flex flex-col md:flex-row md:justify-between md:items-end">
            <div className="flex flex-col md:flex-row mt-4 md:mt-0 order-last md:order-first">
              <Button variant="text" onClick={clearShoppingCart}>
                <FormattedMessage id="cart-page.clear-cart" />
              </Button>
              {activeWishlist && (
                <Button
                  variant="text"
                  onClick={() =>
                    history.push(
                      `/geboortelijst/${activeWishlist.OrderID}/${activeWishlist.AccessToken}`,
                    )
                  }
                >
                  <FormattedMessage id="cart-page.continue-shopping" />
                </Button>
              )}
            </div>
            <div className="flex flex-col">
              {shoppingCartAvailability.earliestDeliveryDate !== undefined && (
                <div className="text-left lg:text-center text-sm font-semibold text-gray-dark mb-4 ">
                  <FormattedMessage id="cart-page.expected-order-delivery-date" />
                  <span className="whitespace-nowrap">
                    {format(
                      shoppingCartAvailability.earliestDeliveryDate,
                      AppConfiguration.dateFormat,
                    )}
                  </span>
                </div>
              )}
              {/* <div className="flex justify-between mb-2">
                <span className="text-gray-dark">
                  <FormattedMessage id="cart-page.subtotal" />
                </span>
                <span className="font-semibold">
                  <Price value={shoppingCart.TotalAmountInTax} />
                </span>
              </div> */}
              <div className="flex justify-between mb-2">
                <span className="text-gray-dark">
                  <FormattedMessage id="cart-page.shipping-costs" />
                </span>
                <span className="font-semibold">
                  <Price
                    value={
                      shoppingCart.Lines.find((x) => x.Type === 5)
                        ?.TotalAmountInTax ?? 0
                    }
                  />
                </span>
              </div>
              <div className="flex justify-between mb-2">
                <span className="text-gray-dark">
                  <FormattedMessage id="cart-page.total" />
                </span>
                <span className="font-semibold">
                  <Price value={shoppingCart.TotalAmountInTax} />
                </span>
              </div>
              {wishListCardBalance !== undefined && wishListCardBalance > 0 && (
                <>
                  <div className="flex justify-between mb-2">
                    <span className="text-gray-dark mr-2">
                      <FormattedMessage id="cart-page.unspent-fundings" />
                    </span>
                    <span className="font-semibold">
                      <Price value={wishListCardBalance} />
                    </span>
                  </div>
                  <div className="flex justify-between mb-2">
                    <span className="text-gray-dark">
                      <FormattedMessage id="cart-page.amount-to-pay" />
                    </span>
                    <span className="font-semibold">
                      <Price
                        value={Math.max(
                          0,
                          shoppingCart.TotalAmountInTax - wishListCardBalance,
                        )}
                      />
                    </span>
                  </div>
                </>
              )}
              <Button
                className="ml-auto mt-4 uppercase"
                onClick={startCheckout}
              >
                <Icon name="arrow-thin-right" />
                <span className="m-2">
                  <FormattedMessage id="generic.checkout" />
                </span>
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
