import { hooks, state } from '@springtree/eva-sdk-react-recoil';
import HelmetTitle from 'components/shared/title/HelmetTitle';
import Text from 'components/shared/ui/Text';
import SuspenseFallback from 'eva/SuspenseFallback';
import { FormattedMessage, useIntl } from 'react-intl';
import SearchPageFiltersWrapper from './SearchPageFiltersWrapper';
import SearchPagePaginationContainer from './SearchPagePaginationContainer';
import SearchPageResults from './SearchPageResults';
import SearchPageResultsPlaceholder from './SearchPageResultsPlaceholder';
import SearchProducts from './SearchProducts';

export default function SearchPage() {
  const isLoading = hooks.useIsServiceLoading({
    serviceState: state.product.productListState,
  });
  const intl = useIntl();

  return (
    <>
      <HelmetTitle
        title={intl.formatMessage({ id: 'search-page.helmet-title' })}
      />
      <SuspenseFallback>
        <SearchProducts />
      </SuspenseFallback>
      <div className="container m-auto p-6 lg:p-10">
        <Text type="h1" className="mb-8">
          <FormattedMessage id="search-page.title" />
        </Text>
        <div className="md:flex md:items-stretch">
          <SearchPageFiltersWrapper />
          <div className="md:ml-6 flex-1">
            <SearchPagePaginationContainer />
            <div className="mt-6">
              {isLoading ? (
                <SearchPageResultsPlaceholder />
              ) : (
                <SearchPageResults />
              )}
            </div>
            <SearchPagePaginationContainer />
          </div>
        </div>
      </div>
    </>
  );
}
