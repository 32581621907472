import Button from 'components/shared/ui/Button';
import FormikInput from 'components/shared/ui/FormikInput';
import Link from 'components/shared/ui/Link';
import Text from 'components/shared/ui/Text';
import { Form, Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import useWishlist from 'hooks/useWishlist';
import { useRecoilValue } from 'recoil';
import { state } from '@springtree/eva-sdk-react-recoil';
import { useCallback } from 'react';
import useGetCurrentUser from 'hooks/useGetCurrentUser';
import { LoginValues } from './LoginTypes';
import useLoginValidationSchema from './useLoginValidationSchema';
import useLogin from './useLogin';

export default function LoginPage() {
  const history = useHistory();
  const loginValidationSchema = useLoginValidationSchema();
  const { login, loginError, logout } = useLogin();
  const { currentUser } = useGetCurrentUser();
  const { activeWishlist, userWishlists } = useWishlist();
  const { isEmployee } = useRecoilValue(state.currentUser.currentUserTypeState);

  const continueAfterLogin = useCallback(() => {
    if (isEmployee) {
      history.push('/employee');
    } else if ((userWishlists && !userWishlists.length) || !activeWishlist) {
      history.push('/geboortelijst/nieuw');
    } else {
      history.push(
        `/geboortelijst/${activeWishlist.OrderID}/${activeWishlist.AccessToken}`,
      );
    }
  }, [activeWishlist, history, isEmployee, userWishlists]);

  return (
    <div className="bg-white m-6 p-6 border-gray border">
      <Text type="h1" className="mb-2">
        <FormattedMessage id="login-page.login.title" />
      </Text>
      {loginError && (
        <div className="bg-pink text-error border border-gray p-4 my-4 text-sm">
          <FormattedMessage id="login-page.login.failed" />
        </div>
      )}
      {currentUser ? (
        <>
          <div className="mt-4">
            <FormattedMessage
              id="login-page.login.logged-in-as"
              values={{
                fullName: (
                  <span className="font-medium italic">
                    {currentUser.FullName}
                  </span>
                ),
              }}
            />
          </div>
          <div>
            <Button className="mt-4" onClick={continueAfterLogin}>
              <FormattedMessage
                id="login-page.login.continue-as"
                values={{
                  fullName: currentUser.FullName,
                }}
              />
            </Button>
          </div>
          <div className="w-full">
            <Button className="mt-4" onClick={logout} variant="stroked">
              <FormattedMessage id="login-page.login.login-different-account" />
            </Button>
          </div>
        </>
      ) : (
        <Formik<LoginValues>
          initialValues={{ email: '', password: '' }}
          onSubmit={login}
          validationSchema={loginValidationSchema}
        >
          {({ isValid, dirty }) => (
            <Form>
              <FormikInput
                name="email"
                labelId="generic.email-address"
                type="text"
              />
              <FormikInput
                name="password"
                labelId="generic.password"
                type="password"
              />
              <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
                <Button type="submit" disabled={!isValid || !dirty}>
                  <FormattedMessage id="login-page.login.login" />
                </Button>
                <span className="mt-4 self-center">
                  <Link to="/forgotpassword">
                    <FormattedMessage id="login-page.login.forgot-password" />
                  </Link>
                </span>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
}
