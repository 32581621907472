/** @jsxImportSource @emotion/react */
import {
  components as ReactSelectComponents,
  GroupTypeBase,
  OptionTypeBase,
} from 'react-select';
import AsyncSelect from 'react-select/async';
import { FormattedMessage, useIntl } from 'react-intl';
import Icon from 'components/shared/ui/Icon';
import Button from 'components/shared/ui/Button';
import { useHistory } from 'react-router-dom';
import { useCallback, useState, useRef } from 'react';
import tw, { theme } from 'twin.macro';
import useGetCurrentUser from 'hooks/useGetCurrentUser';
import { ISearchResult } from './SearchBarTypes';
import useSearch from './useSearch';
import SearchBarItem from './SearchBarItem';

export default function SearchBar() {
  const { currentUser } = useGetCurrentUser();
  const [inputValue, setInputValue] = useState('');
  const { getSearchResults } = useSearch();
  const intl = useIntl();
  const history = useHistory();
  const [key, setKey] = useState(Date.now());

  const showAllResults = useCallback(() => {
    history.push(`/search/${inputValue}`);
    setKey(Date.now());
  }, [history, inputValue]);

  return (
    <>
      {currentUser && (
        <div
          css={[
            tw`w-full mx-auto md:ml-auto h-10 md:mr-2 self-end`,
            { maxWidth: '400px' },
          ]}
        >
          <div className="w-full h-full flex">
            <div className="flex-grow">
              <AsyncSelect<ISearchResult>
                key={key}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    showAllResults();
                    setKey(Date.now());
                  }
                }}
                loadOptions={getSearchResults}
                onInputChange={(value) => setInputValue(value)}
                placeholder={intl.formatMessage({
                  id: 'search-bar.placeholder',
                })}
                loadingMessage={() =>
                  intl.formatMessage({ id: 'search-bar.searching' })
                }
                components={{
                  DropdownIndicator: () => (
                    <Button
                      variant="icon"
                      onClick={showAllResults}
                      className="w-10 flex justify-center items-center bg-white text-xl focus:outline-none"
                    >
                      <Icon name="search" />
                    </Button>
                  ),
                  IndicatorSeparator: () => null,
                  Option: (optionProps) => (
                    <SearchBarItem
                      result={optionProps.data}
                      onSelect={() => setKey(Date.now())}
                    />
                  ),
                  MenuList: (menuListProps) => (
                    <ReactSelectComponents.MenuList {...menuListProps}>
                      {menuListProps.children}
                      {menuListProps.options.length > 0 && (
                        <div className="flex justify-center my-4">
                          <Button
                            className="focus:outline-none"
                            onClick={showAllResults}
                          >
                            <FormattedMessage id="search-bar.see-all-results" />
                          </Button>
                        </div>
                      )}
                    </ReactSelectComponents.MenuList>
                  ),
                }}
                noOptionsMessage={() =>
                  inputValue
                    ? intl.formatMessage(
                        { id: 'search-bar.no-results-for-query' },
                        { query: inputValue },
                      )
                    : null
                }
                styles={{
                  control: (base) => ({
                    ...base,
                    borderRadius: 0,
                    boxShadow: 'none',
                    border: 'none',
                    height: '100%',
                    backgroundColor: theme`colors.gray-light`,
                  }),
                  container: (base) => ({
                    ...base,
                    height: '100%',
                    maxWidth: '400px',
                  }),
                  menu: (base) => ({
                    ...base,
                    maxWidth: '400px',
                  }),
                  menuList: (base) => ({
                    ...base,
                    height: '100%',
                    maxHeight: '650px',
                  }),
                  noOptionsMessage: (base) => ({
                    ...base,
                    fontWeight: 'bold',
                    color: 'black',
                  }),
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
