export default function ProductCardPlaceholder() {
  return (
    <div className="bg-white flex flex-col justify-evenly items-center h-80">
      <div>
        <div className="h-4 w-64 mx-auto mt-2 bg-gray animate-pulse" />
        <div className="h-4 w-72 mx-auto mt-2 bg-gray animate-pulse" />
      </div>
      <div className="my-2 px-2 h-40 w-40 animate-pulse bg-gray" />
    </div>
  );
}
