import Link from 'components/shared/ui/Link';
import Separator from 'components/shared/ui/Separator';
import Text from 'components/shared/ui/Text';
import { FormattedMessage } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { CheckoutOrderState } from '../state/CheckoutOrder';

export default function OrderCustomerDetails() {
  const checkoutOrder = useRecoilValue(CheckoutOrderState);
  return (
    <>
      {checkoutOrder && (
        <div className="bg-white p-4 flex flex-col">
          <Text type="h2">
            <FormattedMessage id="checkout-page.order-customer-details.title" />
          </Text>
          <Separator className="my-4" />
          <div className="mb-6 flex flex-col">
            {checkoutOrder.Customer.FullName && (
              <span className="text-gray-dark font-semibold">
                {checkoutOrder.Customer.FullName}
              </span>
            )}
            <span>{checkoutOrder.Customer.EmailAddress}</span>
            <span>{checkoutOrder.Customer.PhoneNumber}</span>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <AddressDetails
              address={checkoutOrder.BillingAddress}
              titleId="checkout-page.order-customer-details.billing-address"
            />
            <AddressDetails
              address={checkoutOrder.ShippingAddress}
              titleId="checkout-page.order-customer-details.delivery-address"
            />
          </div>
          <div className="mt-auto font-bold">
            <Link to="/checkout/register">
              <FormattedMessage id="checkout-page.order-customer-details.edit" />
            </Link>
          </div>
        </div>
      )}
    </>
  );
}

const AddressDetails = (props: {
  address: EVA.Core.AddressDto;
  titleId: string;
}) => {
  return (
    <div className="flex flex-col">
      <span className="text-gray-dark font-semibold">
        <FormattedMessage id={props.titleId} />
      </span>
      <span>
        {props.address.Street} {props.address.HouseNumber}
      </span>
      <span>
        {props.address.ZipCode} {props.address.City}
      </span>
    </div>
  );
};
