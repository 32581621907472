import { hooks } from '@springtree/eva-sdk-react-recoil';
import { Core } from '@springtree/eva-services-core';
import { RegisterValues } from 'components/shared/profile/RegisterTypes';
import useMapAddress from './useMapAddress';

export default function useCreateCustomer(parentAccount: boolean) {
  const createCustomerService = hooks.useCallService({
    service: Core.CreateCustomer,
  });

  const { mapAddress } = useMapAddress();

  const createCustomer = async (values: RegisterValues) => {
    return createCustomerService({
      AutoLogin: true,
      NoAccount: !parentAccount,
      User: {
        EmailAddress: values.email,
        FirstName: values.firstName,
        LastName: values.lastName,
        PhoneNumber: values.phoneNumber,
        CountryID: values.billingAddress?.countryId,
        Password: parentAccount ? values.password : undefined,
        BillingAddress: mapAddress(values.billingAddress),
        ShippingAddress: values.hasShippingAddress
          ? mapAddress(values.shippingAddress)
          : mapAddress(values.billingAddress),
      },
    });
  };

  return { createCustomer };
}
