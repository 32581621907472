import Pagination from 'components/shared/ui/Pagination';
import Select from 'components/shared/ui/Select';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useRecoilState } from 'recoil';
import { SearchPageFiltersState } from './SearchPageState';
import { SearchSortType, SearchSortTypeTranslations } from './SearchPageTypes';
import usePagination from './usePagination';

export default function SearchPagePaginationContainer() {
  const [searchFilters, setSearchFilters] = useRecoilState(
    SearchPageFiltersState,
  );
  const intl = useIntl();

  const sortTypeOptions = useMemo(
    () =>
      Object.values(SearchSortType).map((x) => ({
        value: x,
        label: intl.formatMessage({ id: SearchSortTypeTranslations[x] }),
      })),
    [intl],
  );

  const {
    pageSizes,
    searchPageFilters,
    totalNumberOfPages,
    setPageSize,
    setCurrentPage,
  } = usePagination();

  return (
    <>
      {totalNumberOfPages !== undefined && totalNumberOfPages > 0 && (
        <div className="flex mt-2 flex-col lg:flex-row">
          <Select
            name="sortType"
            options={sortTypeOptions}
            value={searchFilters.sortType}
            onChange={(e) => {
              setSearchFilters((current) => ({
                ...current,
                sortType: e.target.value as SearchSortType,
              }));
            }}
            className="mb-4 lg:mr-4 lg:mb-0"
          />
          <Pagination
            currentPage={searchPageFilters.currentPage}
            numberOfPages={totalNumberOfPages}
            handlePageChange={setCurrentPage}
            handlePageSizeChange={setPageSize}
            pageSizeOptions={pageSizes}
            pageSize={searchPageFilters.pageSize}
          />
        </div>
      )}
    </>
  );
}
