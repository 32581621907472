/** @jsxImportSource @emotion/react */
import { ReactNode } from 'react';
import tw from 'twin.macro';

interface ITextProps {
  children: ReactNode;
  type?: 'h1' | 'h2' | 'h3';
  className?: string;
}

export default function Text(props: ITextProps) {
  return (
    <span
      css={[
        props.type === 'h1' && tw`text-3xl font-semibold`,
        props.type === 'h2' && tw`text-2xl`,
        props.type === 'h3' && tw`font-bold`,
        props.type === undefined && tw`text-sm`,
        props.className !== undefined && tw`block`,
      ]}
      className={props.className}
    >
      {props.type === 'h1' && <h1>{props.children}</h1>}
      {props.type === 'h2' && <h2>{props.children}</h2>}
      {props.type === 'h3' && <h3>{props.children}</h3>}
      {props.type === undefined && props.children}
    </span>
  );
}
