import { useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import useEvaCheckoutServices from './hooks/useEvaCheckoutServices';
import { CheckoutAmountsState } from './state/CheckoutAmount';
import { CheckoutOrderState } from './state/CheckoutOrder';
import { CheckoutToken } from './state/CheckoutToken';

export default function CheckoutReturn() {
  const history = useHistory();
  const params = useParams<{ orderId?: string; userToken?: string }>();

  const { evaGetOrder } = useEvaCheckoutServices();
  const setCheckoutOrder = useSetRecoilState(CheckoutOrderState);
  const setCheckoutAmounts = useSetRecoilState(CheckoutAmountsState);

  const setCheckoutUserToken = useSetRecoilState(CheckoutToken);

  const resumeCheckout = useCallback(
    async (orderId: number, userToken?: string) => {
      if (params.userToken) {
        setCheckoutUserToken(params.userToken);
      }

      const checkoutOrder = await evaGetOrder(
        {
          OrderID: orderId,
        },
        userToken,
      );

      if (checkoutOrder) {
        console.log('checkoutOrder', checkoutOrder);
        setCheckoutOrder(checkoutOrder.Result);
        setCheckoutAmounts(checkoutOrder.Amounts);
        if (checkoutOrder.Amounts.OpenAmountIsZero) {
          history.push('/checkout/complete');
        } else {
          history.push('/checkout/overview');
        }
      } else {
        history.push('/');
      }
    },
    [
      evaGetOrder,
      history,
      params.userToken,
      setCheckoutAmounts,
      setCheckoutOrder,
      setCheckoutUserToken,
    ],
  );

  useEffect(() => {
    if (params.orderId) {
      resumeCheckout(parseInt(params.orderId, 10), params.userToken);
    }
  }, [evaGetOrder, params, resumeCheckout, setCheckoutUserToken]);

  return <></>;
}
