import { useCallService, useSetServiceStale } from 'eva/EvaHooks';
import { Core } from '@springtree/eva-services-core';
import { useCallback } from 'react';
import { state } from '@springtree/eva-sdk-react-recoil';
import useWishlist from 'hooks/useWishlist';

const useChangeWishListItemPosition = () => {
  const updateWishList = useCallService({
    service: Core.UpdateWishList,
  });
  const refreshWishListDetails = useSetServiceStale({
    serviceState: state.wishList.wishListDetailsState,
  });
  const { activeWishlist } = useWishlist();

  return useCallback(
    async (wishListLine: EVA.Core.WishListLine, newPosition: number) => {
      if (activeWishlist) {
        const lineIds = activeWishlist.Lines.map((x) => x.ID);
        const currentIndex = lineIds.indexOf(wishListLine.ID);
        lineIds.splice(currentIndex, 1);
        lineIds.splice(newPosition - 1, 0, wishListLine.ID);

        await updateWishList({
          SortedWishListLineIDs: lineIds,
          OrderID: activeWishlist.OrderID,
        });
        refreshWishListDetails();
      }
    },
    [activeWishlist, refreshWishListDetails, updateWishList],
  );
};

export default useChangeWishListItemPosition;
