import { state } from '@springtree/eva-sdk-react-recoil';
import useGetCurrentUser from 'hooks/useGetCurrentUser';
import { useEffect } from 'react';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { OrdersOverviewFiltersState } from './OrdersOverviewState';

const useCurrentCustomerOrders = () => {
  const setOrdersRequest = useSetRecoilState(
    state.customer.customerOrderListState.request,
  );
  const { currentUser } = useGetCurrentUser();

  const ordersOverviewFilters = useRecoilValue(OrdersOverviewFiltersState);

  useEffect(() => {
    const start =
      (ordersOverviewFilters.currentPage - 1) * ordersOverviewFilters.pageSize;

    if (currentUser) {
      setOrdersRequest({
        ID: currentUser.ID,
        PageConfig: {
          Filter: {},
          SortProperty: 'CreationTime',
          Start: start,
          Limit: ordersOverviewFilters.pageSize,
          SortDirection: 1,
        },
      });
    }
  }, [
    currentUser,
    ordersOverviewFilters.currentPage,
    ordersOverviewFilters.pageSize,
    setOrdersRequest,
  ]);

  const ordersResponse = useRecoilValue(
    state.customer.customerOrderListState.response,
  );

  return ordersResponse;
};

export default useCurrentCustomerOrders;
