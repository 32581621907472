import Select from 'components/shared/ui/Select';
import useConfigurableProduct from './useConfigurableProduct';

interface IConfigurableProductSelectionProps {
  disableSelect?: boolean;
}

export default function ConfigurableProductSelection(
  props: IConfigurableProductSelectionProps,
) {
  const {
    configurableProperties,
    selectConfiguration,
  } = useConfigurableProduct();

  return (
    <>
      {configurableProperties.map((x) => (
        <Select
          disabled={props.disableSelect}
          className="mx-2"
          key={x.name}
          value={x.selectedProductId}
          options={x.options}
          onChange={(e) => {
            const selectedId = parseInt(e.target.value, 10);
            selectConfiguration(selectedId, x.name);
          }}
        />
      ))}
    </>
  );
}
