import { hooks, state } from '@springtree/eva-sdk-react-recoil';
import { Core } from '@springtree/eva-services-core';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import useWishlist from 'hooks/useWishlist';
import { useSetServiceStale } from 'eva/EvaHooks';

const useRemoveItemFromActiveWishList = () => {
  const { activeWishlist } = useWishlist();
  const removeProductFromWishList = hooks.useCallService({
    service: Core.RemoveProductFromWishList,
  });
  const refreshWishList = useSetServiceStale({
    serviceState: state.wishList.wishListDetailsState,
  });
  const history = useHistory();

  return useCallback(
    async (productId: number) => {
      if (activeWishlist) {
        const openLine = activeWishlist.Lines.find(
          (x: any) => x.Product.ID === productId && x.OpenQuantity > 0,
        );

        if (openLine) {
          await removeProductFromWishList({
            OrderID: activeWishlist.OrderID,
            WishListLineID: openLine.ID,
            Quantity: openLine.Quantity,
          });
          refreshWishList();
          history.push(
            `/geboortelijst/${activeWishlist.OrderID}/${activeWishlist.AccessToken}`,
          );
        }
      }
    },
    [activeWishlist, history, refreshWishList, removeProductFromWishList],
  );
};

export default useRemoveItemFromActiveWishList;
