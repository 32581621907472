/** @jsxImportSource @emotion/react */
import { FormattedMessage } from 'react-intl';
import tw from 'twin.macro';

interface IAvailabilityItemProps {
  shopName: string;
  inStock: boolean;
}

export default function AvailabilityItem(props: IAvailabilityItemProps) {
  return (
    <>
      <div className="col-span-2">{props.shopName}</div>
      <div
        css={[
          props.inStock ? tw`text-primary-darker` : tw`text-red-800`,
          tw`text-right`,
        ]}
      >
        <FormattedMessage
          id={
            props.inStock
              ? 'product-details-page.product-availability.in-stock'
              : 'product-details-page.product-availability.not-in-stock'
          }
        />
      </div>
    </>
  );
}
