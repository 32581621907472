/** @jsxImportSource @emotion/react */
import { FormattedMessage } from 'react-intl';
import tw from 'twin.macro';

interface IInputProps {
  value?: string | number;
  labelId?: string;
  name?: string;
  type: 'text' | 'password' | 'number';
  readonly?: boolean;
  error?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent) => void;
  hideErrorMessage?: boolean;
  capitalizeLabel?: boolean;
}

export default function Input(props: IInputProps) {
  return (
    <div className="w-full mb-4">
      <div className="flex">
        {props.labelId && (
          <div
            css={[
              props.capitalizeLabel !== false && tw`uppercase`,
              tw`text-sm`,
            ]}
          >
            <FormattedMessage id={props.labelId} />
          </div>
        )}
        {props.error && !props.hideErrorMessage && (
          <div className="text-sm flex-grow text-right text-error">
            {props.error}
          </div>
        )}
      </div>
      <input
        value={props.value}
        name={props.name}
        onChange={props.onChange}
        onBlur={props.onBlur}
        type={props.type}
        css={[
          tw`border border-gray mt-2 p-2 text-sm font-light w-full focus:bg-gray focus:border-blue`,
          props.readonly && tw`bg-gray cursor-not-allowed`,
          props.error && tw`border-error`,
        ]}
        readOnly={props.readonly}
      />
    </div>
  );
}
