import Dialog from 'components/shared/ui/Dialog';
import Text from 'components/shared/ui/Text';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { ShowFamilyInformationDialogState } from './wish-list/WishListState';

export default function WishListFamilyInformationDialog() {
  const [showDialog, setShowDialog] = useRecoilState(
    ShowFamilyInformationDialogState,
  );
  const closeModal = useCallback(() => {
    setShowDialog(false);
    localStorage.setItem('familyInformationModalShown', 'true');
  }, [setShowDialog]);

  return (
    <Dialog
      show={showDialog}
      titleId="home-page.family-dialog.title"
      confirmButtonTextId="home-page.family-dialog.button"
      confirmHandler={closeModal}
      closeHandler={closeModal}
      showTopSeparator
    >
      <div className="p-4">
        <Text type="h3">Beste klant,</Text>
        <div className="my-2">
          Hieronder een woordje uitleg over het gebruik van de geboortelijsten:
        </div>
        <Text type="h3">Algemene informatie</Text>
        <ul className="list-disc p-4 ml-4">
          <li>
            Alle producten staan van hoge naar lage prioriteit gerangschikt,
            deze zijn zorgvuldig geselecteerd volgens de wensen van de ouders.
          </li>
          <li>
            Bij ieder product kan u de algemene informatie terugvinden, de
            voorraad per winkel en in het centraal magazijn raadplegen. <br />
            <span className="underline">Tip:</span> <br />
            Voorraad winkel = keuze afhalen, voorraad magazijn = keuze
            thuisbezorgen.
          </li>
          <li>
            U kan een donatie doen aan het begin van de pagina, dit bedrag
            kunnen de ouders zelf besteden op een artikel naar keuze.
          </li>
        </ul>
        <Text type="h3" className="mt-2">
          De verschillende leveropties
        </Text>
        <ul className="list-disc p-4 ml-4">
          <li>
            <div className="font-semibold"> Thuisbezorgen</div>
            Het geschenk kan geleverd worden naar een adres van keuze (Uzelf, de
            ouders,…) <br />U kan direct betalen via Bancontact, Mastercard,
            Visa of Ideal
          </li>
          <li>
            <div className="font-semibold">Afhalen in één van de winkels</div>
            Het geschenk wordt voor u klaargelegd in de winkel van u keuze
            (Mechelen of Gent) <br />U kan dan betalen aan de kassa.
          </li>
        </ul>
        Wenst u het/de artikel(en) bij de ouders te laten leveren (meeleveren
        met ouders), kies dan voor de leveropties thuisbezorgen.
        <br />
        Voor eventuele verdere informatie kan u steeds terecht bij onze
        medewerkers in de dichtstbijzijnde Babydeals of u kan bellen naar de
        service-lijn 015 280120.
        <br />
        <div className="mt-4">Met vriendelijke groeten,</div>
        Team Babydeals <br />
      </div>
    </Dialog>
  );
}
