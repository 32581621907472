/** @jsxImportSource @emotion/react */
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import tw, { css } from 'twin.macro';

export default function PromoRibbon() {
  const ribbonStyle = useMemo(
    () => css`
      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 100%;
        z-index: -1;
        border-left: 3px solid transparent;
        border-right: 3px solid #8f0808;
        border-bottom: 3px solid transparent;
        border-top: 3px solid #8f0808;
      }
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 100%;
        z-index: -1;
        border-left: 3px solid #8f0808;
        border-right: 3px solid transparent;
        border-bottom: 3px solid transparent;
        border-top: 3px solid #8f0808;
      }
    `,
    [],
  );

  return (
    <div className="absolute -top-1.5 -right-1.5 w-20 h-20 overflow-hidden">
      <span
        css={[
          tw`bg-red-600 w-28 absolute top-5 -right-6 transform rotate-45 block uppercase text-white text-xs font-bold py-1`,
          ribbonStyle,
        ]}
      >
        <FormattedMessage id="generic.promo" />
      </span>
    </div>
  );
}
