/** @jsxImportSource @emotion/react */
import { FormattedMessage } from 'react-intl';
import tw from 'twin.macro';

interface INotificationProps {
  textId: string;
  type: 'warning' | 'success';
  className?: string;
}

export default function Notification(props: INotificationProps) {
  return (
    <div
      css={[
        tw`text-center px-4 py-3 border border-gray text-sm`,
        props.type === 'warning' && tw`bg-warning text-warning-darker`,
        props.type === 'success' &&
          tw`bg-success border-success-dark text-success-darker`,
      ]}
      className={props.className}
    >
      <FormattedMessage id={props.textId} />
    </div>
  );
}
