import { ReactNode } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

interface ILinksProps {
  children: ReactNode;
  to: string;
  onClick?: () => void;
}

export default function Link(props: ILinksProps) {
  return (
    <ReactRouterLink
      to={props.to}
      className="text-primary text-sm hover:underline hover:text-blue-dark"
      onClick={props.onClick}
    >
      {props.children}
    </ReactRouterLink>
  );
}
