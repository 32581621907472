import Price from 'components/shared/ui/Price';
import { FormattedMessage } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { CheckoutAmountsState } from '../state/CheckoutAmount';
import { CheckoutOrderState } from '../state/CheckoutOrder';

export default function OrderTotals() {
  const checkoutOrder = useRecoilValue(CheckoutOrderState);
  const checkoutAmounts = useRecoilValue(CheckoutAmountsState);

  return (
    <>
      {checkoutOrder && checkoutAmounts && (
        <div className="grid grid-cols-2 justify-items-end mt-10 gap-4 pr-2">
          <span>
            <FormattedMessage id="checkout-page.order-totals.excluding-vat" />
          </span>
          <span>
            <Price
              value={checkoutAmounts.Total.Amount}
              currency={checkoutOrder.CurrencyID}
            />
          </span>
          <span>
            <FormattedMessage id="checkout-page.order-totals.vat" />
          </span>
          <span>
            <Price
              value={checkoutAmounts.Total.InTax - checkoutAmounts.Total.Amount}
              currency={checkoutOrder.CurrencyID}
            />
          </span>
          <span className="font-bold">
            <FormattedMessage id="checkout-page.order-totals.total" />
          </span>
          <span className="font-bold">
            <Price
              value={checkoutAmounts.Total.InTax}
              currency={checkoutOrder.CurrencyID}
            />
          </span>
          {checkoutAmounts.Open.InTax < checkoutAmounts.Total.InTax && (
            <>
              <span>
                <FormattedMessage id="checkout-page.order-totals.paid" />
              </span>
              <span>
                <Price
                  value={
                    checkoutAmounts.Total.InTax - checkoutAmounts.Open.InTax
                  }
                  currency={checkoutOrder.CurrencyID}
                />
              </span>
              <span className="font-bold">
                <FormattedMessage id="checkout-page.order-totals.remaining" />
              </span>
              <span className="font-bold">
                <Price
                  value={checkoutAmounts.Open.InTax}
                  currency={checkoutOrder.CurrencyID}
                />
              </span>
            </>
          )}
        </div>
      )}
    </>
  );
}
