import Dialog from 'components/shared/ui/Dialog';
import { Form, Formik } from 'formik';
import WishListDonationFields from 'components/shared/donate/WishListDonationFields';
import { WishListDonationValues } from 'components/shared/donate/DonateTypes';
import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
import { WishListDonationValuesState } from 'state/DonateState';
import useWishListDonationValidationSchema from 'components/shared/donate/useWishListDonationValidationSchema';

interface IAddWishListDonationDialogProps {
  closeHandler: () => void;
}

export default function AddWishListDonationDialog(
  props: IAddWishListDonationDialogProps,
) {
  const wishListDonationValidationSchema = useWishListDonationValidationSchema();
  const setWishListDonationValues = useSetRecoilState(
    WishListDonationValuesState,
  );
  const history = useHistory();

  const handleSubmit = useCallback(
    (values: WishListDonationValues) => {
      setWishListDonationValues(values);
      history.push('/donate');
    },
    [history, setWishListDonationValues],
  );

  return (
    <Formik<WishListDonationValues>
      initialValues={{ donationValue: 5, name: '', email: '', remark: '' }}
      onSubmit={(values) => handleSubmit(values)}
      validationSchema={wishListDonationValidationSchema}
    >
      {({ submitForm }) => (
        <Dialog
          show
          closeHandler={props.closeHandler}
          titleId="home-page.wish-list.add-donation-dialog.title"
          showCancelButton
          confirmButtonTextId="home-page.wish-list.add-donation-dialog.action"
          confirmHandler={submitForm}
        >
          <Form>
            <WishListDonationFields />
          </Form>
        </Dialog>
      )}
    </Formik>
  );
}
