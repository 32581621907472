import { hooks } from '@springtree/eva-sdk-react-recoil';
import { useState, useCallback } from 'react';
import { Core } from '@springtree/eva-services-core';
import useIsMounted from './useIsMounted';

const usePromotionInfo = () => {
  const isMounted = useIsMounted();
  const [promotionInfo, setPromotionInfo] = useState<
    Array<{ productId: number; hasPromotion: boolean }>
  >([]);

  const searchProducts = hooks.useCallService({ service: Core.SearchProducts });

  const getPromotionInfo = useCallback(
    async (productIds: Array<number>) => {
      if (productIds) {
        const response = await searchProducts({
          Filters: { product_id: { Values: productIds } },
          IncludedFields: ['vanvoorbetype'],
        });
        if (response && isMounted) {
          setPromotionInfo(
            response.Products.map((x) => ({
              productId: x.product_id,
              hasPromotion: x.vanvoorbetype === 'promo',
            })),
          );
        } else if (isMounted) {
          setPromotionInfo([]);
        }
      }
    },
    [isMounted, searchProducts],
  );

  return { promotionInfo, getPromotionInfo };
};

export default usePromotionInfo;
