import CreatePayment from 'components/shared/payment/CreatePayment';
import { useRecoilValue } from 'recoil';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useWishList from 'hooks/useWishlist';
import { WishListDonationValuesState } from 'state/DonateState';
import { Form, Formik } from 'formik';
import { WishListDonationValues } from 'components/shared/donate/DonateTypes';
import WishListDonationFields from 'components/shared/donate/WishListDonationFields';
import Text from 'components/shared/ui/Text';
import { FormattedMessage } from 'react-intl';
import Separator from 'components/shared/ui/Separator';
import useWishListDonationValidationSchema from 'components/shared/donate/useWishListDonationValidationSchema';
import { PaymentTypes } from 'components/shared/payment/PaymentTypes';
import { BuckarooPaymentMethodsSelector } from 'state/PaymentState';

export default function DonatePage() {
  const wishListDonationValues = useRecoilValue(WishListDonationValuesState);
  const { activeWishlist, isParent } = useWishList();
  const history = useHistory();
  const wishListDonationValidationSchema = useWishListDonationValidationSchema();

  const availableBuckarooPaymentMethods = useRecoilValue(
    BuckarooPaymentMethodsSelector,
  );

  useEffect(() => {
    if (activeWishlist && (isParent || !wishListDonationValues)) {
      history.push(
        `/geboortelijst/${activeWishlist.OrderID}/${activeWishlist.AccessToken}`,
      );
    }
  }, [activeWishlist, history, isParent, wishListDonationValues]);

  return wishListDonationValues ? (
    <div className="container mx-auto p-6 lg:p-10">
      <Text type="h1" className="font-bold mb-4">
        <FormattedMessage id="donate-page.title" />
      </Text>
      <Formik<WishListDonationValues>
        initialValues={{ ...wishListDonationValues }}
        onSubmit={() => {}}
        validationSchema={wishListDonationValidationSchema}
      >
        {({ isValid }) => (
          <Form>
            <div className="">
              <div className="bg-white p-4 mb-4">
                <Text type="h2">
                  <FormattedMessage id="donate-page.your-donation" />
                </Text>
                <Separator color="gray" className="my-4" />
                <WishListDonationFields />
              </div>
              {availableBuckarooPaymentMethods && (
                <CreatePayment
                  isButtonDisabled={!isValid}
                  wishListDonationValues={wishListDonationValues}
                  paymentType={PaymentTypes.WishListDonation}
                  availableMethods={availableBuckarooPaymentMethods}
                />
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  ) : null;
}
