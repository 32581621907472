import { FormattedMessage } from 'react-intl';
import Dialog from '../ui/Dialog';

interface IConfirmRemoveWishListItemDialogProps {
  confirmHandler: () => void;
  closeHandler: () => void;
}

export default function ConfirmRemoveWishListItemDialog(
  props: IConfirmRemoveWishListItemDialogProps,
) {
  return (
    <Dialog
      show
      confirmHandler={props.confirmHandler}
      confirmButtonTextId="generic.yes"
      showCancelButton
      closeHandler={props.closeHandler}
    >
      <span className="px-4">
        <FormattedMessage id="remove-wishlist-item-dialog.are-you-sure" />
      </span>
    </Dialog>
  );
}
