import Button from 'components/shared/ui/Button';
import FormikInput from 'components/shared/ui/FormikInput';
import Text from 'components/shared/ui/Text';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { helpers, state } from '@springtree/eva-sdk-react-recoil';
import { useRecoilValue } from 'recoil';
import { Core } from '@springtree/eva-services-core';
import useIsMounted from 'hooks/useIsMounted';
import { SearchWishlistValues } from './LoginTypes';
import useSearchWishlistValidationSchema from './useSearchWishlistValidationSchema';

export default function LoginPageSearchWishlist() {
  const isMounted = useIsMounted();
  const history = useHistory();
  const searchWishlistValidationSchema = useSearchWishlistValidationSchema();

  const evaEndpointUrl = useRecoilValue(state.core.evaEndpointUrlState);
  const [showWishlistError, setShowWishlistError] = useState(false);

  const searchWishlist = async (values: SearchWishlistValues) => {
    setShowWishlistError(false);

    if (evaEndpointUrl && values.orderId && values.accessToken) {
      try {
        const evaEndpoint = await helpers.getEvaEndpoint(evaEndpointUrl);
        const getWishlistResponse = await evaEndpoint.callService(
          Core.GetWishList,
          {
            OrderID: values.orderId,
            AccessToken: values.accessToken,
          },
        );

        if (getWishlistResponse && getWishlistResponse.Result) {
          history.push(
            `/geboortelijst/${getWishlistResponse.Result.OrderID}/${getWishlistResponse.Result.AccessToken}`,
          );
        }
      } catch {
        if (isMounted) {
          setShowWishlistError(true);
        }
      }
    }
  };

  return (
    <Formik<SearchWishlistValues>
      initialValues={{ orderId: undefined, accessToken: '' }}
      onSubmit={searchWishlist}
      validationSchema={searchWishlistValidationSchema}
    >
      <Form>
        <div className="bg-white m-6 p-6 border-gray border flex flex-col">
          <Text type="h1" className="mb-2">
            <FormattedMessage id="login-page.wishlist.title" />
          </Text>
          <FormikInput
            name="orderId"
            labelId="login-page.wishlist.order-id"
            type="number"
          />
          <FormikInput
            name="accessToken"
            labelId="login-page.wishlist.wishlist-id"
            type="text"
          />
          {showWishlistError && (
            <div className="my-2 text-error text-sm">
              <FormattedMessage id="login-page.wishlist.not-found" />
            </div>
          )}
          <div className="flex-grow items-end flex mt-4">
            <div className="w-full">
              <Button className="w-full" type="submit">
                <FormattedMessage id="login-page.wishlist.search-list" />
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </Formik>
  );
}
